import { api } from "../api/api";
import AllowedCountry from "../api/common/response/AllowedCountry";
import HttpClient from "../api/HttpClient";

class AllowedCountriesService {
	private static instance: AllowedCountriesService | null = null;

	private httpClient: HttpClient = new HttpClient();
	private allCountries: AllowedCountry[] | null = null;
	private tierOneBvnCountries: AllowedCountry[] | null = null;
	private personaCheckCountries: AllowedCountry[] | null = null;

	private constructor() {}

	static getInstance(): AllowedCountriesService {
		if (!AllowedCountriesService.instance) {
			AllowedCountriesService.instance = new AllowedCountriesService();
		}
		return AllowedCountriesService.instance;
	}

	async getAllCountries(): Promise<AllowedCountry[]> {
		if (!this.allCountries) {
			const response = await this.httpClient.get<AllowedCountry[]>(api.common.ALL_COUNTRIES);
			this.allCountries = response.data;
		}
		return this.allCountries;
	}

	async getCountriesTierOneBvn(): Promise<AllowedCountry[]> {
		if (!this.tierOneBvnCountries) {
			const response = await this.httpClient.get<AllowedCountry[]>(api.common.COUNTRIES_TIER_ONE_BVN);
			this.tierOneBvnCountries = response.data;
		}
		return this.tierOneBvnCountries;
	}

	async getCountriesPersonaCheck(): Promise<AllowedCountry[]> {
		if (!this.personaCheckCountries) {
			const response = await this.httpClient.get<AllowedCountry[]>(api.common.COUNTRIES_PERSONA_CHECK);
			this.personaCheckCountries = response.data;
		}
		return this.personaCheckCountries;
	}
}

export default AllowedCountriesService;
