import "./layout.css";

import { Link, useLocation } from "react-router-dom";

const links = [
	{ link: "/dashboard", title: "DASHBOARD" },
	{ link: "/orders", title: "ORDERS" },
	{ link: "/trade", title: "TRADE" },
	{ link: "/deposit", title: "ADD FUNDS" },
	{ link: "/withdraw", title: "WITHDRAW FUNDS" },
];

type DashboardLayoutProps = React.PropsWithChildren;

const DashboardLayout: React.FC<DashboardLayoutProps> = (props: DashboardLayoutProps) => {
	const { children } = props;
	const location = useLocation();

	return (
		<div>
			<div className='center-container'>
				<div className='menu'>
					{links.map((link) => (
						<Link to={link.link} className='link-custom' key={`link-${link.link}`}>
							<p className={`${location.pathname === link.link ? "selected" : ""}`}>{link.title}</p>
						</Link>
					))}
				</div>
			</div>

			{children}
		</div>
	);
};

export default DashboardLayout;
