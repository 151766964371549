import "../../layout/Dashboard/layout.css";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import UserService from "../../services/user/UserService";
import fifthStepIcon from "./fifth-step.png";
import firstStepIcon from "./first-step.png";
import fourthStepIcon from "./fourth-step.png";
import secondStepIcon from "./second-step.png";
import seventhStepIcon from "./seventh-step.png";
import sixthStepIcon from "./sixth-step.png";
import thirdStepIcon from "./third-step.png";

const Help = () => {
	const history = useHistory();
	const userService = UserService.getInstance();

	const [userFirstName, setUserFirstName] = useState("");

	useEffect(() => {
		const user = userService.getUser();
		if (user) {
			setUserFirstName(user?.tierZeroInfo?.firstname);
		}
	}, []); // Empty dependency array means the effect runs only once

	return (
		<div>
			<div className='center-container'>
				<div className='menu'>
					<Link to='/dashboard' className='link-custom'>
						<p>DASHBOARD</p>
					</Link>
					<Link to='/orders' className='link-custom'>
						<p>ORDERS</p>
					</Link>
					<Link to='/trade' className='link-custom'>
						<p>TRADE</p>
					</Link>
					<Link to='/deposit' className='link-custom'>
						<p>ADD FUNDS</p>
					</Link>
					<Link to='/withdraw' className='link-custom'>
						<p>WITHDRAW FUNDS</p>
					</Link>
				</div>
			</div>

			<h1 className='title'>Help / Usage</h1>
			<div className='dashboard-container'>
				<div className='help-container'>
					<div className='help-step-container'>
						<div className='help-step-title-container'>
							<img src={firstStepIcon} className='help-step-icon' />
							<p className='help-step-title-text'>1. Email Verification</p>
						</div>
						<p className='help-step-description-text'>
							Welcome to vTrader, {userFirstName}! Thank you for joining vTrader. On this page you&apos;ll find the
							required steps to properly set up your account and start trading and earning. We&apos;ll also provide some
							quick advice and heads-up about trading crypto.{" "}
						</p>
						<button className='help-step-button' style={{ backgroundColor: "#BBB", cursor: "default" }}>
							COMPLETE
						</button>
					</div>

					<div className='help-step-container'>
						<div className='help-step-title-container'>
							<img src={secondStepIcon} className='help-step-icon' />
							<p className='help-step-title-text'>2. Complete Your Basic Information</p>
						</div>
						<p className='help-step-description-text'>
							Before you can add crypto or buy USDT through us, we need to collect some basic information about
							yourself. To achieve <span style={{ textDecoration: "underline" }}>Tier 1 Verification</span> go to My
							Profile, Account section and submit your basic information for verification.{" "}
						</p>
						<button
							className='help-step-button'
							onClick={() => {
								history.push(`/account`);
							}}
						>
							GET VERIFIED
						</button>
					</div>

					<div className='help-step-container'>
						<div className='help-step-title-container'>
							<img src={thirdStepIcon} className='help-step-icon' />
							<p className='help-step-title-text'>3. Add Funds</p>
						</div>
						<p className='help-step-description-text'>
							Add funds via bank transfer or blockchain. We&apos;ve opened wallets on the blockchain when your account
							was created. You can also buy USDT(symbol:₮) through us via bank transfer.
						</p>
						<button
							className='help-step-button'
							onClick={() => {
								history.push(`/deposit`);
							}}
						>
							ADD FUNDS
						</button>
					</div>

					<div className='help-step-container'>
						<div className='help-step-title-container'>
							<img src={fourthStepIcon} className='help-step-icon' />
							<p className='help-step-title-text'>4. Get Informed</p>
						</div>
						<p className='help-step-description-text'>
							Cryptocurrency is still in it’s infancy and is a high-risk market. That means it can bring huge returns
							but also quick losses. The crypto market is also strongly impacted by news and online media. Remember that
							staying informed maximizes your returns, but only trade what you can afford to lose.
						</p>
						<button
							className='help-step-button'
							onClick={() => {
								window.open("https://vtrader.io/news", "_blank");
							}}
						>
							READ
						</button>
					</div>

					<div className='help-step-container'>
						<div className='help-step-title-container'>
							<img src={fifthStepIcon} className='help-step-icon' />
							<p className='help-step-title-text'>5. Start Trading</p>
						</div>
						<p className='help-step-description-text'>
							We offer you information about the assets (price history, charts, orders, ask and bid). You decide the
							price at which you buy or sell.
						</p>
						<button
							className='help-step-button'
							onClick={() => {
								history.push(`/trade`);
							}}
						>
							ADD FUNDS
						</button>
					</div>

					<div className='help-step-container'>
						<div className='help-step-title-container'>
							<img src={sixthStepIcon} className='help-step-icon' />
							<p className='help-step-title-text'>6. Withdraw your funds</p>
						</div>
						<p className='help-step-description-text'>
							You’re able to withdraw your funds on the blockchain or in your bank account. However, before you do this
							you need to complete the <span style={{ textDecoration: "underline" }}>Tier 2 Verification</span> for
							legal compliance to prevent money laundering and other malicious activity.
						</p>
						<button
							className='help-step-button'
							onClick={() => {
								history.push(`/withdraw`);
							}}
						>
							WITHDRAW
						</button>
					</div>

					<div className='help-step-container'>
						<div className='help-step-title-container'>
							<img src={seventhStepIcon} className='help-step-icon' />
							<p className='help-step-title-text'>7. Download the App</p>
						</div>
						<p className='help-step-description-text'>
							Our mobile apps make crypto transfers free between our members. You will be able to easily transfer crypto
							to others on vTrader without any additional fees. Available on both{" "}
							<a style={{ color: "black" }} href='https://apps.apple.com/ro/app/vtrader/id1463403108'>
								Apple App Store
							</a>{" "}
							and{" "}
							<a style={{ color: "black" }} href='https://play.google.com/store/apps/details?id=com.vtrader.droid'>
								Google Play
							</a>
							.
						</p>
						<button
							className='help-step-button'
							onClick={() => {
								window.open("https://apps.apple.com/ro/app/vtrader/id1463403108", "_blank");
							}}
						>
							DOWNLOAD
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Help;
