import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Field, Form } from "react-final-form";

import { useAppSelector } from "src/hooks/redux";

const TierOneForm = () => {
	const { tierOneInfo, tierZeroInfo } = useAppSelector((state) => state.accountSlice);

	const [shouldUsePersonaCheck, setShouldUsePersonaCheck] = useState(false);
	const [shouldUseBvn, setShouldUseBvn] = useState(false);

	useEffect(() => {}, []);

	return (
		<div>
			<div className='account-tier-one-form-container'>
				<Form
					onSubmit={() => {}}
					// validate={}
					initialValues={{ ...tierOneInfo, ...tierZeroInfo }}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit} className='grid w-full grid-cols-2'>
							<Field
								name='firstName'
								render={({ input, meta }) => (
									<input type='text' placeholder='First Name' disabled={true} title='First Name' {...input} />
								)}
							/>
							<Field
								name='lastName'
								render={({ input, meta }) => <input type='text' placeholder='Last Name' disabled={true} {...input} />}
							/>
							<Field
								name='email'
								render={({ input, meta }) => (
									<input type='email' placeholder='Email address' disabled={true} {...input} />
								)}
							/>
							<Field
								name='phoneNumber'
								render={({ input, meta }) => (
									<input type='text' placeholder='Phone Number' disabled={true} {...input} />
								)}
							/>

							<Field
								name='address'
								render={({ input, meta }) => <input type='text' placeholder='Address*' disabled={true} {...input} />}
							/>
							<Field
								name='state'
								render={({ input, meta }) => (
									<input type='text' placeholder='State/Province*' disabled={true} {...input} />
								)}
							/>

							<Field
								name='city'
								render={({ input, meta }) => <input type='text' placeholder='City*' disabled={true} {...input} />}
							/>
							<Field
								name='postalCode'
								render={({ input, meta }) => (
									<input type='text' placeholder='Zip/Postal Code*' disabled={true} {...input} />
								)}
							/>
							<Field
								name='country'
								render={({ input, meta }) => <input type='text' placeholder='Country' disabled={true} {...input} />}
							/>
							<Field
								name='birthdate'
								render={({ input, meta }) => (
									<ReactDatePicker
										title='Birthday'
										// selected={birthDate}
										placeholderText='Birthdate*'
										dateFormat='MM/dd/yyyy'
										showYearDropdown
										showMonthDropdown
										scrollableYearDropdown
										minDate={new Date("1940-01-01")}
										maxDate={new Date("2004-12-31")}
										yearDropdownItemNumber={40}
										// disabled={hasTierOne || hasPendingTierOne}
										className='w-full'
										{...input}
									/>
								)}
							/>
						</form>
					)}
				/>
			</div>

			<div className='rectangle' style={{ marginBottom: "20px" }}></div>

			{/* {shouldUsePersonaCheck && (
				<p className='tier-one-one-instruction-text'>
					Please provide your current address and birthday. Once you&apos;ve completed this step, you&apos;ll be guided
					through a simple verification process. This will involve taking a selfie and uploading a national document.
				</p>
			)}
			{shouldUseBvn && (
				<div>
					<p className='tier-one-one-instruction-text'>
						1. Provide your current address, birthday and BVN identification number.
					</p>
					<p className='tier-one-one-instruction-text'>
						2. Confirm your identity by uploading a selfie (picture of yourself) in one of the following formats
						(.pdf,.jpg,.jpeg,.png,.pdf) of maximum 5MB size.{" "}
					</p>
				</div>
			)}
			{!shouldUsePersonaCheck && !shouldUseBvn && (
				<div>
					<p className='tier-one-one-instruction-text'>
						Please provide your current address, birthday. Also, confirm your identity by uploading the following
						documents (.pdf,.jpg,.jpeg,.png,.pdf) of maximum 5MB size:
					</p>
					<p className='tier-one-one-instruction-text'>
						1. A copy of Passport or ID card from both sides. The information must be clearly visible and readable
					</p>
					<p className='tier-one-one-instruction-text'>2. A selfie (picture of yourself) holding your uploaded ID</p>
				</div>
			)}

			{(shouldUseBvn || !shouldUsePersonaCheck) && (
				<div>
					<div className='rectangle' style={{ backgroundColor: "#DDD", marginBottom: "20px", marginTop: "20px" }}></div>

					<div className='account-files-table-container'>
						<table style={{ width: "100%" }}>
							<thead>
								<tr>
									<th style={{ fontWeight: "bold", width: "50%" }} className='account-files-table-text'>
										Document
									</th>
									<th style={{ fontWeight: "bold", width: "20%" }} className='account-files-table-text'>
										Size
									</th>
									<th style={{ fontWeight: "bold", width: "20%" }} className='account-files-table-text'>
										Status
									</th>
									<th style={{ fontWeight: "bold", width: "10%" }} className='account-files-table-text'>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{allTierOneFiles.map((file, index) => (
									<tr key={index}>
										<td className='account-files-table-text'>{file.name}</td>
										<td className='account-files-table-text'>{(file.size / 1024).toFixed(2)} KB</td>
										<td className='account-files-table-text'>{file.status}</td>
										<td className='account-files-table-text'>
											<div className='account-files-actions-container'>
												{file.status === "TO BE UPLOADED" && (
													<p
														className='account-files-table-text'
														onClick={() => handleTierOneFileDelete(index)}
														style={{ cursor: "pointer" }}
														title='Delete document'
													>
														X
													</p>
												)}
												{file.status !== "TO BE UPLOADED" && (
													<p
														className='account-files-table-text'
														onClick={() => handleFileDownload(file)}
														style={{ cursor: "pointer", fontWeight: "bold" }}
														title='Download document'
													>
														↓
													</p>
												)}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className='account-files-table-upload-container'>
						<input
							type='file'
							accept='.pdf, .jpg, .jpeg, .png'
							onChange={handleTierOneFileChange}
							ref={fileInputRef}
							style={{ display: "none" }}
						/>
						<button
							onClick={handleTierFileUploadClick}
							style={{
								backgroundColor: shouldDisableTierOneUploadButton || hasTierOne || hasPendingTierOne ? "#BBB" : "#111",
							}}
							className='account-files-upload-button'
							disabled={shouldDisableTierOneUploadButton || hasTierOne || hasPendingTierOne}
						>
							+ ADD FILES
						</button>
					</div>
				</div>
			)}

			<div className='account-request-verification-button-container'>
				<button
					className='account-request-verification-button'
					disabled={shouldDisableTierOneRequestButton || hasTierOne || hasPendingTierOne}
					style={{
						backgroundColor: shouldDisableTierOneRequestButton || hasTierOne || hasPendingTierOne ? "#BBB" : "#111",
					}}
					onClick={() => {
						handleTierSubmitClick("tier1");
					}}
				>
					REQUEST VERIFICATION
				</button>
			</div> */}
		</div>
	);
};

export default TierOneForm;
