import axios, { InternalAxiosRequestConfig } from "axios";

import { BACKEND_URL } from "src/constants";

const instance = axios.create({
	baseURL: BACKEND_URL,
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	const accessToken = localStorage.getItem("accessToken");
	config.headers.set("Authorization", `Bearer ${accessToken}`);

	return config;
});

export default instance;
