import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import { api } from "../../services/api/api";
import HttpClient from "../../services/api/HttpClient";
import { ForgotPasswordViewModel } from "../../services/api/web/request/ForgotPasswordViewModel";
import { useAuth } from "../../services/auth/AuthContext";
import { RECAPTCHA_SITE_KEY } from "../../services/constants";
import { showErrorToast, showSuccessToast } from "../../services/utils/toastUtils";

const ForgotPassword = () => {
	const { isLoggedIn } = useAuth();

	const [email, setEmail] = useState("");
	const [resetPasswordButtonClass, setResetPasswordButtonClass] = useState("login-button");

	const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);

	const [emailValidation, setEmailValidation] = useState("");

	useEffect(() => {
		const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
		const isValid = email.trim() !== "" && isEmailValid && isRecaptchaChecked;
		setIsFormValid(isValid);
		setEmailValidation(!isEmailValid ? "Email should have a valid format" : "");
		setResetPasswordButtonClass(isValid ? "login-button" : "login-button disabled");
	}, [email, isRecaptchaChecked]);

	if (isLoggedIn) {
		return <Redirect to='/dashboard' />;
	}

	const httpClient = new HttpClient();
	const recaptchaRef = React.createRef<ReCAPTCHA>();

	const handleEmailChange = (event: any) => {
		setEmail(event.target.value);
	};

	const handleRecaptchaChange = (event: any) => {
		setIsRecaptchaChecked(event !== null);
	};

	const handleResetPasswordButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		if (!isFormValid) {
			return;
		}

		const captchaVal = recaptchaRef.current?.getValue();
		if (!captchaVal) {
			return;
		}

		recaptchaRef.current?.reset();

		const forgotPasswordViewModel: ForgotPasswordViewModel = {
			captcha: captchaVal,
			email: email,
		};

		try {
			const response = await httpClient.post<ForgotPasswordViewModel, any>(
				api.web.FORGOT_PASSWORD,
				forgotPasswordViewModel
			);

			if (response.status === 200) {
				showSuccessToast(toast, "We've sent a reset link to your email address.");
				setEmail("");
				setIsFormValid(false);
			}
		} catch (error) {
			showErrorToast(toast, "An error occurred. Please try again later. If the problem persists, contact our staff.");
		}
	};

	return (
		<div className='login-form'>
			<p style={{ marginBottom: "-10px" }}>Recover Password</p>
			<form>
				<input type='email' placeholder='Email' value={email} onChange={handleEmailChange} />
				{emailValidation && email && <div className='validation-message'>{emailValidation}</div>}
				<div className='recaptcha'>
					<ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} ref={recaptchaRef} onChange={handleRecaptchaChange} />
				</div>
				<button className={resetPasswordButtonClass} disabled={!isFormValid} onClick={handleResetPasswordButtonClick}>
					SEND RESET LINK
				</button>
			</form>
			<Link to='/login' style={{ color: "black", fontSize: "13px" }}>
				Go back to login
			</Link>
		</div>
	);
};

export default ForgotPassword;
