import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const customStyles = {
	content: {
		width: "320px",
		height: "120px",
		margin: "auto",
		top: "20%",
		left: "50%",
		right: "50%",
		transform: "translate(-50%, -50%)",
		padding: "20px",
		border: "1px solid #000",
		boxShadow: "none",
	},
};

interface ConfirmSubmitTierModalProps {
	closeModal: () => void;
	confirm: () => Promise<void>;
	dismiss: () => void;
	message: string;
	height: string;
}

Modal.setAppElement("#root");

const ConfirmSubmitTierModal: React.FC<ConfirmSubmitTierModalProps> = ({
	closeModal,
	confirm,
	dismiss,
	message,
	height,
}) => {
	const [scrollDisabled] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	customStyles.content.height = height;

	useEffect(() => {
		if (!scrollDisabled) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [scrollDisabled]);

	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			await confirm();
		} catch (error) {
			// Handle errors if necessary
		} finally {
			setIsSubmitting(false);
			closeModal();
		}
	};

	return (
		<div>
			<Modal isOpen={true} onRequestClose={closeModal} style={customStyles} shouldCloseOnOverlayClick={!isSubmitting}>
				<div style={{ textAlign: "center" }}>
					{isSubmitting ? (
						<>
							<p style={{ fontSize: "12px" }}>Submitting application...</p>
							<div className='loading-spinner'></div>
						</>
					) : (
						<>
							<p style={{ textAlign: "center", fontSize: "12px" }}>{message}</p>
							<div
								className='rectangle'
								style={{ backgroundColor: "#DDD", marginBottom: "25px", marginTop: "25px" }}
							></div>
							<div className='setting-section-two-factor-buttons-container'>
								<button
									className='setting-section-two-factor-button-disabled'
									style={{ cursor: "pointer" }}
									onClick={() => {
										dismiss();
										closeModal();
									}}
								>
									CLOSE
								</button>
								<button
									className='setting-section-two-factor-button-enabled'
									style={{ cursor: "pointer" }}
									onClick={handleSubmit}
								>
									SUBMIT
								</button>
							</div>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default ConfirmSubmitTierModal;
