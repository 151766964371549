import "./Header.css";

import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { Link, useHistory } from "react-router-dom";
import { io } from "socket.io-client";

import { BACKEND_URL } from "src/constants";
import { coinData } from "src/constants/coin";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { updateAccount } from "src/redux/slices/account.slice";
import { updateAuth } from "src/redux/slices/auth.slice";

import { formatNumber } from "../../services/utils/formatNumber";
import AlertsModal from "../alerts-modal/AlertsModal";
import LeftPanelModal from "../left-panel-modal/LeftPanelModal";
import LogoutModal from "../logout-modal/LogoutModal";
import RightPanelModal from "../right-panel-modal/RightPanelModal";
import WalletSidebarModal from "../wallet-details-modal/WalletSidebarModal";
import logoIcon from "./icons/lg.png";
import menuIcon from "./icons/menu.png";

const Header = () => {
	const dispatch = useAppDispatch();
	const { username } = useAppSelector((state) => state.accountSlice);
	const history = useHistory();

	const [marketData, setMarketData] = useState<any[]>(coinData);
	const [isLeftPanelModalOpen, setLeftPanelModalOpen] = useState(false);
	const [isRightPanelModalOpen, setRightPanelModalOpen] = useState(false);
	const [isWalletSidebarModalOpen, setWalletSidebarOpen] = useState(false);
	const [isAlertsModalOpen, setAlertsModalOpen] = useState(false);
	const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

	useEffect(() => {
		const socket = io(BACKEND_URL);

		socket.on("connect", function () {
			socket.emit("market");
		});

		socket.on("update", function (data) {
			const updatedCoinData = [];
			for (let i = 0; i < coinData.length; i++) {
				const coinPriceData = data.find((d: any) => d.market === coinData[i].name);
				updatedCoinData[i] = { ...coinData[i], ...coinPriceData };
			}
			setMarketData(updatedCoinData);
		});
	}, []);

	const openLeftPanelModal = () => {
		setLeftPanelModalOpen(true);
	};

	const openRightPanelModal = () => {
		setRightPanelModalOpen(true);
	};

	const openWalletSidebarModal = () => {
		setWalletSidebarOpen(true);
	};

	return (
		<div>
			{isLeftPanelModalOpen && <LeftPanelModal closeModal={() => setLeftPanelModalOpen(false)} />}
			{isRightPanelModalOpen && (
				<RightPanelModal
					closeModal={() => setRightPanelModalOpen(false)}
					openWalletSidebarModal={() => setWalletSidebarOpen(true)}
					openAlertsModal={() => setAlertsModalOpen(true)}
					openLogoutModal={() => setIsLogoutModalOpen(true)}
				/>
			)}
			{isWalletSidebarModalOpen && <WalletSidebarModal closeModal={() => setWalletSidebarOpen(false)} />}
			{isAlertsModalOpen && <AlertsModal closeModal={() => setAlertsModalOpen(false)} />}
			{isLogoutModalOpen && (
				<LogoutModal
					closeModal={() => setIsLogoutModalOpen(false)}
					confirm={() => {
						dispatch(updateAuth({ token: "", refreshToken: "" }));
						dispatch(updateAccount({}));
						history.push("/login");
					}}
				/>
			)}
			<header className='header'>
				<Marquee>
					{marketData && (
						<div className='market-data'>
							{marketData.map((item) => (
								<span className='market-data-item' key={`market-data-item-${item.name}`}>
									<img src={item.icon} alt='ic' className='market-icon' />
									<span className='market-data-text'>
										{item.name} ${formatNumber(item.last)}
									</span>
								</span>
							))}
						</div>
					)}
				</Marquee>

				<div className='logo-container' style={{ justifyContent: "space-between" }}>
					{!isLeftPanelModalOpen && (
						<img src={menuIcon} alt='Menu' className='menu-icon' onClick={openLeftPanelModal} />
					)}
					{isLeftPanelModalOpen && (
						<img src={menuIcon} alt='Menu' className='menu-icon' style={{ visibility: "hidden" }} />
					)}
					<div className='logo'>
						<Link to='/dashboard'>
							<img className='logo-image' src={logoIcon} alt='Logo' />
						</Link>
					</div>

					{username && (
						<div>
							{!isRightPanelModalOpen && (
								<div className='header-user-circle-container' onClick={openRightPanelModal}>
									<p className='header-user-circle-text'>{username.charAt(0)}</p>
								</div>
							)}

							{isRightPanelModalOpen && (
								<div className='header-user-circle-container' style={{ backgroundColor: "white" }}></div>
							)}
						</div>
					)}
					{!username && (
						<div className='header-user-circle-container' style={{ backgroundColor: "white", cursor: "default" }}></div>
					)}
				</div>
			</header>
		</div>
	);
};

export default Header;
