import "../../layout/Dashboard/layout.css";
import "react-toastify/dist/ReactToastify.css";

import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { MobileUserTransferViewModel } from "../../services/api/web/response/MobileUserTransferViewModel";
import UserService from "../../services/user/UserService";
import { getCurrencySymbol } from "../../services/utils/cryptoSymbols";
import { formatDateForTable } from "../../services/utils/dateUtils";
import { formatNumber } from "../../services/utils/formatNumber";
import { showSuccessToast } from "../../services/utils/toastUtils";
import { formatTxStatus, getTxStatusColor } from "../../services/utils/txStatusUtils";
import appleIcon from "./apple.png";
import gstoreIcon from "./gstore.png";
import leftIcon from "./left.png";
import rightIcon from "./right.png";
import sortIcon from "./sort.png";
// import pdf = ReactPDF.pdf;

const MobileActivity = () => {
	const userService = UserService.getInstance();

	const [searchBoxText, setSearchBoxText] = useState("");

	const [userId, setUserId] = useState(0);

	const [mobileTransfers, setMobileTransfers] = useState<Array<MobileUserTransferViewModel>>([]);
	const [displayedMobileTransfers, setDisplayedMobileTransfers] = useState<Array<MobileUserTransferViewModel>>([]);

	const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const [currentPageSize, setCurrentPageSize] = useState(10);

	const [sortDirectionPerColumn, setSortDirectionPerColumn] = useState<Array<number>>(Array(10).fill(1));

	useEffect(() => {
		const user = userService.getUser();
		if (user) {
			setUserId(user?.id);
		}

		const txMobileTransfers = userService.getMobileTransfers();
		setMobileTransfers(txMobileTransfers);
		setDisplayedMobileTransfers(txMobileTransfers);
	}, []); // Empty dependency array means the effect runs only once

	const copyToClipboard = (text: string) => {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("copy");
		document.body.removeChild(textArea);
	};

	const pageDisplayedMobileTransfers = () => {
		const startIndex = (currentPageNumber - 1) * currentPageSize;

		const slicedMobileTransfers = mobileTransfers.slice(startIndex, startIndex + currentPageSize);

		setDisplayedMobileTransfers(slicedMobileTransfers);
	};

	useEffect(() => {
		pageDisplayedMobileTransfers();
	}, [currentPageNumber, currentPageSize, mobileTransfers]);

	const handleSearchBoxTextChange = (event: any) => {
		setSearchBoxText(event.target.value);
	};

	const handlePageSizeChange = (event: any) => {
		setCurrentPageNumber(1);
		setCurrentPageSize(parseInt(event.target.value, 10));
	};

	useEffect(() => {
		if (searchBoxText.length < 3) {
			setDisplayedMobileTransfers([...mobileTransfers]);
			pageDisplayedMobileTransfers();
			return;
		}

		const filteredMobileTransfers = mobileTransfers.filter((mobileTransfer) => {
			const formattedCreated = formatDateForTable(mobileTransfer.created).toLowerCase();

			// Check if any property contains the searchBoxText (case-insensitive)
			return Object.values(mobileTransfer).some((value) => {
				if (typeof value === "number" && value.toString().toLowerCase().includes(searchBoxText.toLowerCase())) {
					return true;
				} else if (typeof value === "string" && value.toLowerCase().includes(searchBoxText.toLowerCase())) {
					return true;
				} else if (value === mobileTransfer.created && formattedCreated.includes(searchBoxText.toLowerCase())) {
					return true;
				}
				return false;
			});
		});

		setDisplayedMobileTransfers(filteredMobileTransfers);
	}, [searchBoxText]);

	const handleSortTable = (column: number) => {
		const sortedMobileTransfers = [...mobileTransfers];

		sortDirectionPerColumn[column] = sortDirectionPerColumn[column] === 1 ? -1 : 1;

		switch (column) {
			case 0:
				sortedMobileTransfers.sort((a, b) => (a.id - b.id) * sortDirectionPerColumn[column]);
				break;
			case 1:
				sortedMobileTransfers.sort((a, b) => a.type.localeCompare(b.type) * sortDirectionPerColumn[column]);
				break;
			case 2:
				sortedMobileTransfers.sort((a, b) => (a.created - b.created) * sortDirectionPerColumn[column]);
				break;
			case 3:
				sortedMobileTransfers.sort((a, b) => (a.amount - b.amount) * sortDirectionPerColumn[column]);
				break;
			case 4:
				sortedMobileTransfers.sort((a, b) => a.userName.localeCompare(b.userName) * sortDirectionPerColumn[column]);
				break;
			case 5:
				sortedMobileTransfers.sort(
					(a, b) => a.destinationUserName.localeCompare(b.destinationUserName) * sortDirectionPerColumn[column]
				);
				break;
			case 6:
				sortedMobileTransfers.sort((a, b) => a.status.localeCompare(b.status) * sortDirectionPerColumn[column]);
				break;
			default:
				// Handle the case when an invalid column is provided
				break;
		}

		setMobileTransfers(sortedMobileTransfers);
	};

	const generateCsvFromMobileTransfers = () => {
		const csvRows = [];

		// Add header row
		const headerRow = ["ID", "Type", "Date", "Amount", "From User", "To User", "Status"];
		csvRows.push(headerRow.join(","));

		// Add data rows
		mobileTransfers.forEach((row) => {
			const dataRow = [
				row.id,
				row.type,
				formatDateForTable(row.created).replace(/,/g, "."),
				formatNumber(row.amount, 8, true).replace(/,/g, "."),
				row.userName,
				row.destinationUserName,
				row.status,
			];
			csvRows.push(dataRow.join(","));
		});

		return csvRows.join("\n");
	};

	const downloadMobileTransfersCsv = () => {
		const csvData = generateCsvFromMobileTransfers();

		// Create a CSV blob
		const blob = new Blob([csvData], { type: "text/csv" });
		const csvUrl = URL.createObjectURL(blob);

		// Create a download link and trigger the click event to download the CSV file
		const a = document.createElement("a");
		a.href = csvUrl;
		a.download = `vtrader_mobile_transfers_history_report_${new Date()
			.toISOString()
			.replace(/[^a-zA-Z0-9]/g, "_")
			.slice(0, -5)}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(csvUrl);
	};

	const downloadMobileTransfersPdf = () => {
		const data = generateCsvFromMobileTransfers()
			.split("\n")
			.map((row) => row.split(","));

		const styles = StyleSheet.create({
			cell: {
				fontSize: 10,
				margin: 2,
				padding: 2,
			},
		});

		const headerRow = ["ID", "Type", "Date", "Amount", "From User", "To User", "Status"];

		const pdfData = (
			<Document>
				<Page size='A4'>
					<View>
						{data.slice(1).map((row, rowIndex) => (
							<View key={rowIndex} style={{ flexDirection: "column", marginBottom: 10 }}>
								{row.map((cell, cellIndex) => (
									<Text key={cellIndex} style={styles.cell}>
										{headerRow[cellIndex]} : {cell}
									</Text>
								))}
							</View>
						))}
					</View>
				</Page>
			</Document>
		);

		// pdf(pdfData)
		//     .toBlob()
		//     .then((blob) => {
		//         const filename = `vtrader_mobile_transfers_history_report_${new Date().toISOString().replace(/[^a-zA-Z0-9]/g, '_').slice(0, -5)}.pdf`;
		//         saveAs(blob, filename);
		//     });
	};

	const getAmountText = (row: MobileUserTransferViewModel): string => {
		return (
			(row.userId === userId ? "-" : "+") + " " + getCurrencySymbol(row.currency) + formatNumber(row.amount, 6, true)
		);
	};

	return (
		<div>
			<div className='center-container'>
				<div className='menu'>
					<Link to='/dashboard' className='link-custom'>
						<p>DASHBOARD</p>
					</Link>
					<Link to='/orders' className='link-custom'>
						<p>ORDERS</p>
					</Link>
					<Link to='/trade' className='link-custom'>
						<p>TRADE</p>
					</Link>
					<Link to='/deposit' className='link-custom'>
						<p>ADD FUNDS</p>
					</Link>
					<Link to='/withdraw' className='link-custom'>
						<p>WITHDRAW FUNDS</p>
					</Link>
				</div>
			</div>

			<h1 className='title'>Mobile Activity</h1>
			<div className='dashboard-container'>
				<div
					className='history-table-header-container'
					style={{
						justifyContent: displayedMobileTransfers.length > 0 ? "space-between" : "center",
					}}
				>
					{(displayedMobileTransfers.length > 0 || searchBoxText.trim().length > 0) && (
						<input
							type='text'
							placeholder='SEARCH'
							style={{ backgroundColor: "white", width: "380px", marginRight: "10px" }}
							value={searchBoxText}
							onChange={handleSearchBoxTextChange}
						/>
					)}
					{displayedMobileTransfers.length > 0 && (
						<div className='history-table-header-export-buttons-container'>
							<p
								className='history-table-header-text'
								onClick={() => {
									downloadMobileTransfersPdf();
								}}
							>
								EXPORT PDF
							</p>
							<p
								className='history-table-header-text'
								onClick={() => {
									downloadMobileTransfersCsv();
								}}
							>
								EXPORT CSV
							</p>
							<p
								className='history-table-header-text'
								onClick={() => {
									copyToClipboard(generateCsvFromMobileTransfers());
									showSuccessToast(toast, "Mobile activity copied to clipboard");
								}}
							>
								COPY TO CLIPBOARD
							</p>
						</div>
					)}
				</div>
			</div>

			{displayedMobileTransfers.length > 0 && (
				<div>
					<div className='history-table-container'>
						<table style={{ borderCollapse: "collapse", width: "1500px" }}>
							<thead>
								<tr className='history-table-row'>
									<th className='history-table-thead-text'>
										<div className='history-table-text-and-icon-header'>
											<p>ID</p>
											<div className='history-table-sort-icon-container'>
												<img
													src={sortIcon}
													alt='ic'
													className='history-table-sort-icon'
													onClick={() => {
														handleSortTable(0);
													}}
												/>
											</div>
										</div>
										<div className='history-table-thead-hidden-section'></div>
									</th>
									<th className='history-table-thead-text'>
										<div className='history-table-text-and-icon-header'>
											<p>Type</p>
											<div className='history-table-sort-icon-container'>
												<img
													src={sortIcon}
													alt='ic'
													className='history-table-sort-icon'
													onClick={() => {
														handleSortTable(1);
													}}
												/>
											</div>
										</div>
										<div className='history-table-thead-hidden-section'></div>
									</th>
									<th className='history-table-thead-text'>
										<div className='history-table-text-and-icon-header'>
											<p>Date</p>
											<div className='history-table-sort-icon-container'>
												<img
													src={sortIcon}
													alt='ic'
													className='history-table-sort-icon'
													onClick={() => {
														handleSortTable(2);
													}}
												/>
											</div>
										</div>
										<div className='history-table-thead-hidden-section'></div>
									</th>
									<th className='history-table-thead-text'>
										<div className='history-table-text-and-icon-header'>
											<p>Amount</p>
											<div className='history-table-sort-icon-container'>
												<img
													src={sortIcon}
													alt='ic'
													className='history-table-sort-icon'
													onClick={() => {
														handleSortTable(3);
													}}
												/>
											</div>
										</div>
										<div className='history-table-thead-hidden-section'></div>
									</th>
									<th className='history-table-thead-text'>
										<div className='history-table-text-and-icon-header'>
											<p>From</p>
											<div className='history-table-sort-icon-container'>
												<img
													src={sortIcon}
													alt='ic'
													className='history-table-sort-icon'
													onClick={() => {
														handleSortTable(4);
													}}
												/>
											</div>
										</div>
										<div className='history-table-thead-hidden-section'></div>
									</th>
									<th className='history-table-thead-text'>
										<div className='history-table-text-and-icon-header'>
											<p>To</p>
											<div className='history-table-sort-icon-container'>
												<img
													src={sortIcon}
													alt='ic'
													className='history-table-sort-icon'
													onClick={() => {
														handleSortTable(5);
													}}
												/>
											</div>
										</div>
										<div className='history-table-thead-hidden-section'></div>
									</th>
									<th className='history-table-thead-text'>
										<div className='history-table-text-and-icon-header'>
											<p>Status</p>
											<div className='history-table-sort-icon-container'>
												<img
													src={sortIcon}
													alt='ic'
													className='history-table-sort-icon'
													onClick={() => {
														handleSortTable(6);
													}}
												/>
											</div>
										</div>
										<div className='history-table-thead-hidden-section'></div>
									</th>
								</tr>
							</thead>
							<tbody>
								{displayedMobileTransfers.map((row, index) => (
									<tr key={index} className='history-table-row'>
										<td className='history-table-cell'>{"#" + row.id}</td>
										<td className='history-table-cell'>Mobile Transfer</td>
										<td className='history-table-cell'>{formatDateForTable(row.created)}</td>
										<td className='history-table-cell'>{getAmountText(row)}</td>
										<td className='history-table-cell'>{row.userName}</td>
										<td className='history-table-cell'>{row.destinationUserName}</td>
										<td
											className='history-table-cell'
											style={{
												width: "3%",
												color: getTxStatusColor(row.status),
											}}
										>
											{formatTxStatus(row.status)}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						{searchBoxText.length < 3 && (
							<div>
								<div className='history-pagination-container'>
									<p
										style={{ color: "black", alignSelf: "center", cursor: "inherit" }}
										className='history-table-header-text'
									>
										Showing{" "}
										{`${(currentPageNumber - 1) * currentPageSize + 1}-${Math.min(
											currentPageNumber * currentPageSize,
											mobileTransfers.length
										)}`}{" "}
										of {formatNumber(mobileTransfers.length, 0, false)}
									</p>

									<div className='history-pagination-buttons-container'>
										<button
											className='history-pagination-button'
											disabled={currentPageNumber === 1}
											onClick={() => {
												setCurrentPageNumber(currentPageNumber - 1);
											}}
										>
											<div className='history-pagination-button-icon-text-container'>
												<div className='history-pagination-icon-container' style={{ paddingRight: "7px" }}>
													<img src={leftIcon} className='history-pagination-button-icon' alt='icon' />
												</div>
												<p>Previous</p>
											</div>
										</button>
										<button
											className='history-pagination-button'
											disabled={currentPageNumber * currentPageSize > mobileTransfers.length}
											onClick={() => {
												setCurrentPageNumber(currentPageNumber + 1);
											}}
										>
											<div className='history-pagination-button-icon-text-container'>
												<p>Next</p>
												<div className='history-pagination-icon-container' style={{ paddingLeft: "5px" }}>
													<img src={rightIcon} className='history-pagination-button-icon' alt='icon' />
												</div>
											</div>
										</button>
									</div>
									<select
										value={currentPageSize}
										onChange={handlePageSizeChange}
										className='history-current-page-size-dropdown'
									>
										{[10, 25, 50, 100].map((pageSize) => (
											<option key={pageSize} value={pageSize}>
												View {pageSize}
											</option>
										))}
									</select>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			{displayedMobileTransfers.length === 0 && searchBoxText.trim().length === 0 && (
				<div>
					<div className='orders-no-orders-to-display-container' style={{ marginTop: "100px" }}>
						<p>You have no transfer history on our mobile app.</p>
						<div className='mobile-activity-apps-container'>
							<div
								className='mobile-activity-app-button'
								onClick={() => {
									window.open("https://apps.apple.com/ro/app/vtrader/id1463403108", "_blank");
								}}
							>
								<div className='mobile-activity-app-icon-and-text-container'>
									<img src={appleIcon} className='mobile-activity-icon' />
									<p className='mobile-activity-app-text'>APPLE APP STORE</p>
								</div>
							</div>
							<div
								className='mobile-activity-app-button'
								onClick={() => {
									window.open("https://play.google.com/store/apps/details?id=com.vtrader.droid", "_blank");
								}}
							>
								<div className='mobile-activity-app-icon-and-text-container'>
									<img src={gstoreIcon} className='mobile-activity-icon' />
									<p className='mobile-activity-app-text'>GOOGLE PLAY</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MobileActivity;
