import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const customStyles = {
	content: {
		width: "320px",
		height: "130px",
		margin: "auto",
		top: "20%",
		left: "50%",
		right: "50%",
		transform: "translate(-50%, -50%)",
		padding: "20px",
		border: "1px solid #000",
		boxShadow: "none",
	},
};

interface CancelOrderModalProps {
	closeModal: () => void;
	confirmCancelOrder: () => Promise<void>;
	dismiss: () => void;
	orderId: number;
}

Modal.setAppElement("#root");

const CancelOrderModal: React.FC<CancelOrderModalProps> = ({ closeModal, confirmCancelOrder, dismiss, orderId }) => {
	const [scrollDisabled] = useState(false);
	const [isCancelling, setIsCancelling] = useState(false);

	useEffect(() => {
		if (!scrollDisabled) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [scrollDisabled]);

	const handleCancelOrder = async () => {
		setIsCancelling(true);
		try {
			await confirmCancelOrder();
		} catch (error) {
			// Handle errors if necessary
			console.log("Error", error);
		} finally {
			setIsCancelling(false);
			closeModal();
		}
	};

	return (
		<div>
			<Modal isOpen={true} onRequestClose={closeModal} style={customStyles} shouldCloseOnOverlayClick={!isCancelling}>
				<div style={{ textAlign: "center" }}>
					{isCancelling ? (
						<>
							<p style={{ fontSize: "12px" }}>Canceling Order #{orderId}...</p>
							<div className='loading-spinner'></div>
						</>
					) : (
						<>
							<p style={{ textAlign: "center", fontSize: "12px" }}>{"Confirm order cancellation #" + orderId + " ?"}</p>
							<div
								className='rectangle'
								style={{ backgroundColor: "#DDD", marginBottom: "25px", marginTop: "25px" }}
							></div>
							<div className='setting-section-two-factor-buttons-container'>
								<button
									className='setting-section-two-factor-button-disabled'
									style={{ cursor: "pointer" }}
									onClick={() => {
										dismiss();
										closeModal();
									}}
								>
									CLOSE
								</button>
								<button
									className='setting-section-two-factor-button-enabled'
									style={{ cursor: "pointer" }}
									onClick={handleCancelOrder}
								>
									CANCEL ORDER
								</button>
							</div>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default CancelOrderModal;
