import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const customStyles = {
	content: {
		width: "320px",
		height: "120px",
		margin: "auto",
		top: "20%",
		left: "50%",
		right: "50%",
		transform: "translate(-50%, -50%)",
		padding: "20px",
		border: "1px solid #000",
		boxShadow: "none",
	},
};

interface ConfirmSubmitTierModalProps {
	closeModal: () => void;
	confirm: () => void;
}

Modal.setAppElement("#root");

const LogoutModal: React.FC<ConfirmSubmitTierModalProps> = ({ closeModal, confirm }) => {
	const [scrollDisabled] = useState(false);

	useEffect(() => {
		if (!scrollDisabled) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [scrollDisabled]);

	return (
		<div>
			<Modal isOpen={true} onRequestClose={closeModal} style={customStyles} shouldCloseOnOverlayClick={false}>
				<div style={{ textAlign: "center" }}>
					<>
						<p style={{ textAlign: "center", fontSize: "12px" }}>Are you sure you want to Log Out ?</p>
						<div
							className='rectangle'
							style={{ backgroundColor: "#DDD", marginBottom: "25px", marginTop: "25px" }}
						></div>
						<div className='setting-section-two-factor-buttons-container'>
							<button
								className='setting-section-two-factor-button-disabled'
								style={{ cursor: "pointer" }}
								onClick={() => {
									closeModal();
								}}
							>
								CANCEL
							</button>
							<button
								className='setting-section-two-factor-button-enabled'
								style={{ cursor: "pointer" }}
								onClick={() => {
									confirm();
									closeModal();
								}}
							>
								LOG OUT
							</button>
						</div>
					</>
				</div>
			</Modal>
		</div>
	);
};

export default LogoutModal;
