import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import About from "./components/about/About";
import Account from "./components/account/Account";
import AmlPolicy from "./components/aml-policy/AmlPolicy";
import ConfirmEmail from "./components/confirm-email/ConfirmEmail";
import CookiePolicy from "./components/cookie-policy/CookiePolicy";
import Dashboard from "./components/dashboard/Dashboard";
import Deposit from "./components/deposit/Deposit";
import DepositHistory from "./components/deposit/DepositHistory";
import ExchangedAssets from "./components/exchanged-assets/ExchangedAssets";
import Faq from "./components/faq/Faq";
import Fees from "./components/fees/Fees";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import GenerateNewPassword from "./components/generate-new-password/GenerateNewPassword";
import Header from "./components/header/Header";
import logoIcon from "./components/header/icons/lg.png";
import Help from "./components/help/Help";
import Login from "./components/login/Login";
import MobileActivity from "./components/mobile-activity/MobileActivity";
import Orders from "./components/orders/Orders";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
import Referrals from "./components/referrals/Referrals";
import RefundPolicy from "./components/refund-policy/RefundPolicy";
import Register from "./components/register/Register";
import Settings from "./components/settings/Settings";
import TermsAndConditions from "./components/terms-and-conditions/TermsAndConditions";
import Trade from "./components/trade/Trade";
import Withdraw from "./components/withdraw/Withdraw";
import WithdrawHistory from "./components/withdraw/WithdrawHistory";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import instance from "./libs/axios";
import { updateAccount } from "./redux/slices/account.slice";
import { updateAuth } from "./redux/slices/auth.slice";

interface PrivateRouteProps {
	component: React.ComponentType<any>;
	path: string;
	exact?: boolean;
}

const AppRouter = () => {
	const dispatch = useAppDispatch();
	const { token } = useAppSelector((state) => state.authSlice);
	const [isLoading, setIsLoading] = useState(true); // State to track loading status

	useEffect(() => {
		const initializeApp = async () => {
			try {
				setIsLoading(true);

				if (token) {
					const { data } = await instance.get("/user");
					dispatch(updateAccount(data));
				}

				setIsLoading(false);
			} catch (err) {
				console.log(err);
				dispatch(updateAuth({ token: "", refreshToken: "" }));
				dispatch(updateAccount({}));
			}
		};

		initializeApp();
	}, [token]);

	const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => (
		<Route {...rest} render={(props) => (token ? <Component {...props} /> : <Redirect to='/login' />)} />
	);

	// Render the loading spinner or the application content based on isLoading
	if (isLoading) {
		return (
			<div>
				<div className='loading-container'>
					<img className='logo-image' src={logoIcon} alt='Logo' />
					<div style={{ textAlign: "center", paddingTop: "40px" }}>Loading...</div>
					<div className='loading-spinner'></div>
				</div>
			</div>
		);
	}

	return (
		<Router>
			<Header />
			<ToastContainer bodyClassName='toastify-style' style={{ minWidth: "350px", fontSize: "15px" }} />
			<Switch>
				<Route path='/login' component={Login} />
				<Route path='/register' component={Register} />
				<Route path='/forgot-password' component={ForgotPassword} />
				<Route path='/auth/email-handler/:confirmationToken' component={ConfirmEmail} />
				<Route path='/auth/reset-password/:forgotPasswordToken' component={GenerateNewPassword} />

				<Route path='/terms-and-conditions' component={TermsAndConditions} />
				<Route path='/privacy-policy' component={PrivacyPolicy} />
				<Route path='/cookie-policy' component={CookiePolicy} />
				<Route path='/aml-policy' component={AmlPolicy} />
				<Route path='/refund-policy' component={RefundPolicy} />
				<Route path='/faq' component={Faq} />
				<Route path='/about' component={About} />
				<Route path='/fees' component={Fees} />
				<Route path='/exchanged-assets' component={ExchangedAssets} />

				<PrivateRoute path='/dashboard' component={Dashboard} />
				<PrivateRoute path='/orders' component={Orders} />

				<PrivateRoute path='/trade' component={Trade} />
				<PrivateRoute path='/deposit' component={Deposit} />
				<PrivateRoute path='/deposit-history' component={DepositHistory} />

				<PrivateRoute path='/withdraw' component={Withdraw} />
				<PrivateRoute path='/withdraw-history' component={WithdrawHistory} />

				<PrivateRoute path='/account' component={Account} />
				<PrivateRoute path='/settings' component={Settings} />
				<PrivateRoute path='/mobile-activity' component={MobileActivity} />
				<PrivateRoute path='/referrals' component={Referrals} />
				<PrivateRoute path='/help' component={Help} />

				<Redirect from='/' to='/login' />
			</Switch>
		</Router>
	);
};

export default AppRouter;
