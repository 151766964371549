import "chartjs-adapter-date-fns";

import { Chart as ChartJS, ChartOptions, registerables } from "chart.js";
import { enGB } from "date-fns/locale";
import React from "react";
import { Line } from "react-chartjs-2";

interface PriceChartProps {
	data: [number, number][];
}

const PriceChart: React.FC<PriceChartProps> = ({ data }) => {
	ChartJS.register(...registerables);

	data.sort((a, b) => a[0] - b[0]);

	const formattedData = data
		.filter((item) => {
			const timestamp = item[0];
			const date = new Date(timestamp);

			// Check if the timestamp is exactly on the hour
			return date.getMinutes() === 0;
		})
		.map((item) => {
			const timestamp = item[0];
			const date = new Date(timestamp);

			// Format the date as "yyyy-MM-dd hh:mm"
			const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
				.toString()
				.padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
				.getHours()
				.toString()
				.padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;

			return [formattedDate, item[1]];
		});

	const chartData = {
		datasets: [
			{
				label: "",
				data: formattedData,
				borderColor: "black",
				fill: false,
				borderWidth: 1,
				pointRadius: 1,
			},
		],
	};

	const chartOptions: ChartOptions = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false, // Hide vertical grid lines
				},
				display: true,
				ticks: {
					callback: (value) => {
						if (+value % 24 !== 12) {
							return "";
						}
						const dataToParse = formattedData[+value][0] as string;
						const [dateString] = dataToParse.split(" ");
						const [, month, day] = dateString.split("-");
						const months = ["Jan", "Feb", "March", "Apr", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
						return `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]}`;
					},
				},
				adapters: {
					date: { locale: enGB },
					// distribution: "linear",
					// time: {
					// 	parser: "yyyy-MM-dd hh:mm",
					// 	unit: "hour",
					// },
					// title: {
					// 	display: true,
					// 	text: "Date",
					// },
				},
			},
			y: {
				title: { display: true, text: "Price in $" },
			},
		},
	};

	return <Line data={chartData} options={chartOptions as any} />;
};

export default PriceChart;
