import "../../layout/Dashboard/layout.css";
import "react-toastify/dist/ReactToastify.css";

import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import eventBus from "../../EventBus";
import GenericTransactionViewModel from "../../services/api/web/response/GenericTransactionViewModel";
import UserService from "../../services/user/UserService";
import UserServiceUtils from "../../services/user/UserServiceUtils";
import { getCurrencySymbol } from "../../services/utils/cryptoSymbols";
import { formatDateForTable } from "../../services/utils/dateUtils";
import { formatNumber } from "../../services/utils/formatNumber";
// import pdf = ReactPDF.pdf;
import { showSuccessToast } from "../../services/utils/toastUtils";
import { formatTxStatus, getTxStatusColor } from "../../services/utils/txStatusUtils";
import leftIcon from "./left.png";
import rightIcon from "./right.png";
import sortIcon from "./sort.png";

const WithdrawHistory = () => {
	const userService = UserService.getInstance();
	const userServiceUtils = UserServiceUtils.getInstance();
	const history = useHistory();

	const [hasTierTwo, setHasTierTwo] = useState(false);

	const [searchBoxText, setSearchBoxText] = useState("");

	const [withdrawals, setWithdrawals] = useState<Array<GenericTransactionViewModel>>([]);
	const [displayedWithdrawals, setDisplayedWithdrawals] = useState<Array<GenericTransactionViewModel>>([]);

	const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const [currentPageSize, setCurrentPageSize] = useState(10);

	const [sortDirectionPerColumn, setSortDirectionPerColumn] = useState<Array<number>>(Array(10).fill(1));

	useEffect(() => {
		const handleWithdrawalRefresh = () => {
			refreshWithdrawals();
		};

		const handleRefreshUser = () => {
			refreshUser();
		};

		eventBus.on("refreshWithdrawals", handleWithdrawalRefresh);
		eventBus.on("refreshUser", handleRefreshUser);

		// Unsubscribe when the component unmounts
		return () => {
			eventBus.off("refreshWithdrawals", handleWithdrawalRefresh);
			eventBus.off("refreshUser", handleRefreshUser);
		};
	}, []);

	useEffect(() => {
		refreshUser();

		refreshWithdrawals();
	}, []); // Empty dependency array means the effect runs only once

	const refreshUser = () => {
		const user = userService.getUser();
		if (user) {
			setHasTierTwo(userServiceUtils.hasTier(user?.authorities, "ROLE_USER_TIER_2"));
		}
	};

	const refreshWithdrawals = () => {
		const txWithdrawals = userService.getWithdrawals();
		setWithdrawals(txWithdrawals);
		setDisplayedWithdrawals(txWithdrawals);
	};

	const copyToClipboard = (text: string) => {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("copy");
		document.body.removeChild(textArea);
	};

	const pageDisplayedWithdrawals = () => {
		const startIndex = (currentPageNumber - 1) * currentPageSize;

		const slicedWithdrawals = withdrawals.slice(startIndex, startIndex + currentPageSize);

		setDisplayedWithdrawals(slicedWithdrawals);
	};

	useEffect(() => {
		pageDisplayedWithdrawals();
	}, [currentPageNumber, currentPageSize, withdrawals]);

	const handleSearchBoxTextChange = (event: any) => {
		setSearchBoxText(event.target.value);
	};

	const handlePageSizeChange = (event: any) => {
		setCurrentPageNumber(1);
		setCurrentPageSize(parseInt(event.target.value, 10));
	};

	useEffect(() => {
		if (searchBoxText.length < 3) {
			setDisplayedWithdrawals([...withdrawals]);
			pageDisplayedWithdrawals();
			return;
		}

		// Filter withdrwals based on searchBoxText
		const filteredWithdrawals = withdrawals.filter((withdrawal) => {
			const formattedCreated = formatDateForTable(withdrawal.created).toLowerCase();

			// Check if any property contains the searchBoxText (case-insensitive)
			return Object.values(withdrawal).some((value) => {
				if (typeof value === "number" && value.toString().toLowerCase().includes(searchBoxText.toLowerCase())) {
					return true;
				} else if (typeof value === "string" && value.toLowerCase().includes(searchBoxText.toLowerCase())) {
					return true;
				} else if (value === withdrawal.created && formattedCreated.includes(searchBoxText.toLowerCase())) {
					return true;
				}
				return false;
			});
		});

		setDisplayedWithdrawals(filteredWithdrawals);
	}, [searchBoxText]);

	const handleSortTable = (column: number) => {
		const sortedWithdrawals = [...withdrawals];

		sortDirectionPerColumn[column] = sortDirectionPerColumn[column] === 1 ? -1 : 1;

		switch (column) {
			case 0:
				sortedWithdrawals.sort((a, b) => (a.id - b.id) * sortDirectionPerColumn[column]);
				break;
			case 1:
				sortedWithdrawals.sort((a, b) => (a.created - b.created) * sortDirectionPerColumn[column]);
				break;
			case 2:
				sortedWithdrawals.sort(
					(a, b) => a.resultingCurrency.localeCompare(b.resultingCurrency) * sortDirectionPerColumn[column]
				);
				break;
			case 3:
				sortedWithdrawals.sort((a, b) => a.fromWallet.localeCompare(b.fromWallet) * sortDirectionPerColumn[column]);
				break;
			case 4:
				sortedWithdrawals.sort((a, b) => a.toWallet.localeCompare(b.toWallet) * sortDirectionPerColumn[column]);
				break;
			case 5:
				sortedWithdrawals.sort((a, b) => (a.initialAmount - b.initialAmount) * sortDirectionPerColumn[column]);
				break;
			case 6:
				sortedWithdrawals.sort((a, b) => (a.resultingAmount - b.resultingAmount) * sortDirectionPerColumn[column]);
				break;
			case 7:
				sortedWithdrawals.sort(
					(a, b) => ((a.exchangeRate || 0) - (b.exchangeRate || 0)) * sortDirectionPerColumn[column]
				);
				break;
			case 8:
				sortedWithdrawals.sort((a, b) => a.status.localeCompare(b.status) * sortDirectionPerColumn[column]);
				break;
			case 9:
				sortedWithdrawals.sort(
					(a, b) => (a.txHash || "").localeCompare(b.txHash || "") * sortDirectionPerColumn[column]
				);
				break;
			default:
				// Handle the case when an invalid column is provided
				break;
		}

		setWithdrawals(sortedWithdrawals);
	};

	const generateCsvFromWithdrawals = () => {
		const csvRows = [];

		// Add header row
		const headerRow = [
			"ID",
			"Date",
			"Asset",
			"From",
			"To",
			"Initial Amount",
			"Resulting Amount (Fee)",
			"Exchange Rate",
			"Status",
			"Info (TxHash)",
		];
		csvRows.push(headerRow.join(","));

		// Add data rows
		withdrawals.forEach((row) => {
			const dataRow = [
				row.id,
				formatDateForTable(row.created).replace(/,/g, "."),
				row.resultingCurrency,
				row.fromWallet,
				row.toWallet,
				row.category === "FIAT"
					? row.initialCurrency +
						" " +
						formatNumber(row.initialAmount, 8, true).replace(/,/g, ".") +
						(row.initialTotalAmountFee !== 0
							? " (" + formatNumber(row.initialTotalAmountFee, 8, true).replace(/,/g, ".") + ")"
							: "")
					: row.resultingCurrency + " " + formatNumber(row.initialAmount, 8, true).replace(/,/g, "."),
				row.category === "FIAT"
					? row.resultingCurrency + " " + formatNumber(row.resultingAmount, 8, true).replace(/,/g, ".")
					: row.resultingCurrency +
						" " +
						formatNumber(row.resultingAmount, 8, true).replace(/,/g, ".") +
						" (" +
						formatNumber(row.resultingTotalAmountFee, 8, true).replace(/,/g, ".") +
						")",
				row.category === "FIAT"
					? row.initialCurrency +
						" 1 = " +
						row.resultingCurrency +
						" " +
						formatNumber(row.exchangeRate, 8, true).replace(/,/g, ".")
					: "-",
				row.status,
				row.txHash || "-",
			];
			csvRows.push(dataRow.join(","));
		});

		return csvRows.join("\n");
	};

	const downloadWithdrawalsCsv = () => {
		const csvData = generateCsvFromWithdrawals();

		// Create a CSV blob
		const blob = new Blob([csvData], { type: "text/csv" });
		const csvUrl = URL.createObjectURL(blob);

		// Create a download link and trigger the click event to download the CSV file
		const a = document.createElement("a");
		a.href = csvUrl;
		a.download = `vtrader_withdrawals_history_report_${new Date()
			.toISOString()
			.replace(/[^a-zA-Z0-9]/g, "_")
			.slice(0, -5)}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(csvUrl);
	};

	const downloadWithdrawalsPdf = () => {
		const data = generateCsvFromWithdrawals()
			.split("\n")
			.map((row) => row.split(","));

		const styles = StyleSheet.create({
			cell: {
				fontSize: 10,
				margin: 2,
				padding: 2,
			},
		});

		const headerRow = [
			"ID",
			"Date",
			"Asset",
			"From",
			"To",
			"Initial Amount",
			"Resulting Amount (Fee)",
			"Exchange Rate",
			"Status",
			"Info (TxHash)",
		];

		const pdfData = (
			<Document>
				<Page size='A4'>
					<View>
						{data.slice(1).map((row, rowIndex) => (
							<View key={rowIndex} style={{ flexDirection: "column", marginBottom: 10 }}>
								{row.map((cell, cellIndex) => (
									<Text key={cellIndex} style={styles.cell}>
										{headerRow[cellIndex]} : {cell}
									</Text>
								))}
							</View>
						))}
					</View>
				</Page>
			</Document>
		);

		// pdf(pdfData)
		//     .toBlob()
		//     .then((blob) => {
		//         const filename = `vtrader_withdrawals_history_report_${new Date().toISOString().replace(/[^a-zA-Z0-9]/g, '_').slice(0, -5)}.pdf`;
		//         saveAs(blob, filename);
		//     });
	};

	const getInitialAmount = (row: GenericTransactionViewModel): string => {
		if (row.category === "FIAT") {
			return (
				getCurrencySymbol(row.initialCurrency || "") +
				formatNumber(row.initialAmount, 8, true) +
				(row.initialTotalAmountFee !== 0 ? " (" + formatNumber(row.initialTotalAmountFee, 8, true) + ")" : "")
			);
		}

		return getCurrencySymbol(row.initialCurrency || "") + formatNumber(row.initialAmount, 8, true);
	};

	const getResultingAmount = (row: GenericTransactionViewModel): string => {
		if (row.category === "FIAT" && row.status === "SUCCESS") {
			return (
				getCurrencySymbol(row.resultingCurrency) +
				formatNumber(row.resultingAmount, 8, true) +
				" (" +
				formatNumber(row.resultingTotalAmountFee || 0, 8, true) +
				")"
			);
		}

		// crypto
		if (row.status === "SUCCESS") {
			return (
				getCurrencySymbol(row.initialCurrency || "") +
				formatNumber(row.resultingAmount, 8, true) +
				" (" +
				formatNumber(row.initialTotalAmountFee || 0, 8, true) +
				")"
			);
		}

		return "-";
	};

	const getExchangeRate = (row: GenericTransactionViewModel): string => {
		if (row.category === "FIAT" && row.status === "SUCCESS") {
			return (
				getCurrencySymbol(row.initialCurrency || "") +
				"1 = " +
				getCurrencySymbol(row.resultingCurrency) +
				formatNumber(row.exchangeRate, 8, true)
			);
		}

		return "-";
	};

	return (
		<div>
			<div className='center-container'>
				<div className='menu'>
					<Link to='/dashboard' className='link-custom'>
						<p>DASHBOARD</p>
					</Link>
					<Link to='/orders' className='link-custom'>
						<p>ORDERS</p>
					</Link>
					<Link to='/trade' className='link-custom'>
						<p>TRADE</p>
					</Link>
					<Link to='/deposit' className='link-custom'>
						<p>ADD FUNDS</p>
					</Link>
					<Link to='/withdraw' className='link-custom'>
						<p className='selected'>WITHDRAW FUNDS</p>
					</Link>
				</div>
			</div>

			<h1 className='title'>Withdrawal History</h1>
			{hasTierTwo && (
				<div>
					<div className='dashboard-container'>
						<div className='deposit-titles'>
							<Link to='/withdraw' className='deposit-inactive-tab'>
								<p>Issue Withdrawal</p>
							</Link>
							<Link to='/withdraw-history' className='deposit-active-tab'>
								<p>Withdrawal History</p>
							</Link>
						</div>
						<div
							className='history-table-header-container'
							style={{
								justifyContent: displayedWithdrawals.length > 0 ? "space-between" : "center",
							}}
						>
							{(displayedWithdrawals.length > 0 || searchBoxText.trim().length > 0) && (
								<input
									type='text'
									placeholder='SEARCH'
									style={{ backgroundColor: "white", width: "380px", marginRight: "10px" }}
									value={searchBoxText}
									onChange={handleSearchBoxTextChange}
								/>
							)}
							{displayedWithdrawals.length > 0 && (
								<div className='history-table-header-export-buttons-container'>
									<p
										className='history-table-header-text'
										onClick={() => {
											downloadWithdrawalsPdf();
										}}
									>
										EXPORT PDF
									</p>
									<p
										className='history-table-header-text'
										onClick={() => {
											downloadWithdrawalsCsv();
										}}
									>
										EXPORT CSV
									</p>
									<p
										className='history-table-header-text'
										onClick={() => {
											copyToClipboard(generateCsvFromWithdrawals());
											showSuccessToast(toast, "Withdrawal history copied to clipboard");
										}}
									>
										COPY TO CLIPBOARD
									</p>
								</div>
							)}
						</div>
					</div>

					{displayedWithdrawals.length > 0 && (
						<div>
							<div className='history-table-container'>
								<table style={{ borderCollapse: "collapse", width: "1500px" }}>
									<thead>
										<tr className='history-table-row'>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>ID</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(0);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>Date</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(1);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>Asset</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(2);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>From</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(3);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>To</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(4);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>Initial Amount</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(5);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>Resulting Amount</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(6);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>Exchange Rate</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(7);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header'>
													<p>Status</p>
													<div className='history-table-sort-icon-container'>
														<img
															src={sortIcon}
															alt='ic'
															className='history-table-sort-icon'
															onClick={() => {
																handleSortTable(8);
															}}
														/>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
											<th className='history-table-thead-text'>
												<div className='history-table-text-and-icon-header' style={{ paddingLeft: "40px" }}>
													<p>Info</p>
													<div className='history-table-sort-icon-container'>
														<div className='history-pagination-icon-container'>
															<img
																src={sortIcon}
																alt='ic'
																className='history-table-sort-icon'
																onClick={() => {
																	handleSortTable(9);
																}}
															/>
														</div>
													</div>
												</div>
												<div className='history-table-thead-hidden-section'></div>
											</th>
										</tr>
									</thead>
									<tbody>
										{displayedWithdrawals.map((row, index) => (
											<tr key={index} className='history-table-row'>
												<td className='history-table-cell' style={{ width: "3%" }}>
													{"#" + row.id}
												</td>
												<td className='history-table-cell' style={{ width: "14%" }}>
													{formatDateForTable(row.created)}
												</td>
												<td className='history-table-cell' style={{ width: "5%" }}>
													{row.category === "FIAT" ? row.resultingCurrency : row.initialCurrency}
												</td>
												<td className='history-table-cell' style={{ width: "10%" }}>
													{row.initialCurrency + " Wallet"}
												</td>
												<td className='history-table-cell' style={{ width: "24%" }}>
													{row.toWallet}
												</td>
												<td className='history-table-cell' style={{ width: "11%" }}>
													{getInitialAmount(row)}
												</td>
												<td className='history-table-cell' style={{ width: "12%" }}>
													{getResultingAmount(row)}
												</td>
												<td className='history-table-cell' style={{ width: "12%" }}>
													{getExchangeRate(row)}
												</td>
												<td className='history-table-cell' style={{ width: "3%", color: getTxStatusColor(row.status) }}>
													{formatTxStatus(row.status)}
												</td>
												<td
													className='history-table-cell'
													style={{
														paddingLeft: "40px",
														width: "6%",
														cursor: row.category === "CRYPTO" && row.status === "SUCCESS" ? "pointer" : "inherit",
													}}
													onClick={() => {
														if (row.category === "CRYPTO") {
															if (row.initialCurrency === "ETH" || row.initialCurrency === "USDT") {
																window.open("https://ethplorer.io/tx/" + row.txHash, "_blank");
															} else if (row.initialCurrency === "BTC") {
																window.open("https://blockchain.info/en/tx/" + row.txHash, "_blank");
															}
														}
													}}
												>
													{row.category === "CRYPTO" && row.status === "SUCCESS" ? "TxHash" : "-"}
												</td>
											</tr>
										))}
									</tbody>
								</table>

								{searchBoxText.length < 3 && (
									<div>
										<div className='history-pagination-container'>
											<p
												style={{ color: "black", alignSelf: "center", cursor: "inherit" }}
												className='history-table-header-text'
											>
												Showing{" "}
												{`${(currentPageNumber - 1) * currentPageSize + 1}-${Math.min(
													currentPageNumber * currentPageSize,
													withdrawals.length
												)}`}{" "}
												of {formatNumber(withdrawals.length, 0, false)}
											</p>

											<div className='history-pagination-buttons-container'>
												<button
													className='history-pagination-button'
													disabled={currentPageNumber === 1}
													onClick={() => {
														setCurrentPageNumber(currentPageNumber - 1);
													}}
												>
													<div className='history-pagination-button-icon-text-container'>
														<div className='history-pagination-icon-container' style={{ paddingRight: "7px" }}>
															<img src={leftIcon} className='history-pagination-button-icon' alt='icon' />
														</div>
														<p>Previous</p>
													</div>
												</button>
												<button
													className='history-pagination-button'
													disabled={currentPageNumber * currentPageSize > withdrawals.length}
													onClick={() => {
														setCurrentPageNumber(currentPageNumber + 1);
													}}
												>
													<div className='history-pagination-button-icon-text-container'>
														<p>Next</p>
														<div className='history-pagination-icon-container' style={{ paddingLeft: "5px" }}>
															<img src={rightIcon} className='history-pagination-button-icon' alt='icon' />
														</div>
													</div>
												</button>
											</div>
											<select
												value={currentPageSize}
												onChange={handlePageSizeChange}
												className='history-current-page-size-dropdown'
											>
												{[10, 25, 50, 100].map((pageSize) => (
													<option key={pageSize} value={pageSize}>
														View {pageSize}
													</option>
												))}
											</select>
										</div>
									</div>
								)}
							</div>
						</div>
					)}

					{displayedWithdrawals.length === 0 && searchBoxText.trim().length === 0 && (
						<div>
							<div className='orders-no-orders-to-display-container'>
								<p>Your withdrawal history is currently empty.</p>
							</div>
						</div>
					)}
				</div>
			)}

			{!hasTierTwo && (
				<div>
					<div className='deposit-get-verified-tier-container'>
						<div className='deposit-get-verified-tier-box'>
							<p className='deposit-bank-instruction-text'>To withdraw, you must complete Tier 2 verification.</p>
						</div>
						<div className='deposit-get-verified-tier-box' style={{ marginTop: "10px" }}>
							<button
								className='deposit-get-tier-verified-button'
								onClick={() => {
									history.push("/account?selectedTier=tier2");
								}}
							>
								GET STARTED
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default WithdrawHistory;
