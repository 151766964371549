export const EmployeeStatusOptions = [
	{ key: "EMPLOYEE_STATUS_EMPLOYED", label: "Employed" },
	{ key: "EMPLOYEE_STATUS_FREELANCER", label: "Freelancer/Entrepreneur" },
	{ key: "EMPLOYEE_STATUS_RETIRED", label: "Retired" },
	{ key: "EMPLOYEE_STATUS_UNEMPLOYED", label: "Unemployed" },
	{ key: "EMPLOYEE_STATUS_STUDENT", label: "Student" },
];

export const ActivityFieldOptions = [
	{ key: "ACTIVITY_FIELD_ACCOUNTING", label: "Accounting" },
	{ key: "ACTIVITY_FIELD_LEGAL", label: "Legal" },
	{ key: "ACTIVITY_FIELD_AGRICULTURE", label: "Agriculture" },
	{ key: "ACTIVITY_FIELD_CONSULTING", label: "Consulting/Professional Services" },
	{ key: "ACTIVITY_FIELD_MEDIA", label: "Marketing/Publicity/Media" },
	{ key: "ACTIVITY_FIELD_ARMY", label: "Army/Defense/Aerospace Industry" },
	{ key: "ACTIVITY_FIELD_EDUCATION", label: "Education" },
	{ key: "ACTIVITY_FIELD_ENERGY", label: "Energy/Natural Resources/Mining" },
	{ key: "ACTIVITY_FIELD_ENGINEERING", label: "Engineering" },
	{ key: "ACTIVITY_FIELD_EXPORT_IMPORT", label: "Export/Import" },
	{ key: "ACTIVITY_FIELD_FINANCE", label: "Finance" },
	{ key: "ACTIVITY_FIELD_INSURANCE", label: "Insurance" },
	{ key: "ACTIVITY_FIELD_IT", label: "IT/Technology" },
	{ key: "ACTIVITY_FIELD_TRAVEL", label: "Travel/Tourism" },
	{ key: "ACTIVITY_FIELD_PRODUCTION", label: "Production" },
	{ key: "ACTIVITY_FIELD_CHARITY", label: "Non-Profit/Charity" },
	{ key: "ACTIVITY_FIELD_METALS", label: "Metals/Precious Stones" },
	{ key: "ACTIVITY_FIELD_REAL_ESTATE", label: "Real Estate/Construction" },
	{ key: "ACTIVITY_FIELD_PUBLIC_SECTOR", label: "Public Sector/Social Services/Healthcare" },
	{ key: "ACTIVITY_FIELD_RETAIL", label: "Retail" },
	{ key: "ACTIVITY_FIELD_TELECOMMUNICATIONS", label: "Telecommunications" },
	{ key: "ACTIVITY_FIELD_TRANSPORT", label: "Transport/Logistics" },
	{ key: "ACTIVITY_FIELD_HOUSEHOLD_PRODUCTS", label: "Household Products" },
	{ key: "ACTIVITY_FIELD_AUTOMOTIVE", label: "Automotive" },
	{ key: "ACTIVITY_FIELD_FOOD_AND_DRINKS", label: "Food & Drinks" },
	{ key: "ACTIVITY_FIELD_PHARMACEUTICAL_INDUSTRY", label: "Pharmaceutical industry/Biotechnology" },
	{ key: "ACTIVITY_FIELD_ASSISTANCE_SERVICES", label: "Assistance Services" },
];

export const SourceOfIncomeOptions = [
	{ key: "SOURCE_OF_INCOME_SALARY", label: "Salary" },
	{ key: "SOURCE_OF_INCOME_BUSINESS", label: "Freelancing/Business" },
	{ key: "SOURCE_OF_INCOME_PENSION", label: "Pension" },
	{ key: "SOURCE_OF_INCOME_GOVERNMENTAL_AID", label: "Governmental Aid" },
	{ key: "SOURCE_OF_INCOME_INVESTMENTS", label: "Investments" },
	{ key: "SOURCE_OF_INCOME_INHERITANCE", label: "Inheritance" },
	{ key: "SOURCE_OF_INCOME_PROPERTY", label: "Property Rental or Sale" },
	{ key: "SOURCE_OF_INCOME_BUSINESS_SALE", label: "Sale of Business or Company Shares" },
];

export const FiscalResidenceOptions = [
	{ key: "UE", label: "EU (European Union)" },
	{ key: "non-UE", label: "Non-EU (Outside of the European Union)" },
	{ key: "Romania", label: "Romania" },
];

export const TransactionPurposeOptions = [
	{ key: "Speculative", label: "Speculative" },
	{ key: "Investment", label: "Investment (Long Term Holding)" },
	{ key: "Mix", label: "Speculative and Investment (Mixed)" },
];

export const YesNoOptions = [
	{ key: "Yes", label: true },
	{ key: "No", label: false },
];
