import "../../layout/Dashboard/layout.css";
import "react-toastify/dist/ReactToastify.css";

import React from "react";

const Fees = () => {
	return (
		<div>
			<div className='dashboard-container' style={{ marginBottom: "50px" }}>
				<h1 className='title' style={{ marginBottom: "30px" }}>
					Fees
				</h1>

				<div className='rectangle' style={{ backgroundColor: "#DDD" }}></div>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<p style={{ width: "50%", fontWeight: "bold" }} className='faq-title-section-text'>
						Transaction
					</p>
					<p style={{ width: "50%", textAlign: "left", marginLeft: "0px" }} className='faq-content-section-text'>
						Fee
					</p>
				</div>

				<div className='rectangle' style={{ backgroundColor: "#DDD" }}></div>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<p style={{ width: "50%", fontWeight: "bold" }} className='faq-title-section-text'>
						Fiat Deposit
					</p>
					<p style={{ width: "50%", textAlign: "left", marginLeft: "0px" }} className='faq-content-section-text'>
						1.8% (Reflected in our USDT buy price) + 2.0 € Fixed Fee
					</p>
				</div>

				<div className='rectangle' style={{ backgroundColor: "#DDD" }}></div>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<p style={{ width: "50%", fontWeight: "bold" }} className='faq-title-section-text'>
						Sell Order
					</p>
					<p style={{ width: "50%", textAlign: "left", marginLeft: "0px" }} className='faq-content-section-text'>
						0.85%
					</p>
				</div>

				<div className='rectangle' style={{ backgroundColor: "#DDD" }}></div>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<p style={{ width: "50%", fontWeight: "bold" }} className='faq-title-section-text'>
						Buy Order
					</p>
					<p style={{ width: "50%", textAlign: "left", marginLeft: "0px" }} className='faq-content-section-text'>
						0.85%
					</p>
				</div>

				<div className='rectangle' style={{ backgroundColor: "#DDD" }}></div>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<p style={{ width: "50%", fontWeight: "bold" }} className='faq-title-section-text'>
						Withdrawal (fiat)
					</p>
					<p style={{ width: "50%", textAlign: "left", marginLeft: "0px" }} className='faq-content-section-text'>
						1.8% (Reflected in our USDT sell price) + 2.0 € Fixed Fee
					</p>
				</div>

				<div className='rectangle' style={{ backgroundColor: "#DDD" }}></div>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<p style={{ width: "50%", fontWeight: "bold" }} className='faq-title-section-text'>
						Mobile Transfers
					</p>
					<p style={{ width: "50%", textAlign: "left", marginLeft: "0px" }} className='faq-content-section-text'>
						0%
					</p>
				</div>
			</div>
		</div>
	);
};

export default Fees;
