import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialStateProps = {
	token: string;
	refreshToken: string;
};

const initialState: InitialStateProps = {
	token: "",
	refreshToken: "",
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		updateAuth(state, action: PayloadAction<InitialStateProps>) {
			state = { ...state, ...action.payload };
			localStorage.setItem("accessToken", state.token);
			return state;
		},
	},
});

export const { updateAuth } = authSlice.actions;

export default authSlice.reducer;
