import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

import { coinData } from "src/constants/coin";

import UserService from "../../services/user/UserService";
import { formatNumber } from "../../services/utils/formatNumber";
import closeIcon from "./icons/close.png";

const customStyles = {
	content: {
		width: "fit-content",
		height: "auto",
		margin: "auto",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		padding: "20px",
		border: "1px solid #000", // Thin black border
		boxShadow: "none", // No shadow
	},
};

interface WalletDetailsModalProps {
	closeModal: () => void;
}

Modal.setAppElement("#root");

const WalletDetailsModal: React.FC<WalletDetailsModalProps> = ({ closeModal }) => {
	const [scrollDisabled, setScrollDisabled] = useState(false);
	const [wallets, setWallets] = useState<any>(null);

	const userService = UserService.getInstance();
	const history = useHistory();

	useEffect(() => {
		if (!scrollDisabled) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		const user = userService.getUser();
		if (user) {
			setWallets(user?.wallets);
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [scrollDisabled, wallets]);

	return (
		<div>
			<Modal
				isOpen={true}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel='Wallet Details Modal'
				shouldCloseOnOverlayClick={false}
			>
				<div className='wallet-details-title'>
					<h3>Wallet Details</h3>
					<div style={{ display: "flex", alignItems: "center" }}>
						<img
							src={closeIcon}
							alt='Close'
							className='wallet-details-exit-icon' // Add class for close icon styling
							onClick={() => {
								closeModal();
								setScrollDisabled(true);
							}}
						/>
					</div>
				</div>

				{wallets && (
					<table>
						<tbody>
							{coinData.map((coin, index) => (
								<tr key={`table-row-${index}`}>
									<div className='wallet-details-coin-box'>
										<td style={{ width: "25px" }}>
											<img src={coin.icon} alt={`${coin.name} Icon`} className='wallet-details-icon' />
										</td>
										<td className='wallet-details-text' style={{ width: "40px" }}>
											{coin.name}
										</td>
										<td className='wallet-details-text' style={{ width: "170px", textAlign: "right" }}>
											{formatNumber(wallets[coin.name]?.balance, 8) || 0.0}
										</td>
										<td style={{ paddingLeft: "20px", paddingBottom: "5px" }}>
											<button
												className='button-with-text-only'
												onClick={() => {
													history.push(`/withdraw?selectedCoin=${coin.name}`);
												}}
												style={{ marginRight: "20px" }}
											>
												&minus;
											</button>
											<button
												className='button-with-text-only'
												onClick={() => {
													history.push(`/deposit?selectedCoin=${coin.name}`);
												}}
											>
												+
											</button>
										</td>
									</div>
									<div
										className='rectangle'
										style={{ backgroundColor: "#DDD", marginBottom: "15px", marginTop: "15px" }}
									></div>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</Modal>
		</div>
	);
};

export default WalletDetailsModal;
