import "./index.css";

import Hotjar from "@hotjar/browser";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";

import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import AppRouter from "./Router"; // Import the Router component
import { AuthProvider } from "./services/auth/AuthContext";
import { WebSocketProvider } from "./websocket/WebsocketContext";

const hotjarSiteId = 3719567;
const hotjarVersion = 6;

Hotjar.init(hotjarSiteId, hotjarVersion);

const tagManagerArgs = {
	gtmId: "GTM-5L3NC6P9",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<>
		<Provider store={store}>
			<AuthProvider>
				<WebSocketProvider>
					<AppRouter />
				</WebSocketProvider>
			</AuthProvider>
		</Provider>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
