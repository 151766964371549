import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { coinData } from "src/constants/coin";

import DashboardLayout from "../../layout/Dashboard/Layout";
import FeesTickersService from "../../services/api/common/fees-tickers-service/FeesTickersService";
import JwtUser from "../../services/api/common/jwtUser/JwtUser";
import Bank from "../../services/api/common/response/Bank";
import { Fees } from "../../services/api/common/response/Fees";
import NgnUsdtRate from "../../services/api/common/response/NgnUsdtRate";
import BittrexMarketSummaryService from "../../services/bittrex-market-summary/BittrexMarketSummaryService";
import UserService from "../../services/user/UserService";
import UserServiceUtils from "../../services/user/UserServiceUtils";
import { getCurrencySymbol } from "../../services/utils/cryptoSymbols";
import { formatNumber } from "../../services/utils/formatNumber";
import { getLongNameForCrypto } from "../../services/utils/longNameCrypto";
import { showSuccessToast } from "../../services/utils/toastUtils";
import copyIcon from "./icons/copy.png";

const Deposit = () => {
	const depositType = ["Bank", "Blockchain"];

	const fiatCoins = ["EUR", "NGN"];

	const blockchainDepositCoins = ["USDT", "ETH", "BTC"];

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const coinSelectedParam = queryParams.get("selectedCoin") || "USDT";

	const [coinSelected, setCoinSelected] = useState(coinSelectedParam);
	const [depositTypeSelected, setDepositTypeSelected] = useState("Bank");
	const [fiatCoinSelected, setFiatCoinSelected] = useState("EUR");
	const [coinBuyFiatPrice, setCoinBuyFiatPrice] = useState(0);
	const [coinSellFiatPrice, setCoinSellFiatPrice] = useState(0);
	const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
	const [userReference, setUserReference] = useState<string | null>(null);

	const [user, setUser] = useState<JwtUser | null>(null);
	const [banks, setBanks] = useState<Array<Bank>>([]);
	const [fees, setFees] = useState<Fees | null>(null);

	const [ethDepositAddress, setEthDepositAddress] = useState<string | null>(null);
	const [btcDepositAddress, setBtcDepositAddress] = useState<string | null>(null);
	const [depositAddress, setDepositAddress] = useState<string | null>(null);

	const history = useHistory();
	const feesTickerService: FeesTickersService = FeesTickersService.getInstance();
	const bittrexMarketSummaryService: BittrexMarketSummaryService = BittrexMarketSummaryService.getInstance();
	const userService = UserService.getInstance();
	const userServiceUtils = UserServiceUtils.getInstance();

	const [depositFee, setDepositFee] = useState(0);

	const [hasTierOne, setHasTierOne] = useState(false);

	const [linesOfCoins, setLinesOfCoins] = useState<any>([]);
	const screenWidth = window.outerWidth;

	const handleDepositTypeSelect = (event: any) => {
		const newDepositType = event.target.value;
		setDepositTypeSelected(newDepositType);
	};

	const handleFiatCoinSelect = (event: any) => {
		const newFiatCoin = event.target.value;
		setFiatCoinSelected(newFiatCoin);
	};

	useEffect(() => {
		const user = userService.getUser();
		if (user) {
			setHasTierOne(userServiceUtils.hasTier(user?.authorities, "ROLE_USER_TIER_1"));
			setUser(user);
			setEthDepositAddress(user.wallets.ETH.publicKey);
			setBtcDepositAddress(user.wallets.BTC.publicKey);
		}
		const _banks = userService.getBanks();
		if (_banks) {
			setBanks(_banks);
		}

		const fees = feesTickerService.getFees();
		if (fees) {
			setFees(fees);
			setDepositFee(fees?.currencies[coinSelected].depositFixed || 0);
		}
	}, []); // Empty dependency array means the effect runs only once

	useEffect(() => {
		// Calculate the number of lines and coins per line based on screen width
		let numLines, coinsPerLine;
		if (screenWidth >= 600) {
			numLines = 3;
			coinsPerLine = Math.ceil(coinData.length / numLines);
		} else {
			numLines = 6;
			coinsPerLine = 4;
		}

		// Generate lines with coins
		const linesArray = [];
		for (let i = 0; i < numLines; i++) {
			const startIdx = i * coinsPerLine;
			const endIdx = startIdx + coinsPerLine;
			const lineCoins = coinData.slice(startIdx, endIdx);

			linesArray.push(
				<div className='trade-coin-container-line' key={i}>
					{lineCoins.map((coin) => (
						<div
							key={coin.name}
							className={`trade-container-coin`}
							style={{
								borderRadius: coinSelected === coin.name ? "5px" : "inherit",
								border: coinSelected === coin.name ? "1px solid black" : "inherit",
							}}
							onClick={() => setCoinSelected(coin.name)}
						>
							<span className='trade-container-coin-text'>{coin.name}</span>
							<img src={coin.icon} alt='ic' className='trade-icon' />
						</div>
					))}
				</div>
			);
		}
		setLinesOfCoins(linesArray);
		setDepositFee(fees?.currencies[coinSelected].depositFixed || 0);
	}, [coinSelected]);

	useEffect(() => {
		const isBlockchainCoin = blockchainDepositCoins.includes(coinSelected);
		if (!isBlockchainCoin) {
			setDepositTypeSelected("Bank");
		}

		let newBuyValue = coinBuyFiatPrice;
		let newSellValue = coinSellFiatPrice;

		if (fiatCoinSelected === "NGN") {
			const ngnUsdtRate: NgnUsdtRate = feesTickerService.getNgnUsdtRate();
			newBuyValue = ngnUsdtRate.buy;
			newSellValue = ngnUsdtRate.sell;
		} else if (fiatCoinSelected === "EUR") {
			const usdtTicker = feesTickerService.getUsdtTicker();
			newBuyValue = (usdtTicker.value * (100 + usdtTicker.buyFee)) / 100;
			newSellValue = (usdtTicker.value * (100 - usdtTicker.sellFee)) / 100;
		}

		if (coinSelected !== "USDT") {
			const bittrexMarketSummary = bittrexMarketSummaryService.getMarketDetailedSummary("USDT-" + coinSelected);
			if (bittrexMarketSummary) {
				const usdtPrice = bittrexMarketSummary.last;

				if (fiatCoinSelected === "EUR") {
					const usdtTicker = feesTickerService.getUsdtTicker();
					const usdtPrice = bittrexMarketSummary.last;
					const eurPrice = usdtPrice * usdtTicker.value;
					newBuyValue = (eurPrice * (100 + usdtTicker.buyFee)) / 100;
					newSellValue = (eurPrice * (100 - usdtTicker.sellFee)) / 100;
				} else {
					newBuyValue = usdtPrice * newBuyValue;
					newSellValue = usdtPrice * newSellValue;
				}
			}
		}

		setCoinBuyFiatPrice(newBuyValue);
		setCoinSellFiatPrice(newSellValue);

		const newBank = banks.find((bank) => bank.currency === fiatCoinSelected);
		if (newBank) {
			setSelectedBank(newBank);
		}

		if (user) {
			if (coinSelected === "USDT") {
				setUserReference(user.publicIdentifier);
			} else {
				setUserReference(user.publicIdentifier + "-" + coinSelected);
			}
		}

		if ((coinSelected === "USDT" || coinSelected === "ETH") && ethDepositAddress) {
			setDepositAddress(ethDepositAddress);
			setDepositFee(fees?.currencies[coinSelected].depositFixed || 0);
		} else if (coinSelected === "BTC" && btcDepositAddress) {
			setDepositAddress(btcDepositAddress);
			setDepositFee(fees?.currencies[coinSelected].depositFixed || 0);
		}
	}, [coinSelected, fiatCoinSelected, user, banks, fees]);

	const copyToClipboard = (text: string) => {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("copy");
		document.body.removeChild(textArea);
	};

	const handleCopyClick = () => {
		if (depositAddress) {
			copyToClipboard(depositAddress);
			showSuccessToast(toast, "Address copied to clipboard");
		}
	};

	return (
		<DashboardLayout>
			<h1 className='title'>Add Funds</h1>
			{hasTierOne && (
				<div>
					<div className='dashboard-container'>
						<div className='deposit-titles'>
							<Link to='/deposit' className='deposit-active-tab'>
								<p>Issue Order</p>
							</Link>
							<Link to='/deposit-history' className='deposit-inactive-tab'>
								<p>Add Funds History</p>
							</Link>
						</div>

						{/*<div className="trade-coin-container">*/}
						{/*    {linesOfCoins}*/}
						{/*</div>*/}

						<h3 style={{ textAlign: "center" }}>USDT Deposit</h3>

						<div
							className='rectangle'
							style={{ backgroundColor: "#DDD", marginBottom: "10px", marginTop: "40px" }}
						></div>

						<div style={{ display: "flex", justifyContent: "center" }}>
							<p className='deposit-select-type'>Select deposit type</p>
						</div>

						<div style={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
							{blockchainDepositCoins.includes(coinSelected) ? (
								<select value={depositTypeSelected} onChange={handleDepositTypeSelect} className='deposit-dropdown'>
									{depositType.map((type) => (
										<option key={type} value={type}>
											{type}
										</option>
									))}
								</select>
							) : (
								<select value='Bank' disabled className='deposit-dropdown'>
									<option value='Bank'>Bank</option>
								</select>
							)}
						</div>

						{depositTypeSelected === "Bank" && (
							<div>
								<div className='deposit-bank-currency-sell-buy'>
									<div className='deposit-bank-currency-sell-buy-box'>
										<p className='deposit-select-type'>View Currency</p>
										<select
											value={fiatCoinSelected}
											onChange={handleFiatCoinSelect}
											className='deposit-dropdown'
											style={{ marginTop: "-10px" }}
										>
											{fiatCoins.map((type) => (
												<option key={type} value={type}>
													{type}
												</option>
											))}
										</select>
									</div>
									<div className='deposit-bank-currency-sell-buy-box'>
										<p className='deposit-select-type'>Sell Price</p>
										<p
											style={{
												textAlign: "center",
												marginTop: "-10px",
											}}
										>
											{getCurrencySymbol(fiatCoinSelected)} {formatNumber(coinSellFiatPrice)}
										</p>
									</div>
									<div className='deposit-bank-currency-sell-buy-box'>
										<p className='deposit-select-type'>Buy Price</p>
										<p
											style={{
												textAlign: "center",
												marginTop: "-10px",
											}}
										>
											{getCurrencySymbol(fiatCoinSelected)} {formatNumber(coinBuyFiatPrice)}
										</p>
									</div>
								</div>

								<div
									className='rectangle'
									style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "20px" }}
								></div>

								<p className='deposit-bank-instruction-text'>
									1. Add funds to your account using the information below. You&apos;ll need to contact your bank for
									specific instructions.
								</p>
								<p className='deposit-bank-instruction-text'>
									2. Be sure to include the &quot;Reference&quot; code given below - this code identifies the order with
									your account. If you fail to include the reference code we may ask you to get verified and submit an
									ID confirmation photo.
								</p>
								<p className='deposit-bank-instruction-text'>
									3. Orders may take a few days to be processed. If you encounter any problem with your order, write an
									e-mail to our support team (support@vtrader.com)
								</p>
								<p className='deposit-bank-instruction-text'>
									4. Important: The name on the bank account you are transferring funds from must match the name of the
									account you are adding funds into.
								</p>
								<p className='deposit-bank-instruction-text'>
									5. Important: Please don&apos;t forget to include the complete reference code
								</p>

								<div
									className='rectangle'
									style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "20px" }}
								></div>

								<div className='deposit-bank-details-container'>
									<div className='deposit-bank-details-column'>
										<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
											Reference #
										</p>
										<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
											Account Name
										</p>
										<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
											IBAN
										</p>
										<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
											Bank Name
										</p>
										<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
											BIC
										</p>
										<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
											Bank Address
										</p>
										<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
											Fixed fee
										</p>
										<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
											Minimum Order
										</p>
									</div>
									<div className='deposit-bank-details-column'>
										{userReference && (
											<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
												{userReference}
											</p>
										)}
										{selectedBank && (
											<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
												{selectedBank.receiver}
											</p>
										)}
										{selectedBank && (
											<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
												{selectedBank.account}
											</p>
										)}
										{selectedBank && (
											<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
												{selectedBank.name}
											</p>
										)}
										{selectedBank && (
											<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
												{selectedBank.swiftCode}
											</p>
										)}
										{selectedBank && (
											<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
												{selectedBank.address}
											</p>
										)}
										{fees && (
											<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
												{getCurrencySymbol(fiatCoinSelected) +
													" " +
													fees.currencies.USDT.depositFiat?.[fiatCoinSelected]}
											</p>
										)}
										{selectedBank && fees && (
											<p className='deposit-bank-instruction-text' style={{ lineHeight: "20px" }}>
												{getCurrencySymbol(fiatCoinSelected) +
													" " +
													Number(fees.currencies.USDT.depositFiat?.[fiatCoinSelected]) * 2}
											</p>
										)}
									</div>
								</div>
							</div>
						)}

						{depositTypeSelected === "Blockchain" && (
							<div>
								<div
									className='rectangle'
									style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "20px" }}
								></div>

								<p className='deposit-bank-instruction-text' style={{ fontWeight: "bold", lineHeight: "30px" }}>
									Instructions
								</p>
								<p className='deposit-bank-instruction-text'>
									1. Add {getLongNameForCrypto(coinSelected)} to your account by sending them to the addresses shown
									below.
								</p>
								<p className='deposit-bank-instruction-text'>
									2. For accuracy and convenience, use the available button to copy the address.
								</p>
								<p className='deposit-bank-instruction-text'>
									3. Important: Only send {getLongNameForCrypto(coinSelected)} ({coinSelected}) to this address. Sending
									any other currency my result in a loss of funds.
								</p>
								{fees && (
									<p className='deposit-bank-instruction-text'>
										4. Deposit Fixed Fee: {getCurrencySymbol(coinSelected)} {depositFee}. Added amounts must be greater
										than the deposit fee.
									</p>
								)}
								<p className='deposit-bank-instruction-text'>
									5. We charge a deposit fee so we can move your funds in an isolated wallet to safeguard from any kind
									of malicious attack.
								</p>
								{(coinSelected === "USDT" || coinSelected === "ETH") && (
									<p className='deposit-bank-instruction-text'>
										6. Please use only the Ethereum (ERC20) network to deposit funds. Using any other network may result
										in the loss of your funds.
									</p>
								)}
								<div
									className='rectangle'
									style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "40px" }}
								></div>

								{depositAddress && (
									<div className='deposit-crypto-public-key-container'>
										<button className='deposit-copy-to-clipboard-button' onClick={handleCopyClick}>
											<span className='copy-icon' style={{ backgroundImage: `url(${copyIcon})` }}></span>
											COPY TO CLIPBOARD
										</button>
										<img
											style={{ width: "150px" }}
											src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${depositAddress}`}
											alt='address'
										/>
										<p className='deposit-address-text'>{depositAddress}</p>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			)}

			{!hasTierOne && (
				<div>
					<div className='deposit-get-verified-tier-container'>
						<div className='deposit-get-verified-tier-box'>
							<p className='deposit-bank-instruction-text'>To add funds, you must complete Tier 1 verification.</p>
						</div>
						<div className='deposit-get-verified-tier-box' style={{ marginTop: "10px" }}>
							<button
								className='deposit-get-tier-verified-button'
								onClick={() => {
									history.push("/account?selectedTier=tier1");
								}}
							>
								GET STARTED
							</button>
						</div>
					</div>
				</div>
			)}
		</DashboardLayout>
	);
};

export default Deposit;
