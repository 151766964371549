import aaveIcon from "src/assets/coin-icons/icon_aave.png";
import adaIcon from "src/assets/coin-icons/icon_ada.png";
import algoIcon from "src/assets/coin-icons/icon_algo.png";
import apeIcon from "src/assets/coin-icons/icon_ape.png";
import atomIcon from "src/assets/coin-icons/icon_atom.png";
import avaxIcon from "src/assets/coin-icons/icon_avax.png";
import btcIcon from "src/assets/coin-icons/icon_btc.png";
import compIcon from "src/assets/coin-icons/icon_comp.png";
import daiIcon from "src/assets/coin-icons/icon_dai.png";
import dogeIcon from "src/assets/coin-icons/icon_doge.png";
import dotIcon from "src/assets/coin-icons/icon_dot.png";
import ethIcon from "src/assets/coin-icons/icon_eth.png";
import grtIcon from "src/assets/coin-icons/icon_grt.png";
import linkIcon from "src/assets/coin-icons/icon_link.png";
import ltcIcon from "src/assets/coin-icons/icon_ltc.png";
import maticIcon from "src/assets/coin-icons/icon_matic.png";
import mkrIcon from "src/assets/coin-icons/icon_mkr.png";
import solIcon from "src/assets/coin-icons/icon_sol.png";
import umaIcon from "src/assets/coin-icons/icon_uma.png";
import uniIcon from "src/assets/coin-icons/icon_uni.png";
import usdcIcon from "src/assets/coin-icons/icon_usdc.png";
import xlmIcon from "src/assets/coin-icons/icon_xlm.png";
import xrpIcon from "src/assets/coin-icons/icon_xrp.png";

export const coinData = [
	{ name: "AAVE", icon: aaveIcon },
	{ name: "ADA", icon: adaIcon },
	{ name: "APE", icon: apeIcon },
	{ name: "AVAX", icon: avaxIcon },
	{ name: "ALGO", icon: algoIcon },
	{ name: "ATOM", icon: atomIcon },
	{ name: "BTC", icon: btcIcon },
	{ name: "COMP", icon: compIcon },
	{ name: "DAI", icon: daiIcon },
	{ name: "DOGE", icon: dogeIcon },
	{ name: "DOT", icon: dotIcon },
	{ name: "ETH", icon: ethIcon },
	{ name: "GRT", icon: grtIcon },
	{ name: "LINK", icon: linkIcon },
	{ name: "LTC", icon: ltcIcon },
	{ name: "MATIC", icon: maticIcon },
	{ name: "MKR", icon: mkrIcon },
	{ name: "SOL", icon: solIcon },
	{ name: "UMA", icon: umaIcon },
	{ name: "UNI", icon: uniIcon },
	{ name: "USDC", icon: usdcIcon },
	{ name: "XLM", icon: xlmIcon },
	{ name: "XRP", icon: xrpIcon },
];
