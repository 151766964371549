import "../../layout/Dashboard/layout.css";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

const TermsAndConditions = () => {
	return (
		<div>
			<h1 className='title'>Terms & Conditions</h1>
			<div className='dashboard-container' style={{ marginBottom: "50px" }}>
				<div className='deposit-titles' style={{ gap: "25px" }}>
					<Link to='/terms-and-conditions' className='deposit-active-tab'>
						<p>Terms & Conditions</p>
					</Link>
					<Link to='/privacy-policy' className='deposit-inactive-tab'>
						<p>Privacy Policy</p>
					</Link>
					<Link to='/cookie-policy' className='deposit-inactive-tab'>
						<p>Cookie Policy</p>
					</Link>
					<Link to='/aml-policy' className='deposit-inactive-tab'>
						<p>AML Policy</p>
					</Link>
					<Link to='/refund-policy' className='deposit-inactive-tab'>
						<p>Refund Policy</p>
					</Link>
				</div>
				<p className='terms-text' style={{ marginTop: "50px" }}>
					The website hosted by the &quot;vTrader.io&quot; domain (&quot;vTrader website&quot;) is property of the
					company VTrader Online Services S.R.L. (&quot;vTrader&quot;), with its registered office situated in Romania,
					Bucharest, 6th district, str. Prelungirea Ghencea nr. 279, etaj 5, registered with the Trade registry under
					no. J40/8628/18.06.2018, having the VAT code 39501244, phone: +40741155647, electronic mail address:
					office@vTrader.io.
				</p>
				<p className='terms-text'>
					By accessing the vTrader website, including, but not limited to accessing the information from the Website,
					creating an account on the vTrader website, as well as by using any of the services supplied by this website
					(&quot;vTrader Services&quot;), you hereby declare and confirm that you have read, understood and that you
					fully accept, without reserve, the terms and conditions set forth, which stand for an agreement concluded
					between vTrader and you (&quot;the Terms and Conditions&quot; or &quot;the Agreement&quot;), as well as the{" "}
					<Link to='/privacy-policy' style={{ color: "#111" }}>
						Confidentiality Policy
					</Link>{" "}
					and the Policy related to{" "}
					<Link to='/cookie-policy' style={{ color: "#111" }}>
						Cookies
					</Link>{" "}
					which are integral part from the hereby Terms and conditions.
				</p>
				<p className='terms-text'>
					If you do not agree with the hereby Terms and Conditions, whether your disagreement regards the entire or only
					part of the Terms and Conditions, you are bound to immediately stop accessing the vTrader website and using
					the vTrader services.
				</p>
				<p className='terms-text'>
					vTrader may unilaterally amend at any time the Terms and Conditions, and such amendments will be deemed
					accepted by You by simply using the vTrader Website / vTrader Services.
				</p>
				<p className='terms-text'>
					The hereby Agreement will be provided and concluded in Romanian, but it can also be provided and concluded in
					English, depending on Your option expressed by selecting the language of utilization of the vTrader Website.
					So as to avoid any doubt, the hereby Agreement is governed by the Romanian law and will be construed in
					accordance with the Romanian legislation. In the case of inconsistencies between the Romanian version and the
					one in English, the one in Romanian is the one that prevails.
				</p>
				<p className='terms-text'>
					We hereby inform you that we will communicate with You, depending on Your option, in Romanian or in English
					regarding all the matters related to the utilization of the vTrader Website and/or vTrader Services, and we
					assure you that our staff is fluently speaking both English and Romanian.
				</p>
				<p className='terms-text'>
					If, after having integrally read the hereby Agreement, you still have questions related to it, please contact
					the vTrader support department (support@vtrader.io)
				</p>
				<p className='terms-title-section'>1. Eligibility</p>
				<p className='terms-text'>
					The vTrader Website and the vTrader Services are intended exclusively to citizens of the European Union and
					who are domiciled on the European Union territory.
				</p>
				<p className='terms-text'>
					Therefore, in order to enter this agreement, you must be a resident of the European Union, to be domiciled in
					the European Union, you must be at least 18 years of age and you must have legal competence.
				</p>
				<p className='terms-text'>
					By accessing the vTrader Website and/or using the vTrader Services, you declare and guarantee that you are
					citizens of the European Union, that you are domiciled in the European Union, you are at least 18 years of age
					and that you have legal competence.
				</p>
				<p className='terms-title-section'>2. Privacy</p>
				<p className='terms-text'>
					In accordance with the provisions of the EU Regulation 679/2016 regarding the protection of natural persons
					and regarding the free circulation of such data and for the abolition of the Directive 95/46/CE (General
					regulation regarding the data protection) we are committed to protecting your personal information and to
					helping you understand how the information you provide is used, by complying with all the principles related
					to the personal data processing, namely: the principle of responsibility, the principle of lawfulness, equity
					and transparency, the principle regarding the purpose of the processing, the principle regarding the reduction
					to a minimum of the necessary data for the processing, the principle regarding the accuracy of the data, the
					principle regarding the limitation of the data storage, the principle of integrality and confidentiality.
				</p>
				<p className='terms-title-section'>3. Risks involved when using cryptocurrencies</p>
				<p className='terms-text'>
					The risks associated to cryptocurrencies, including, but not limited to bitcoin, litecoin, ether and others,
					from the perspective of their purchase, sale, holding, exchange or transaction depend on the evolution of the
					price of such cryptocurrencies on the markets and/or platforms they are being traded on (and which vTrader
					cannot influence) and can capitalize by the loss of a part or even of the entire amount invested in
					cryptocurrencies.
				</p>
				<p className='terms-text'>
					vTrader draws your attention that trading cryptocurrencies requires training and experience in the field of
					cryptocurrencies in order to appropriately understand and manage the risks involved, so that you should
					analyze well whether trading or holding cryptocurrencies is something adequate for you, taking into account
					all the elements mentioned above.
				</p>
				<p className='terms-text'>
					You should also be aware of the fact that the price or the value of the cryptocurrencies can change rapidly,
					it can decrease and even get to zero, when taking into consideration trading or holding cryptocurrencies.
				</p>
				<p className='terms-text'>
					vTrader does not offer consultancy nor does it make recommendations with regard to the trading or holding of
					cryptocurrencies, neither in general, nor in particular, with regard to those which can make the object of the
					vTrader Services.
				</p>
				<p className='terms-text'>
					vTrader informs you that the data, the information, the statistics and the analyses regarding the
					cryptocurrencies or the markets and/or platforms they are traded on, which can be published on or accessed by
					the agency of the vTrader Website, are not customized function to your training or experience level in the
					field of cryptocurrencies, nor function to your financial situation or your investment objectives, and
					certainly they do not represent recommendations of investments in cryptocurrencies, hints or guarantees of the
					future performances of the cryptocurrencies which you intend to hold or trade.
				</p>
				<p className='terms-text'>
					In order to try to minimize the risks you must perform your own analysis or consult consultants which are
					specialized in cryptocurrencies so as to ground your decisions of investing in cryptocurrencies.
				</p>
				<p className='terms-text'>
					For the avoidance of any doubt, in using the vTrader Services, You take all the risks associated with the
					transactions with cryptocurrencies and with holding cryptocurrencies, including, but not limited to, the
					volatility of the prices of the cryptocurrencies, of the turnover and/or of the profits generated by
					holding/trading the cryptocurrencies, the fluctuation of the rate exchanges afferent to the cryptocurrencies
					and the financial risks mentioned above.
				</p>
				<p className='terms-text'>
					vTrader has advised you with regard to the risks associated to the transactions with cryptocurrencies, so that
					you bind not to hold vTrader liable for any financial or other type of losses which you may incur in relation
					to or as a result of holding or trading the cryptocurrencies by the agency of the vTrader Website, using your
					vTrader Account and/or vTrader Services.
				</p>
				<p className='terms-text'>
					By using the vTrader Services, you expressly declare and claim that you are informed in the field of
					cryptocurrencies, with training and experience in what regards holding and trading cryptocurrencies.
				</p>
				<p className='terms-title-section'>4. vTrader Services</p>
				<p className='terms-text'>
					vTrader will provide, on it&apos;s own but on your behalf, in exchange of commissions and fees in accordance
					with the provisions of Article 9 below, the following types of services and activities:
				</p>
				<p className='terms-text'>
					a) The Convert Service, which consists in taking over and transmitting the buy / sell orders of
					criptocurrencies through the vTrader site (the &quot;Convert Service&quot;).
				</p>
				<p className='terms-text'>
					b) Exchange Service, which is a cryptocurrency exchange facility via vTrader (&quot;Exchange Service&quot;),
					according to the orders sent by you.
				</p>
				<p className='terms-text'>
					c) The Trading Service on the Network is a service of transfer of the cryptocurrencies to external wallets
					from the network specific to each cryptocurrency to the vtrader Account and viceversa (&quot;Trading Service
					on the Network&quot;), according to orders sent by you.
				</p>
				<p className='terms-text'>
					To access the above services, you need to register on the vTrader site by creating a user account (the
					&quot;vTrader account&quot;).
				</p>
				<p className='terms-text'>
					The Online vTrader Account, which is a hosted account that allows users to track and manage the information on
					trading their cryptomones and through which vTrader can facilitate transactions in accordance with orders and
					instructions submitted by a user or an entity authorized by vTrader (&quot;The Online Account vTrader&quot;).
					In this account, vTrader will highlight all the holdings of your criptocurrencies and fiduciary coins, as well
					as the transactions made by the user.
				</p>
				<p className='terms-text'>
					The user declares that he understands and agrees that, in order to gain access to the vTrader Services, he
					undertakes to feed the vTrader Online Account with fiduciary or cryptocurrency, which remain the exclusive
					property of the User but are in the vTrader administration. For this purpose, the User empowers vTrade as an
					administrator in the meaning of Art. 795 Romanian Civil Code, To carry out all necessary documents for the
					storage of criptocurrency and fiduciary coins, as well as all the acts that are useful for them to be used as
					intended by the user.
				</p>
				<p className='terms-text'>
					In order to eliminate any ambiguity in connection with vTrader Services, vTrader will conclude with Third
					Parties legal acts in its own name but on account of the User, the effects of these acts being produced in the
					User&apos;s patrimony without, however, giving rise to direct legal relationships between You and the Third
					Parties, only between the latter and vTrader.
				</p>
				<p className='terms-text'>
					The vTrader Online Account, the Convert Service, the Exchange Service, and the Trading Service on the Network
					are collectively referred to in these Terms and Conditions as &quot;vTrader Services&quot; and are detailed
					below.
				</p>
				<p className='terms-text'>
					VTrader services can only be used for lawful purposes and you warrant and agree not to use these services in
					an unlawful or abusive manner.
				</p>
				<p className='terms-title-section'>5. vTrader Online Account</p>
				<p className='terms-title-section'>5.1. Registration</p>
				<p className='terms-text'>
					You may open only one vTrader Online Account. If you attempt to open more than one account, any additional
					account(s) may be suspended and/or terminated at our discretion. In order to use any of the vTrader Services,
					you must first register by providing: a valid email address, a password, your phone number and your country.
					The registration process involves compulsorily the declaration of your country of residence, as belonging to
					the European Union and the confirmation of the e-mail address you have provided. Upon the successful
					completion of the registration process, vTrader will establish your vTrader Account, which you can access by
					using the user and the password chosen in the registration process.
				</p>
				<p className='terms-text'>
					If vTrader has any suspicions with regard to the fact that you fail to comply with the eligibility criteria
					from chapter 1 above or any other conditions from the hereby Terms and Conditions, or if it considers that you
					have provided insufficient information or misleading or untrue information, vTrader reserves the right to
					refuse the establishment / activation / access to the Online vTrader Account and to the vTrader Services.
				</p>
				<p className='terms-text'>
					Also, after the registration of the vTrader Account, or from time to time, we may also require you to provide
					further information as a condition for continued use of the Services.
				</p>
				<p className='terms-text'>
					If you wish to convert fiat currency to cryptocurrencies, or cryptocurrencies to fiat currency you authorise
					vTrader, directly or through third parties, to make any inquiries we deem necessary to validate your identity.
					This may include requesting further information about you such as your date of birth, email address, physical
					address, personal identification number, bank account information (e.g., financial institution, account type,
					SWIFT account and account number) so that we can confirm your identity and provide vTrader Services. vTrader
					may also require you to answer certain questions or take actions in order to verify your identity, and be able
					to provide the vTrader Services to you, or to comply with applicable law. By submitting this or any other
					personal information as may be required, please make sure first that the information is accurate and
					authentic, and you agree to inform vTrader if any information changes. We reserve the right to maintain your
					account registration information after you close your account for business and regulatory compliance purposes,
					including those related to fiscal matters, for the prevention and fighting of money laundering, and by
					complying with the legal norms regarding the personal data processing.
				</p>
				<p className='terms-text'>
					In order to access your vTrader Account and to use the vTrader Services, you may use a computer or a mobile
					phone connected to the internet by any type of protocol, at the address of the vTrader Website (vTrader.io) or
					any other back-up address communicated by vTrader, under the protection of the user and of the password you
					have chosen on the occasion of the account registration.
				</p>
				<p className='terms-text'>
					You hold the entire responsibility with regard to the operations and transactions you are performing in your
					vTrader Account. For the avoidance of any doubt, vTrader will not be held liable by You or by any other person
					for any damage suffered by you or by another person as a result of the operations / transactions performed in
					your vTrader Account. With regard to those mentioned above, you declare and bind that you are the sole
					authorized used of the vTrader Account / vTrader Services.
				</p>
				<p className='terms-title-section'>5.2. Password security and keeping your contact information updated</p>
				<p className='terms-text'>
					vTrader informs you about the necessity and you have the obligation to periodically update your password for
					accessing your vTrader account. You have exclusive responsibility in keeping the privacy of the username, of
					the access password and of any other security elements allowing you to log in your vTrader account, to access
					your vTrader account and to use any of the vTrader Services.
				</p>
				<p className='terms-text'>
					Detailing, you are responsible for maintaining adequate security and control of any and all IDs, passwords,
					hints, personal identification numbers (PINs) or any other codes that you use to access the vTrader Services.
					Any loss or compromise of the foregoing information and/or your personal information may result in
					unauthorised access to your vTrader Online Account by third parties. You are responsible for keeping your
					email address up to date in your Account in order to receive any notices or alerts that we may send you.
					vTrader will not be liable for any loss or damage arising from your failure to protect your account
					information, or failure to follow or act on any notices or alerts that we may send to you. In the event you
					believe your vTrader Account information has been compromised, contact vTrader Support immediately.
				</p>
				<p className='terms-text'>
					vTrader shall not bear any liability for any damage or interruptions of the access to your vTrader Account or
					to the vTrader Services caused by any computer viruses, spyware, or other malware that may affect your
					computer or other equipment, or any phishing, spoofing, or other attacks. We advise the regular use of a
					reliable virus and malware screening and prevention software. If you have doubts about the authenticity of a
					communication purporting to be from vTrader, you should login to your account through the vTrader Website
					(vtrader.io), and not by clicking any links contained in emails.
				</p>
				<p className='terms-title-section'>5.3. Account suspension and closure</p>
				<p className='terms-text'>
					vTrader may, in its sole and absolute discretion, without liability to you or any third party, refuse to let
					you open an Account, suspend your vTrader Account, or terminate your vTrader Account or your use of one or
					more of the Services, as a result of any of the following circumstances: inactivity of the vTrader account
					(that is, lack of any transactions or lack of accessing the account for more than 365 days from the date of
					the last transaction/access), failure to respond to customer support requests, failure to positively identify
					you or your violation of the terms of this Agreement, or as required by a valid subpoena or court order, or if
					vTrader reasonably suspects you of using your vTrader Account in furtherance of illegal activity, or if you
					take any action that vTrader deems as circumventing vTrader&apos;s controls, including, but not limited to,
					opening multiple vTrader Online Accounts or abusing promotions which vTrader may offer from time to time.
				</p>
				<p className='terms-text'>
					If you have unsettled transactions in an account which has been suspended or closed, you will be able to
					recover such funds, unless prohibited by law or a court order. vTrader, in its discretion, will allow you to
					either (i) withdraw such funds to an already-known bank account (provided you own cryptocurrencies which can
					be used in the Convert Service), or (ii) send your funds to a wallet from the network afferent to the
					cryptocurrencies you own. Any taxes and commissions afferent to these transactions are the same taxes which
					are generally perceived and displayed on the vTrader website.{" "}
				</p>
				<p className='terms-text'>
					vTrader is entitled to notify you the suspension of the Agreement with immediate effect, whenever vTrader
					considers such a measure to be necessary in order to protect its interests, in which case the access to the
					vTrader Account and to the vTrader Services will be also suspended.
				</p>
				<p className='terms-text'>
					vTrader reserves the right that, function to the legal regulation of the cryptocurrencies by the Romanian
					authorities and/or the European Union authorities, to suspend, or, as the case may be, stop the functioning of
					the vTrader Account and of the vTrader Services, in which case you are not entitled to claim any prejudice, of
					any kind, from vTrader as a result of such a measure.
				</p>
				<p className='terms-text'>
					You may terminate this Agreement at any time by discontinuing use of the vTrader Services. Upon termination of
					this Agreement and your account, you remain liable for all transactions made while the account was open.
				</p>
				<p className='terms-text'>
					Cases of termination of the Terms and Conditions: unless otherwise expressly provided in the contents of the
					hereby Agreement, the hereby Agreement may be also terminated in the following situations: (a) by the
					agreement of the parties, (b) by unilateral denunciation by either party, with a 14-day prior notice given to
					the other party; (c) if vTrader is in impossibility of providing the services making the object of the hereby
					agreement for more than 14 days; (d) unilateral termination by vTrader if you fail to comply with your
					obligations under the hereby Agreement or if any of the statements, guarantees, information supplied within or
					under the hereby Agreement proves to be false, inaccurate or misleading for vTrader.
				</p>
				<p className='terms-text'>
					Also, you are entitled to unilaterally terminate the hereby agreement within 14 calendar days from its
					conclusion, without any penalties or additional costs and without motivating your decision; if you decide to
					unilaterally terminate the hereby agreement within 14 calendar days from its conclusion, you will be bound to
					pay to vTrader all the commissions and fees corresponding to the services provided in your benefit until
					vTrader has received the written notice of termination. Also, any transactions which you have initiated and
					which will be ongoing on the date of receipt of the notice of termination, will be completed, without having
					the possibility of requesting their reversal.
				</p>
				<p className='terms-text'>
					For the avoidance of any doubt, if the vTrader Account is suspended, the client is entitled to contact the
					support department in view of liquidating the vTrader Account. Such a request will be made on the email
					address of the support department (support@vTrader.io) and will include the mentioning of some external
					wallets from the network of each cryptocurrency held, as well as the client&apos;s bank accounts if the
					cryptocurrencies held can be used in the Convert Service. vTrader will transfer the cryptocurrencies you hold
					or the fiat currencies afferent to the cryptocurrencies which can be used in the Convert Service which you
					hold in the external wallets and/or bank accounts already known indicated in the liquidation request. vTrader
					reserves the right to suspend, delay or, as the case may be, cancel the processing of such a transaction as
					required by law or in response to a subpoena, court order, or other binding government order or decision of a
					public body, authority or institution (especially those with attributions in criminal, fiscal, financial,
					banking field). Also, vTrader may request additional verifications of the identity, if it considers it
					necessary, before processing such transactions.
				</p>
				<p className='terms-title-section'>5.4. Right to Suspend or Delay Transactions and Settlements</p>
				<p className='terms-text'>
					vTrader reserves the right to refuse to process, refuse to settle or to cancel any pending cryptocurrency or
					fiat currency transaction connected to your vTrader Account or any vTrader Service as required by law or in
					response to a subpoena, court order, or other binding government order or decision of a public body, authority
					or institution (especially those with attributions in criminal, fiscal, financial, banking field). vTrader
					informs you and you expressly accept the fact that vTrader cannot reverse a cryptocurrency settlement which
					has been communicated to and confirmed by the particular cryptocurrency network.
				</p>
				<p className='terms-text'>
					vTrader reserves the right to refuse to process, or to cancel or reverse, any purchases or sales of
					cryptocurrencies or transfers in its sole discretion, if (i) vTrader suspects the transaction involves (or has
					a high risk of involvement in) money laundering, terrorist financing, fraud or any other type of financial
					crime, (ii) as well as in any case in response to a subpoena, court order, or other government order, or
					decision of a public body, authority or institution (especially those with attributions in criminal, fiscal,
					financial, banking field) or (iii) if vTrader suspects the transaction or the operations from your vTrader
					Account relate(s) to a Restricted Activity as defined in Section 12.2, which listed below.
				</p>
				<p className='terms-text'>
					vTrader does not guarantee that the vTrader Website or the access to the vTrader Account or to the vTrader
					Services will be accessible/available permanently or that their functioning will be continuous and without
					errors. Whenever it may consider it necessary, as well as in any case when a technical error causes the
					stoppage of the vTrader Website or the stoppage/disturbance of the access to the vTrader Account or to the
					VTrader Services or errors of the foregoing , vTrader will be able to interrupt the vTrader Website or may
					temporarily suspend your access to your vTrader Account or to the vTrader Services in order to remedy a
					problem occurred or, as the case may be, to perform some revisions or other similar operations. With the
					exception of the force majeure cases, vTrader will make all the reasonable efforts to announce you in due time
					about any interruption of the functioning of the vTrader Website or of the access to the vTrader Account or to
					the vTrader Services and will limit the duration of the interruption to the time necessary for the performance
					of the revisions or other similar operations.
				</p>
				<p className='terms-text'>
					We reserve the right to change, suspend, or discontinue any aspect of the vTrader Services at any time,
					including hours of operation or availability of any feature, without a prior notice and without liability and
					you accept, agree and undertake not to claim any compensation from Vtrader as a result of such measures.
					vTrader may limit or suspend your use of one or more Services at any time, in its sole discretion. Suspension
					of your use of any of the Services will not affect your rights and obligations pursuant to this Agreement,
					with the exception of those regarding exclusively the vTrader Service whose use has been suspended.
				</p>
				<p className='terms-text'>
					vTrader may, in its sole discretion, delay/suspend/cancel transactions or settlements if it believes the
					transaction is suspicious, may involve fraud or misconduct, violates applicable laws, or violates the terms of
					this Agreement.
				</p>
				<p className='terms-title-section'>5.5. Account Statement. Transaction Limits. Accepting orders</p>
				<p className='terms-text'>
					vTrader opens and operates in your name and account a distinct cryptocurrency account in which all of your
					Custodian holdings and transactions with your Cryptocurrency, executed under this Agreement, will be
					highlighted. Cryptocurrency holdings will be reflected in that currency unit, and for orientation and
					non-binding purposes, vTrader will also display an estimated value in Euro.
				</p>
				<p className='terms-text'>
					vTrader may impose limits on the amount of cryptocurrency that you may buy, sell or exchange in a given day.
				</p>
				<p className='terms-text'>
					vTrader reserves the right to limit the value of one of your orders or of a transaction with regard to the
					cryptocurrencies that you want to buy from or sell to vTrader, or by the agency of one of the VTrader
					Services, as well as the right to reject or cancel an order from you or to impose certain limits in trading
					the cryptocurrencies.
				</p>
				<p className='terms-text'>
					For the avoidance of any doubt, vTrader does not assume the obligation to sell to you or the obligation to buy
					from you any cryptocurrency. You transfer, and vTrader can fulfill buy/sell cryptocurrency orders, and any
					such order of yours may be considered accepted by vTrader only if vTrader actually processes that operation by
					crediting your vTrader account. If the transaction does not comply with the procedures set forth in this
					Agreement, or if there is a risk of fraud or illegal activities, vTrader reserves the right to refuse the
					intermediation of the Transaction, in which case you will be notified via the e-mail declared when creating
					the vTrader Online Account. Also, the exchange of cryptocurrency through your vTrader Account does not
					guarantee that the operation will be completed and any such order from you will be considered accepted by
					vTrader only if vTrader actually processes that order by changing your vTrader Account&apos;s balances.
				</p>
				<p className='terms-title-section'>5.6. Withdrawing From a Transaction. Cancelling a trade</p>
				<p className='terms-text'>
					You do not have a statutory right to withdraw from a transaction to buy or sell cryptocurrencies (order of
					supply with cryptocurrencies / order to withdraw fiat currencies) once you have instructed vTrader because the
					price of cryptocurrencies fluctuate in accordance with the financial market for cryptocurrencies which is
					outside the control of vTrader. Cancelling a Trader (or Order) regarding the sale/purchase/exchange of
					cryptocurrencies will not guarantee you that the Trade/Order will cancel in its current state. The Trade might
					further fill after the action of Cancelling the order because of network delays or other factors that are out
					of our control, in which case the result of the Transaction/Order may be unfavourable, so that, in such a
					hypothesis, you assume any possible loss and understand to waive any claims or compensations from vTrader
					under or in relation to or as a result of that Transaction/Order.
				</p>
				<p className='terms-title-section'>5.7. Legal fees</p>
				<p className='terms-text'>
					It is your sole responsibility to determine whether, and to what extent, any taxes/legal, fiscal or other fees
					apply to the cryptocurrency transactions you conduct through the Convert Services, and to withhold, collect,
					report and remit the correct amounts of taxes to the appropriate tax authorities. Your transaction history is
					available through your vTrader Online Account. However, you hereby authorize vTrader to report to competent
					authorities your earnings / losses through your vTrader account in accordance with the applicable tax laws.
				</p>
				<p className='terms-title-section'>5.8. Legal Compliance</p>
				<p className='terms-text'>
					Pursuant to the economic sanctions programs administered in the countries where vTrader conducts business,
					vTrader is prohibited from providing services or entering into relationships with certain individuals and
					entities. In the event that vTrader is required to block assets associated with your vTrader Account in
					accordance with a sanctions program, vTrader may (i) deactivate or cancel your vTrader Account or block user
					activity, (ii) reject funds transfers; (iii) settle transactions associated with your vTrader Account to an
					account specified by authorities. vTrader is not responsible for any losses, whether direct or indirect, that
					you may incur as a result of our complying with applicable law, the guidance or direction of any regulatory
					authority or government agency, or any writ of attachment, lien, levy, subpoena, warrant or other legal order
					given by a public body, authority or institution with attributions in criminal, fiscal, financial, banking
					field).
				</p>
				<p className='terms-title-section'>6. Convert Service</p>
				<p className='terms-text'>
					The Convert Service facilitates two types of transactions to the User: &quot;Cryptocurrency add funds
					orders,&quot; &quot;Cryptocurrency withdrawal orders&quot;. Cryptocurrency add funds orders are transactions
					through which you buy cryptocurrency with fiduciary coins through vTrader, and Cryptocurrency withdrawal
					orders are transactions through which you sell cryptocurrency for fiduciary coins through vTrader. Operations
					related to this service can only be made with Euros. The Convert Service is only available for certain types
					of cryptocurrencies. Cryptocurrencies that can be used for Cryptocurrency add funds orders are specified in
					the &quot;Add Funds&quot; section of your vTrader Online Account, and cryptocurrencies that can be used for
					Cryptocurrency withdrawal orders are specified in the &quot;Withdraw Funds&quot; section of Your vTrader
					Online account.
				</p>
				<p className='terms-title-section'>6.1. Transactions with vTrader</p>
				<p className='terms-text'>
					When buying or selling cryptocurrency through the Convert Service, do not buy or sell directly from / to
					vTrader. To avoid any doubt, vTrader does not undertake the obligation to sell to you or the obligation to buy
					from you cryptocurrency. Through the Convert Service, you authorize vTrader to buy or sell cryptocurrency on
					your behalf and on your account, and any such Order on your part will only be accepted by vTrader if vTrader
					actually processes that operation by crediting your vTrader Account . If the transaction does not comply with
					the procedures set forth in this Agreement, or if there is a risk of fraud or illegal activities, vTrader
					reserves the right to refuse your transaction, in which case you will be notified via the e-mail declared when
					creating your vTrader Online Account.
				</p>
				<p className='terms-text'>
					Cryptocurrency add funds order consists of completing the form in the &quot;Add Funds&quot; section, which is
					conditioned by the transfer of the fiduciary currency to the vTrader bank account containing the bank transfer
					reference made to instruct vTrader to convert the fiduciary transferred to cryptocurrency (USD Tether (USDT)
					at an Orientative Conversion Rate. The withdrawal order consists of completing the form in the
					&quot;Withdrawal Funds&quot; section in order to instruct vTrader to convert and transfer to your bank account
					the amount resulted from the sale of your cryptocurrencies, sold at an orientative Conversion Rate
				</p>
				<p className='terms-text'>
					The Conversion Service is subject to a vTrader &quot;Purchase Conversion Rate&quot; and a vTrader &quot;Sale
					Conversion Rate&quot;. At the time the Order is launched, this &quot;Conversion Rate&quot; is indicative, and
					its final value will be announced upon the execution of the Order. &quot;Conversion Rate&quot; is not an
					exchange rate, it is a value taken from Third parties. &quot;Conversion Rate&quot; means the
					liquidity-adjusted price of a cryptocurrency amount in terms of the fiduciary currency displayed on the
					vTrader site. The Conversion Rate is declared either as &quot;Purchase Price&quot;, in which case it is the
					fiduciary currency price at which you purchase the cryptocurrencies through vTrader or &quot;Selling
					Price&quot;, in which case it is the fiduciary currency price at which you sell cryptocurrencies through
					vTrader.
				</p>
				<p className='terms-text'>
					For the avoidance of any doubt, the Conversion Rate has a distinct value for sale and a distinct value for
					purchase, as your transaction/order regards the sale or, as the case may be, the purchase of cryptocurrencies.
				</p>
				<p className='terms-text'>
					For the purpose of any transaction which incorporates the Conversion Rate, you agree, as a condition of using
					any vTrader Services, to accept the Conversion Rate as the sole conversion metric, consequently, by using the
					Convert Service, you agree with the use of the Conversion Rate as sole conversion metric.
				</p>
				<p className='terms-title-section'>6.2. Initiating a convert transaction</p>
				<p className='terms-text'>
					In order to use the Convert Service you must create a Convert Transaction using your vTrader Online Account.
					Before creating a Convert Transaction, you will need to provide information such as: your name, first name,
					email, phone number, town, address, county/district, zip code, date of birth, a clear photo of your identity
					document and/or a visible photo of invoice for utility services not older than three months, information
					requested in the section &quot;My profile &gt; vTrader Account&quot; of your vTrader Online Account, including
					your bank account information (&quot;User Bank Account&quot;). This information will act as settlement
					instructions and is required for us to be able to provide the Convert Service. vTrader will use the User Bank
					Account information when you buy and sell cryptocurrencies. It will allow us to send you fiat currency when
					you sell cryptocurrencies and allow us to confirm that the fiat currency you use to buy cryptocurrencies
					originates in your account.{" "}
				</p>
				<p className='terms-text'>
					Likewise, vTrader will provide information about its bank account (&quot;vTrader Bank Account&quot;) and a
					code which you will need to use when you send fiat currencies to vTrader (&quot;Reference&quot; or
					&quot;Reference Code&quot;). This information will become available by accessing the Convert Transaction you
					have created.
				</p>
				<p className='terms-text'>
					The number of Convert Transactions which you can create have some certain daily limits in order to prevent the
					system spamming.
				</p>
				<p className='terms-title-section'>6.4. Buying cryptocurrencies </p>
				<p className='terms-text'>
					You understand that, by making a fiat currency payment to vTrader Bank Accounts as provided by vTrader, you
					are issuing an order to purchase cryptocurrencies at the “Conversion Rate” at the time we process the order.
					Your orders accepted by vTrader are processed during our business hours as stated on the vTrader Website after
					we receive the payment into one of the vTrader Bank Accounts. In connection with any purchase of
					cryptocurrencies through the Convert Service, vTrader will use good faith efforts to fulfil such purchase
					orders at the applicable Buy Price Conversion Rate quoted on the vTrader Website at the time that we received
					the payment into the vTrader Bank Accounts. However, from time to time, it may be necessary for vTrader to
					delay fulfilment of a purchase order until a time when we are able to execute the transaction. In such cases,
					we will notify you directly the amount of cryptocurrency that you will receive; it will be determined based on
					the applicable Buy Price Conversion Rate quoted on the vTrader Website at the time vTrader executes your
					order, not the applicable Buy Price Conversion Rate quoted on the vTrader Website at the time we received the
					payment in the vTrader Bank Accounts. Please be aware that fiat currency payments may take up to two working
					days to reach vTrader Bank Accounts, and as such, the Conversion Rate published on the vTrader Website at the
					time you send the payment may be different from the time we receive such payment. The price of
					cryptocurrencies fluctuates in accordance with the financial market for cryptocurrencies which is outside the
					control of vTrader.
				</p>
				<p className='terms-text'>
					The value of the cryptocurrency that you purchased will be determined by dividing the amount of the fiat
					currency you sent from the User Bank Account to the vTrader Bank Accounts by the Buy Price Conversion Rate,
					minus the fixed commission displayed upon the initiation of the transaction.
				</p>
				<p className='terms-text'>
					Your order will usually be settled in 1-2 business days, although vTrader reserves the right to
					delay/suspend/cancel any such settlement if it perceives a risk of fraud or illegal activity.
				</p>
				<p className='terms-text'>
					„The reference” represents and alpha-numeric code, created on the establishment of your vTrader Online Account
					and associated with it. The reference will be unique for each used and will allow vTrader to correlate the
					User&apos;s Bank Account with your vTrader Account and to credit cryptocurrencies in the intended vTrader
					account.
				</p>
				<p className='terms-text'>
					The payments in fiat currencies without reference specified in the Payment details will be returned or
					delayed, and vTrader will make all the efforts in order to send back to the initial account all the payments,
					or resolve the transaction. The payments in fiat currencies performed using a Reference and which are not
					coming from the User&apos;s correct Bank Account or which come from an unknown bank account (whose name
					doesn&apos;t match with the name declared by the User) will be returned.
				</p>
				<p className='terms-text'>
					vTrader asks you to be extremely careful when you transmit payments in fiat currencies to use the correct
					reference, as indicated in the section „Add funds” in order to avoid any delays caused by the return of the
					payments.
				</p>
				<p className='terms-title-section'>6.4. Selling Cryptocurrencies</p>
				<p className='terms-text'>
					You understand that, by making a convert withdrawal to vTrader as provided by vTrader, you are issuing an
					order to sell cryptocurrencies at the “Conversion Rate” for the Sell Price at the time we process the order
					(Order of withdrawal in fiat currencies). Your orders accepted by vTrader are processed automatically on an
					ongoing basis, during our business hours displayed on the vTrader Website. In connection with any withdrawal
					of fiat currencies through the Convert Service, vTrader will use good faith efforts to fulfil such sell orders
					at the applicable Sell Price Conversion Rate quoted on the vTrader Website at the time that we processed the
					order and will make any effort in executing your order within 1-2 business days. However, from time to time,
					it may be necessary for vTrader to delay settlement of a sell order until the time in which we are able to
					execute the transaction. In such cases, we will notify you directly the amount of fiat currency that you will
					receive; it will be determined based on the applicable Sell Price Conversion Rate quoted on the vTrader
					Website at the time vTrader processes your order, not the applicable Sell Price Conversion Rate quoted on the
					vTrader Website at the time you initiated the convert withdrawal order. In relation to those stated above,
					vTrader informs you that the Conversion Rate for the Sell Price published on the vTrader Website on the date
					you initiate the payment of the cryptocurrencies (you issue the order of withdrawal in fiat currencies (you
					initiate the sale of the cryptocurrencies) may be very different from that at the time the we are able to
					process it. The price of cryptocurrencies fluctuates in accordance with the financial market for
					cryptocurrencies which is outside the control of vTrader.
				</p>
				<p className='terms-text'>
					The value of fiat currency that you receive in your User Bank Account as a result of your sale order will be
					determined by multiplying the amount of cryptocurrency placed in a convert withdrawal order to the Sell Price
					Conversion Rate , minus the fixed commission displayed at the time of the initiation of the transaction. The
					amount of fiat currency that results will be delivered to the User Bank Account you provided when initiating
					the transaction. Your order will usually be settled in 1-2 business days, although vTrader reserves the right
					to delay any such settlement if it perceives a risk of fraud or illegal activity.
				</p>
				<p className='terms-title-section'>7. Exchange Service</p>
				<p className='terms-text'>
					The Exchange Service allows the User to change the cryptocurrencies it holds with other cryptocurrencies. The
					exchange Service is performed by the emission by the User of some Exchange Orders. The Orders that the User
					can request are Orders of purchase, by which the User requests the purchase of a certain type of
					cryptocurrency and Sale Orders, by which the User requests the sale of a certain type of cryptocurrency.
					Orders are intermediated by vTrader, but vTrader does not directly exchange cryptocurrency with the user. The
					Order is completed by other users or third parties.
				</p>
				<p className='terms-title-section'>7.1 Creating a and Exchange Order</p>
				<p className='terms-text'>
					In order to create an Exchange Order, the User must choose the cryptocurrency, must declare the unitary amount
					of cryptocurrency participant to the Exchange Order, as well as the purchase/sale price for a unit of the
					respective cryptocurrency. The Exchange Orders may be placed on a certain &quot;Market&quot;. The market is
					represented by another cryptocurrency with which the exchange is to be performed. Therefore, the price
					established by the user for the cryptocurrency it will purchase/sell will be expressed in the cryptocurrency
					representing the Market chosen for the Exchange Order.
				</p>
				<p className='terms-title-section'>7.2 Completion of an Exchange Order</p>
				<p className='terms-text'>
					You understand that the Exchange Order you have placed for processing represents a request for the exchange of
					a cryptocurrency held by you with another cryptocurrency. Thus, vTrader does not offer any guarantee that the
					Exchange Orders transmitted for processing will be completed partially or integrally. Also, vTrader does not
					offer any guarantee with regard to the time necessary for the partial or integral completion of your Exchange
					Order. The partial or integral completion of an Exchange Order depends on factors which are beyond
					vTrader&apos;s control, including, but not limited to, the price afferent to the Exchange Order, the volume
					afferent to the Exchange Order, the fluctuation of the financial market of the cryptocurrencies.
				</p>
				<p className='terms-title-section'>7.3 Cancellation of an Exchange Order</p>
				<p className='terms-text'>
					The User may initiate a &quot;Command for the cancellation of an Exchange Order&quot; placed for processing.
					Upon the receipt of a Command for the cancellation of an Exchange Order, vTrader will try to automatically
					retire from processing the Exchange Order in question. You understand that a Command for the cancellation of
					an Exchange Order does not guarantee in any way the withdrawal of the Exchange Order from the processing,
					since the withdrawal of the Exchange order depends on factors which are beyond vTrader&apos;s control,
					including, but not limited to network delays, exchange processes already initiated.
				</p>
				<p className='terms-title-section'>8. The Service of Trading on the Network </p>
				<p className='terms-text'>
					The Service of Trading on the Network allows the User to supply the vTrader Account with cryptocurrencies from
					the network of cryptocurrencies in question (Supply Transaction) and the withdrawal of the cryptocurrencies
					from the vTrader Account on the network of cryptocurrencies in question (Withdrawal Transaction).
				</p>
				<p className='terms-title-section'>8.1 Creating a Deposit Transaction</p>
				<p className='terms-text'>
					The Transactions performed on the networks of cryptocurrencies generally require a destination address
					specific to a wallet of the network where the cryptocurrencies will be transmitted following the transaction.
					vTrader puts at the User&apos;s disposal a destination address for each cryptocurrency allowing deposit
					transactions on the vTrader Website. These destination addresses are available in the section „Add Funds” from
					the User&apos;s vTrader Online Account. With the help of these addresses, the User can initiate from outside a
					transaction to the wallet corresponding to that address, thus supplying his vTrader Account with the
					cryptocurrencies transferred.
				</p>
				<p className='terms-title-section'>8.2 Creating a Withdrawal Transaction</p>
				<p className='terms-text'>
					The User may withdraw cryptocurrencies from his vTrader Account into an external wallet from the network of
					the cryptocurrency withdrawn by initiating a Withdrawal Transaction. The Withdrawal Transactions may be
					initiated in the section &quot;Withdraw Funds&quot; from the User&apos;s vTrader Online Account. The User must
					respect entirely the instructions offered in the vTrader Online Account in order to initiate a Withdrawal
					Transaction. We remind you that the transactions performed on the networks of the cryptocurrencies are not
					reversible and are not under vTrader&apos;s control. By initiating a Withdrawal Transaction you instruct
					vTrader to perform a transaction on the network, which involves and is limited to your funds from your vTrader
					Account.
				</p>
				<p className='terms-title-section'>8.3 Availability of the Trading on the Network Service</p>
				<p className='terms-text'>
					Since the networks of cryptocurrencies can suffer changes at any time, vTrader does not guarantee in any way
					the availability of the Trading Service on the Network. However, vTrader directs its efforts and acts
					permanently with the purpose of improving the quality of the services, including, but not limited to, the
					improvement of the Trading on the Network Service in accordance with the current condition and the
					modifications of the networks of cryptocurrencies.
				</p>
				<p className='terms-title-section'>9. Commissions and taxes</p>
				<p className='terms-text'>
					In exchange of the services provided, which are part of the object of the hereby Agreement, by vTrader, you
					owe to vTrader and undertake to pay to vTrader the following taxes and commissions:
				</p>
				<ul>
					<li className='terms-text'>
						A fixed tax for the Convert Service, applied both for the sums used in the Deposit Convert Orders and for
						the sums used in the Withdrawal Convert Orders. These taxes are detailed on the main page of the Website
						vTrader, as well as in the sections „Add funds” and „Withdraw funds” from your vTrader Online Account, as
						the case may be.
					</li>
					<li className='terms-text'>
						Percentage tax for the Exchange Service, applied to the Sum exchanged at the complete or partial execution
						of an Exchange Order, both for Purchase Orders and for Sale orders. This tax is detailed on the main page of
						the Website vTrader as well as in the section „Trading” from your vTrader Online Account.
					</li>
					<li className='terms-text'>
						Fixed taxes for the Service of Transacting on the Network, applied both for the sums afferent to the Supply
						Transactions and for the sums afferent to the Withdrawal Transactions. These taxes are detailed in the
						sections „Add Funds” or „Withdraw Funds” from your vTrader Online Account.
					</li>
				</ul>
				<p className='terms-text'>
					as well as all the taxes and costs due to some third parties in relation to the transactions performed on your
					vTrader Account or in relation to Your vTrader Account / vTrader Services which you have used, which are
					included in the above taxes.
				</p>
				<p className='terms-text'>
					vTrader can unilaterally amend the amount of the taxes and commissions perceived under the hereby Agreement,
					in accordance with the provisions of section14.8. below..
				</p>
				<p className='terms-title-section'>10. Use of the vTrader Website</p>
				<p className='terms-title-section'>10.1. Accuracy</p>
				<p className='terms-text'>
					For the avoidance of any doubt, with the exception of the taxes and commissions detailed on the main page of
					the vTrader Website and/or in the sections from the vTrader Website where vTrader Services are provided, as
					well as the Conversion Rate displayed on the main page of the vTrader Website and/or in the sections from the
					vTrader Website where vTrader Services are provided, the information supplied on the vTrader Website and by
					the agency of the vTrader Services is for your guidance only. However, vTrader directs its efforts and acts
					permanently with the purpose of improving the quality of the services and of the information supplied by the
					agency of the vTrader Website / vTrader Services. For this purpose, vTrader makes efforts to provide accurate
					and reliable information and content on the vTrader Website and through the vTrader Service, but such
					information may not always be correct, complete, or up to date. vTrader will update the information on the
					vTrader Website as necessary to provide you with the most up to date information, but you should always
					independently verify such information.
				</p>
				<p className='terms-text'>
					The vTrader Website may also contain links to third party websites, applications, events or other materials
					/information (“Third Party Content”). Such information is provided for your convenience and links or
					references to Third Party Content do not constitute an endorsement by vTrader of any products or services, and
					vTrader does not guarantee in any way that the Third Party Content provide you clear, accurate, complete and
					updated information. vTrader shall have no liability for any losses incurred as a result of actions taken in
					reliance on the information contained on the vTrader Website or in any Third Party Content.
				</p>
				<p className='terms-title-section'>10.2. Intellectual Property</p>
				<p className='terms-text'>
					vTrader will grant you a limited, non-exclusive, non-sublicensable, and non-transferable licence, subject to
					the terms and conditions of this Agreement, to access and use the vTrader Services solely for approved
					purposes as determined by vTrader. Any other use of the Services is expressly prohibited. vTrader and its
					licensors reserve all rights in the Services and you agree that this Agreement does not grant you any rights
					in or licences to the Services except for the limited licence set forth above. Except as expressly authorised
					by vTrader, you agree not to modify, reverse engineer, copy, frame, scrape, rent, lease, loan, sell,
					distribute, or create derivative works based on the vTrader Services, in whole or in part. If you violate any
					portion of this Agreement, your permission to access and use the Services may be terminated pursuant to this
					Agreement.{" "}
				</p>
				<p className='terms-text'>
					&quot;vtrader.io&quot;, &quot;vTrader&quot;, and all logos related to the Services are either trademarks, or
					registered marks of vTrader or its licensors. You may not copy, imitate, or use them without vTrader’s prior
					written consent. All rights, title, and interest in and to the vTrader Website, any content thereon, the
					Services, and all technology and any content created or derived from any of the foregoing is the exclusive
					property of vTrader and its licensors
				</p>
				<p className='terms-title-section'>11. Our Relationship with You</p>
				<p className='terms-title-section'>11.1. Relationship of the Parties</p>
				<p className='terms-text'>
					vTrader is an independent contractor for all purposes. Nothing in this Agreement shall be deemed or is
					intended to be deemed, nor shall it cause, you and vTrader to be treated as partners, joint ventures, or
					otherwise as joint associates for profit, or either you or vTrader to be treated as the agent of the other.
				</p>
				<p className='terms-title-section'>11.2. Service Providers</p>
				<p className='terms-text'>
					From time to time, vTrader may engage third parties to assist vTrader in providing certain aspects of the
					vTrader Services (each, a &quot;Service Provider&quot;). Service Providers may include, but are not limited
					to, vTrader&apos;s banking partners and technology or engineering service providers.
				</p>
				<p className='terms-title-section'>
					11.3. Notices and communications in relation to the vTrader Account and the vTrader Services
				</p>
				<p className='terms-text'>
					For executing the hereby agreement and for the fulfilment of its purpose, you agree that vTrader may provide
					you with any notices or other communications about your vTrader Account and the vTrader Services
					electronically: (a) via email (in each case to the address that you provide), SMS message, or telephone call
					(in each case to the phone number that you provide), or (b) by posting to the vTrader Website. For notices
					made by email, the date of receipt will be deemed the date on which such notice is transmitted. If you do not
					wish to receive notices or other communications electronically, we will be unable to support your vTrader
					Account and your vTrader Account will be cancelled. For the avoidance of any doubt, the notices and
					communications referred to in the hereby paragraph are not advertising or marketing notices. No mobile
					information will be shared with third parties/affiliates for marketing/promotional purposes. All the above
					categories exclude text messaging originator opt-in data and consent; this information will not be shared with
					any third parties. vTrader: Verification code for Onboarding new users to the vTrader platform. One message
					per request. Reply &apos;HELP&apos; for support or &apos;STOP&apos; to cancel. Message and Data rates may
					apply may apply. Carriers are not liable for delayed or undelivered messages.
				</p>
				<p className='terms-title-section'>11.4. Notices to vTrader</p>
				<p className='terms-text'>
					You may transmit any notices or information or complaint to vTrader electronically, in the attention of the
					vTrader Support department, on the electronic e-mail address: support@vTrader.io
				</p>
				<p className='terms-text'>
					vTrader will solve any request or notice or complaint within 30 calendar days from its receipt.
				</p>
				<p className='terms-title-section'>12. Restricted Activities</p>
				<p className='terms-title-section'>12.1. Assets in custody</p>
				<p className='terms-text'>
					You hereby represent and warrant that all funds used by you in connection with the vTrader Services are your
					property or you are authorized to conduct transactions using these funds. In particular, You acknowledge that
					vTrader is the administrator of the goods (criptocurrency) within the meaning of Title V, Book III of the
					Romanian Civil Code and you represent and warrant that the use of the vTrader Services by you is in compliance
					with all applicable legal requirements governing the regime of goods, whatever their nature, that you use
					under this contract.
				</p>
				<p className='terms-title-section'>12.2. Restricted Activities</p>
				<p className='terms-text'>
					In connection with your use of the vTrader Services, and your interactions with other users, and third parties
					you will not:
				</p>
				<p className='terms-text'>
					a) Violate or assist any party in violating any law, statute, ordinance, regulation or any rule of any
					self-regulatory or similar organisation of which you are or are required to be a member (for example, those
					laws, rules, or regulations governing financial services, controlled substances, unlawful gambling or consumer
					protections);
				</p>
				<p className='terms-text'>b) Partake in a transaction which involves the proceeds of any unlawful activity;</p>
				<p className='terms-text'>c) Defraud or attempt to defraud vTrader or other vTrader users;</p>
				<p className='terms-text'>
					d) Infringe upon vTrader&apos;s or any third party&apos;s copyright, patent, trademark, or intellectual
					property rights;
				</p>
				<p className='terms-text'>e) Provide false, inaccurate or misleading information;</p>
				<p className='terms-text'>
					f) Take any action that imposes an unreasonable or disproportionately large load on our infrastructure, or
					detrimentally interfere with, intercept, or expropriate any system, data, or information;
				</p>
				<p className='terms-text'>
					g) Interfere with another individual&apos;s or entity&apos;s access to or use of any of the vTrader Services;
				</p>
				<p className='terms-text'>
					h) Defame, abuse, harass, stalk, threaten or otherwise violate or infringe the legal rights (such as, but not
					limited to, rights of privacy, publicity and intellectual property) of others;
				</p>
				<p className='terms-text'>i) Publish, distribute or disseminate any unlawful material or information;</p>
				<p className='terms-text'>
					j) Transmit or upload any material to the vTrader Website that contains viruses, Trojan horses, worms, or any
					other harmful or deleterious programmes;
				</p>
				<p className='terms-text'>
					k) Harvest or otherwise collect information from the vTrader Website about others, including without
					limitation email addresses, without proper consent;
				</p>
				<p className='terms-text'>
					l) Act as a payment intermediary or aggregator or otherwise resell any of the vTrader Services, unless
					expressly authorised by vTrader in writing;
				</p>
				<p className='terms-text'>m) Transfer any rights granted to you under this Agreement;</p>
				<p className='terms-text'>
					n) Use the vTrader Account information of another party to access or use the vTrader Website;
				</p>
				<p className='terms-text'>
					o) Otherwise attempt to gain unauthorised access to the vTrader Website, other vTrader Online Accounts,
					computer systems or networks connected to the vTrader Website, through password mining or any other means; or
				</p>
				<p className='terms-text'>
					p) Engage in transactions involving items that infringe or violate any copyright, trademark, right of
					publicity or privacy or any other proprietary right under the law.
				</p>
				<p className='terms-title-section'>13. Customer Queries and Complaints</p>
				<p className='terms-text'>
					<span>If you have any complaints or queries please contact vTrader Support</span> at support@vtrader.io
					<span>
						{" "}
						and we will try to resolve the matter quickly. When you contact us please provide us with your name, address
						and any other information we may need to identify you and your vTrader Account.
					</span>
				</p>
				<p className='terms-title-section'>14. General Provisions</p>
				<p className='terms-title-section'>14.1. Limitations of Liability</p>
				<p className='terms-text'>
					(A) In no event shall vTrader, its affiliates and service providers, or any of their respective officers,
					directors, agents, joint venturers, employees or representatives, be liable for lost profits or any special,
					incidental or consequential damages arising out of or in connection with the vTrader Website, the vTrader
					Services, or this agreement(however arising, including negligence), with the exception of any material damage
					caused by a deed committed with intention or from gross fault.
				</p>
				<p className='terms-text'>
					(B) For consumers only: where applicable consumer protection law limits the effectiveness of Section 11.1(a)
					with respect to consumers, Section 11.1(a) only applies to the maximum extent as permitted by law
				</p>
				<p className='terms-title-section'>14.2. Lack of warranty</p>
				<p className='terms-text'>
					(A) The vTrader services are provided on a &quot;as is&quot; and &quot;as available&quot; basis without any
					representation or warranty, whether express, implied or statutory. vTrader specifically disclaims any implied
					warranties of title, merchantability, fitness for a particular purpose and non-infringement. vTrader does not
					guarantee continuous, uninterrupted or secure access to any part of vTrader Services, and operation of the
					vTrader Website may be interfered with by numerous factors outside of our control. vTrader will make
					reasonable efforts to ensure that requests for settlements to bank accounts or cryptocurrency addresses are
					processed in a timely manner but vTrader makes no representations or warranties regarding the amount of time
					needed to complete processing because the vTrader services are dependent upon many factors outside of our
					control, such as delays in the banking system or cryptocurrency networks.
				</p>
				<p className='terms-text'>
					(B) For consumers only: where applicable consumer protection law limits the effectiveness of Section 11.1. (b)
					with respect to consumers, Section 11.1. (b) only applies to the maximum extent as permitted by law.
				</p>
				<p className='terms-title-section'>14.3. Assignment</p>
				<p className='terms-text'>
					The hereby Agreement, and any rights and licences granted hereunder, may not be transferred or assigned by
					you, but may be assigned by vTrader without restriction, including without limitation to any of its affiliates
					or subsidiaries, or to any successor in interest of any business associated with the vTrader Services. Any
					attempted transfer or assignment in violation hereof shall be null and void. Subject to the foregoing, this
					Agreement will bind and inure to the benefit of the parties, their successors and permitted assigns.
				</p>
				<p className='terms-title-section'>14.4. Change of Control </p>
				<p className='terms-text'>
					In the event that vTrader is acquired by or merged with a third party entity, we reserve the right, in any of
					these circumstances, to transfer or assign the information we have collected from you as part of such merger,
					acquisition, sale, or other change of control.
				</p>
				<p className='terms-title-section'>14.5. Survival</p>
				<p className='terms-text'>
					All the provisions of this Agreement which by their nature extend beyond the expiration or termination of this
					Agreement, including, without limitation, Sections 5.3 (vTrader Account Suspension &amp; Closure), 7 (Use of
					the vTrader Website), 10 (Customer Queries and Complaints) and 11 (General Provisions) shall survive the
					termination or expiration of this Agreement.
				</p>
				<p className='terms-title-section'>14.6. Governing Law and Jurisdiction</p>
				<p className='terms-text'>
					(A) This Agreement will be governed by the Romanian law and will be interpreted in accordance with the
					Romanian legislation.
				</p>
				<p className='terms-text'>
					(B) Subject to Article 16 from the Council Regulation (CE) nr. 44/2001 regarding the judicial competence, the
					acknowledgement and the execution of the judgments in civil and commercial matter, any dispute deriving from
					or in relation to the hereby Agreement will be submitted for settlement to the competent judicial courts from
					Romania, at the vTrader office.
				</p>
				<p className='terms-title-section'>14.7. Force Majeure</p>
				<p className='terms-text'>
					Both vTrader, and You are exonerated of liability for any total or partial failure in fulfilling or for the
					delayed fulfilment of the obligations deriving from the hereby Agreement as a result of a force majeure
					situation, as defined below. The force majeure is any external, unpredictable, invincible and inevitable
					event, which exonerates of liability the party invoking it, provided it notifies the other party in writing
					within 10 days from the occurrence of the force majeure event and based on the force majeure certificate
					issued by the Chamber of Trade and Industry of the Bucharest Municipality. If the force majeure event fails to
					cease within 30 days from its occurrence, either party is entitled to notify to the other party the
					termination by right of the hereby Agreement, without either of them claiming any damage interests.
				</p>
				<p className='terms-title-section'>14.8. Amendments to this Agreement</p>
				<p className='terms-text'>
					vTrader may amend or modify this Agreement at any time by posting on the vTrader Website and emailing to you
					the revised Agreement. The revised Agreement shall be effective 14 days after the posting date except where
					the changes and modifications are required by law or on the instruction of a competent governmental agency. By
					continuing to access or use the vTrader Services once the revised Agreement is effective, you agree to be
					bound by the Agreement. Should you not agree to be bound by the Agreement, you must discontinue use of your
					vTrader Account in the manner described in Section 5.3 and otherwise cease accessing or using the vTrader
					Services. You agree that we shall not be liable to you or any third party for any modification or termination
					of the vTrader Services, or suspension or termination of your access to the vTrader Services, except to the
					extent otherwise expressly set forth herein.
				</p>
				<p className='terms-title-section'>14.9.Entire Agreement</p>
				<p className='terms-text'>
					The failure of vTrader to exercise or enforce any right or provision of the Agreement shall not constitute a
					waiver of such right or provision. If any provision of this Agreement shall be adjudged by any court of
					competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the
					minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and remain
					enforceable between the parties. The parties agree that, after removing the void provision, it will be
					replaced with another provision, in the spirit of the hereby agreement, if it regards an essential element of
					the agreement.
				</p>
				<p className='terms-text'>
					The heading and explanatory text are for reference purposes only and in no way define, limit, construe, or
					describe the scope or extent of such section. This Agreement, including vTrader policies governing the
					Services referenced herein, the Privacy Policy, the Cookie Policy constitute the entire agreement between you
					and vTrader with respect to the use of the vTrader Services.
				</p>
				<p className='terms-text'>
					The hereby Agreement is not intended and shall not be construed to create any rights or remedies in any
					parties other than you and vTrader, and no other person shall assert any rights as a third party beneficiary
					hereunder.
				</p>
				<p className='terms-text'>
					The hereby Agreement, together with the Privacy Policy, the Policy regarding the cookies represents the entire
					agreement between the parties and supersedes any other previous, oral or written agreement.
				</p>
			</div>
		</div>
	);
};

export default TermsAndConditions;
