import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DashboardLayout from "../../layout/Dashboard/Layout";
import FeesTickersService from "../../services/api/common/fees-tickers-service/FeesTickersService";
import BittrexMarketOhlcv from "../../services/api/common/response/BittrexMarketOhlcv";
import BittrexMarketSummary from "../../services/api/common/response/BittrexMarketSummary";
import BittrexMarketSummaryService from "../../services/bittrex-market-summary/BittrexMarketSummaryService";
import UserService from "../../services/user/UserService";
import { formatNumber } from "../../services/utils/formatNumber";
import PriceChart from "../crypto-graph/PriceChart";
import WalletDetailsModal from "../wallet-details-modal/WalletDetailsModal";
import DefaultBittrexMarketSummary from "./DefaultBittrexMarketSummary";
import btcIcon from "./icons/icon_btc.png";
import ethIcon from "./icons/icon_eth.png";

const Dashboard = () => {
	const [ethSummary, setEthSummary] = useState<BittrexMarketSummary>(new DefaultBittrexMarketSummary());
	const [btcSummary, setBtcSummary] = useState<BittrexMarketSummary>(new DefaultBittrexMarketSummary());

	const [ethOhlcv, setEthOhlcv] = useState<Array<BittrexMarketOhlcv>>([]);
	const [btcOhlcv, setBtcOhlcv] = useState<Array<BittrexMarketOhlcv>>([]);

	const [isWalletDetailsModelOpen, setIsWalletDetailsModalOpen] = useState(false);

	const history = useHistory();

	const [usdtBalance, setUsdtBalance] = useState(0);
	const [eurUsdtEquivBalance, setEurUsdtEquivBalance] = useState(0);

	const feesTickerService: FeesTickersService = FeesTickersService.getInstance();
	const userService = UserService.getInstance();

	useEffect(() => {
		const fetchMarketData = () => {
			const bittrexMarketSummaryService = BittrexMarketSummaryService.getInstance();

			const btcSummary = bittrexMarketSummaryService.getMarketDetailedSummary("USDT-BTC");
			if (btcSummary) {
				setBtcSummary(btcSummary);
			}

			const ethSummary = bittrexMarketSummaryService.getMarketDetailedSummary("USDT-ETH");
			if (ethSummary) {
				setEthSummary(ethSummary);
			}

			const newEthOhlcv = bittrexMarketSummaryService.getMarketOhlcv("USDT-ETH");
			if (newEthOhlcv && ethOhlcv.length === 0) {
				setEthOhlcv(newEthOhlcv);
			}

			const newBtcOhlcv = bittrexMarketSummaryService.getMarketOhlcv("USDT-BTC");
			if (newBtcOhlcv && btcOhlcv.length === 0) {
				setBtcOhlcv(newBtcOhlcv);
			}

			const user = userService.getUser();
			if (user) {
				setUsdtBalance(user.wallets.USDT.balance);
				const usdtTicker = feesTickerService.getUsdtTicker();
				setEurUsdtEquivBalance(user.wallets.USDT.balance * usdtTicker.value);
			}
		};

		fetchMarketData();

		const intervalId = setInterval(fetchMarketData, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const handleTradeButtonClick = (coin: string) => {
		history.push(`/trade?selectedCoin=${coin}`);
	};

	const openWalletDetailsModel = () => {
		setIsWalletDetailsModalOpen(true);
	};

	return (
		<DashboardLayout>
			<h1 className='title'>Dashboard</h1>

			<div className='dashboard-container'>
				<div className='rectangle'></div>
				<p style={{ fontWeight: "bolder", marginTop: "30px" }} className='dashboard-wallet-text'>
					Wallet
				</p>

				<div className='dashboard-wallet-info'>
					<p className='dashboard-wallet-text'>Available USDT</p>
					<p style={{ textAlign: "right" }} className='dashboard-wallet-text'>
						${formatNumber(usdtBalance)}
					</p>
				</div>

				<div style={{ marginTop: "-20px" }} className='dashboard-wallet-info'>
					<p className='dashboard-wallet-text'>Available EUR (Equiv)</p>
					<p style={{ textAlign: "right" }} className='dashboard-wallet-text'>
						€{formatNumber(eurUsdtEquivBalance)}
					</p>
				</div>

				<p
					style={{
						textAlign: "right",
						textDecoration: "underline",
						marginBottom: "30px",
						cursor: "pointer",
					}}
					className='dashboard-wallet-text'
					onClick={openWalletDetailsModel}
				>
					Details
				</p>

				{isWalletDetailsModelOpen && <WalletDetailsModal closeModal={() => setIsWalletDetailsModalOpen(false)} />}

				<div className='rectangle'></div>

				<p style={{ fontWeight: "bolder", marginTop: "30px" }} className='dashboard-wallet-text'>
					Exchange Rates (Unit Price)
				</p>

				<div className='dashboard-wallet-info'>
					<div className='dashboard-coin-info'>
						<div style={{ display: "flex", alignItems: "center" }}>
							<img src={ethIcon} alt='ic' className='dashboard-coin-icon' />
						</div>
						<p className='dashboard-coin-wallet-text'>ETH</p>
					</div>
					<p style={{ textAlign: "right" }} className='dashboard-coin-wallet-text'>
						${formatNumber(ethSummary?.last)}
					</p>
				</div>

				{ethOhlcv.length > 0 && <PriceChart data={ethOhlcv.map((item) => [item.t, item.c])} />}

				<div className='dashboard-wallet-info' style={{ marginBottom: "-15px" }}>
					<p className='dashboard-summary-price-text'>
						1H&nbsp;&nbsp;
						<span style={{ color: ethSummary.percent_change_1h >= 0 ? "green" : "red" }}>
							{ethSummary.percent_change_1h >= 0
								? ` +${ethSummary.percent_change_1h.toFixed(2)}%`
								: ` ${ethSummary.percent_change_1h.toFixed(2)}%`}
						</span>
					</p>

					<p className='dashboard-summary-price-text'>
						Bid <span style={{ color: "green" }}>${formatNumber(ethSummary?.bid)}</span>
					</p>
					<button
						className='dashboard-summary-trade-button'
						onClick={() => {
							handleTradeButtonClick("ETH");
						}}
					>
						Trade
					</button>
				</div>

				<div className='dashboard-wallet-info' style={{ marginBottom: "-15px" }}>
					<p className='dashboard-summary-price-text'>
						24H&nbsp;
						<span style={{ color: ethSummary.percent_change_24h >= 0 ? "green" : "red" }}>
							{ethSummary.percent_change_24h >= 0
								? ` +${ethSummary.percent_change_24h.toFixed(2)}%`
								: ` ${ethSummary.percent_change_24h.toFixed(2)}%`}
						</span>
					</p>
					<p className='dashboard-summary-price-text'>
						Ask <span style={{ color: "green" }}>${formatNumber(ethSummary?.ask)}</span>
					</p>
					<button
						style={{ backgroundColor: "white", marginTop: "10px", cursor: "none" }}
						className='dashboard-summary-trade-button'
					>
						Trade
					</button>
				</div>

				<p className='dashboard-summary-price-text'>
					7D&nbsp;&nbsp;
					<span style={{ color: ethSummary.percent_change_7d >= 0 ? "green" : "red" }}>
						{ethSummary.percent_change_7d >= 0
							? ` +${ethSummary.percent_change_7d.toFixed(2)}%`
							: ` ${ethSummary.percent_change_7d.toFixed(2)}%`}
					</span>
				</p>

				<div className='rectangle' style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "30px" }}></div>

				<div className='dashboard-wallet-info'>
					<div className='dashboard-coin-info'>
						<div style={{ display: "flex", alignItems: "center" }}>
							<img src={btcIcon} alt='ic' className='dashboard-coin-icon' />
						</div>
						<p className='dashboard-coin-wallet-text'>XBT</p>
					</div>
					<p style={{ textAlign: "right" }} className='dashboard-coin-wallet-text'>
						${formatNumber(btcSummary?.last)}
					</p>
				</div>

				{btcOhlcv.length > 0 && <PriceChart data={btcOhlcv.map((item) => [item.t, item.c])} />}

				<div className='dashboard-wallet-info' style={{ marginBottom: "-15px" }}>
					<p className='dashboard-summary-price-text'>
						1H&nbsp;&nbsp;
						<span style={{ color: btcSummary.percent_change_1h >= 0 ? "green" : "red" }}>
							{btcSummary.percent_change_1h >= 0
								? ` +${btcSummary.percent_change_1h.toFixed(2)}%`
								: ` ${btcSummary.percent_change_1h.toFixed(2)}%`}
						</span>
					</p>

					<p className='dashboard-summary-price-text'>
						Bid <span style={{ color: "green" }}>${formatNumber(btcSummary?.bid)}</span>
					</p>
					<button
						className='dashboard-summary-trade-button'
						onClick={() => {
							handleTradeButtonClick("BTC");
						}}
					>
						Trade
					</button>
				</div>

				<div className='dashboard-wallet-info' style={{ marginBottom: "-15px" }}>
					<p className='dashboard-summary-price-text'>
						24H&nbsp;
						<span style={{ color: btcSummary.percent_change_24h >= 0 ? "green" : "red" }}>
							{btcSummary.percent_change_24h >= 0
								? ` +${btcSummary.percent_change_24h.toFixed(2)}%`
								: ` ${btcSummary.percent_change_24h.toFixed(2)}%`}
						</span>
					</p>
					<p className='dashboard-summary-price-text' style={{ paddingLeft: "7px" }}>
						Ask <span style={{ color: "green" }}>${formatNumber(btcSummary?.ask)}</span>
					</p>
					<button
						style={{ backgroundColor: "white", marginTop: "10px", cursor: "none" }}
						className='dashboard-summary-trade-button'
					>
						hidden
					</button>
				</div>

				<p className='dashboard-summary-price-text'>
					7D&nbsp;&nbsp;
					<span style={{ color: btcSummary.percent_change_7d >= 0 ? "green" : "red" }}>
						{btcSummary.percent_change_7d >= 0
							? ` +${btcSummary.percent_change_7d.toFixed(2)}%`
							: ` ${btcSummary.percent_change_7d.toFixed(2)}%`}
					</span>
				</p>
			</div>
			<div style={{ marginBottom: "40px" }}></div>
		</DashboardLayout>
	);
};

export default Dashboard;
