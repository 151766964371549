import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialStateProps = {
	id?: number;
	enabled?: boolean;
	language?: string;
	publicIdentifier?: string;
	username?: string;
	tierZeroInfo?: {
		countryId: number;
		email: string;
		firstName: string;
		lastName: string;
		phoneNumber: string;
		phonePrefix: string;
		receiverAdministrativeEmails: boolean;
		receiverNewsletterEmails: boolean;
		username: string;
	};
	tierOneInfo?: {
		address: string;
		birthdate: string;
		city: string;
		extraInfo: Record<string, any>;
		files: any[];
		postalCode: string;
		state: string;
	};
	tierTwoInfo?: {
		activityField: string;
		employeeStatus: string;
		employerName: string;
		files: any[];
		fiscalResidence: string;
		isEmployerListedOnStockExchange: boolean;
		isEmployerStateInstitution: boolean;
		isUserPublicPerson: boolean;
		moneySource: string;
		purposeOfTransaction: string;
		residence: string;
	};
};

const initialState: InitialStateProps = {};

export const accountSlice = createSlice({
	name: "account",
	initialState,
	reducers: {
		updateAccount(state, action: PayloadAction<any>) {
			if (state) {
				state = action.payload;
			}
			return state;
		},
	},
});

export const { updateAccount } = accountSlice.actions;

export default accountSlice.reducer;
