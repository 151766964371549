//node_modules
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import accountSlice from "./slices/account.slice";
import authSlice from "./slices/auth.slice";

const persistConfig = {
	key: "root",
	storage: storage,
};

const reducer = combineReducers({
	accountSlice,
	authSlice,
});

export const makeStore = () => {
	const persistedReducer = persistReducer(persistConfig, reducer);
	const store = configureStore({
		reducer: persistedReducer,
	});
	return store;
};

export const store = makeStore();
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
