import { api } from "../../api";
import HttpClient from "../../HttpClient";
import { Fees } from "../response/Fees";
import NgnUsdtRate from "../response/NgnUsdtRate";
import UsdtTicker from "../response/UsdtTicker";

class FeesTickersService {
	static emptyUsdtTicker: UsdtTicker = {
		value: 0,
		buyFee: 0,
		sellFee: 0,
	};

	static emptyNgnUsdtRate: NgnUsdtRate = {
		buy: 0,
		sell: 0,
	};

	private static instance: FeesTickersService | null = null;

	private httpClient: HttpClient = new HttpClient();

	private usdtTicker: UsdtTicker = FeesTickersService.emptyUsdtTicker;
	private ngnUsdtRate: NgnUsdtRate = FeesTickersService.emptyNgnUsdtRate;
	private fees: Fees | null = null;
	private minTradeValueInSatoshi: Record<string, any> = {};

	private constructor() {}

	static getInstance(): FeesTickersService {
		if (!FeesTickersService.instance) {
			FeesTickersService.instance = new FeesTickersService();
		}
		return FeesTickersService.instance;
	}

	getUsdtTicker() {
		return this.usdtTicker;
	}

	getNgnUsdtRate() {
		return this.ngnUsdtRate;
	}

	getFees() {
		return this.fees;
	}

	getMinTradeValueInSatoshi() {
		return this.minTradeValueInSatoshi;
	}

	private async fetchUsdtTicker() {
		try {
			const response = await this.httpClient.get<UsdtTicker>(api.common.USDT_TICKER);
			this.usdtTicker = response.data;
		} catch (error) {
			// Handle errors
			console.log("Error", error);
		}
	}

	private async fetchNgnUsdtRate() {
		try {
			const response = await this.httpClient.get<NgnUsdtRate>(api.common.NGN_USDT_RATE);
			this.ngnUsdtRate = response.data;
		} catch (error) {
			// Handle errors
			console.log("Error", error);
		}
	}

	private async fetchFees() {
		try {
			const response = await this.httpClient.get<Fees>(api.common.FEES);
			this.fees = response.data;
		} catch (error) {
			// Handle errors
			console.log("Error", error);
		}
	}

	private async fetchMinTradeValueInSatoshi() {
		try {
			const response = await this.httpClient.get<any>(api.common.MIN_TRADE_VALUE_IN_SATOSHI);
			this.minTradeValueInSatoshi = response.data;
		} catch (error) {
			// Handle errors
			console.log("Error", error);
		}
	}

	startFetchingInterval(intervalInMilliseconds: number = 15 * 60 * 1000) {
		this.fetchUsdtTicker().then(() => {});
		this.fetchNgnUsdtRate().then(() => {});

		setInterval(() => {
			this.fetchUsdtTicker().then(() => {});
			this.fetchNgnUsdtRate().then(() => {});
		}, intervalInMilliseconds);
	}

	fetchFeesAndMinTradeValueInSatoshi() {
		this.fetchFees().then(() => {});
		this.fetchMinTradeValueInSatoshi().then(() => {});
	}
}

export default FeesTickersService;
