import "../../layout/Dashboard/layout.css";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
	return (
		<div>
			<h1 className='title'>Privacy Policy</h1>
			<div className='dashboard-container' style={{ marginBottom: "50px" }}>
				<div className='deposit-titles' style={{ gap: "25px" }}>
					<Link to='/terms-and-conditions' className='deposit-inactive-tab'>
						<p>Terms & Conditions</p>
					</Link>
					<Link to='/privacy-policy' className='deposit-active-tab'>
						<p>Privacy Policy</p>
					</Link>
					<Link to='/cookie-policy' className='deposit-inactive-tab'>
						<p>Cookie Policy</p>
					</Link>
					<Link to='/aml-policy' className='deposit-inactive-tab'>
						<p>AML Policy</p>
					</Link>
					<Link to='/refund-policy' className='deposit-inactive-tab'>
						<p>Refund Policy</p>
					</Link>
				</div>
				<p className='terms-title-section' style={{ marginTop: "50px" }}>
					1. Introduction
				</p>
				<p className='terms-text'>
					This privacy notice outlines how VTRADER processes personal data for its clients, potential clients, and
					website visitors. Your privacy is a top priority for us, and we are committed to protecting the
					confidentiality of your information. <br />
					<br /> Below you will find details on how we manage personal data and your rights regarding its processing.
				</p>

				<p className='terms-title-section'>2. Definitions</p>
				<ul style={{ listStyle: "outside", marginLeft: "16px" }}>
					<li className='terms-text'>
						<b>2.1 “AML”</b>: Anti-money laundering.
					</li>
					<li className='terms-text'>
						<b>2.2 “Digital Asset”</b>: Any digital representation of value that can be traded using VTRADER’s services,
						excluding non-fungible tokens.
					</li>
					<li className='terms-text'>
						<b>2.3 “VTRADER,” “We,” “Us”</b>: Refers collectively to VTRADER Inc. and its subsidiaries.
					</li>
					<li className='terms-text'>
						<b>2.4 “Personal Data”</b>: Any information related to an identified or identifiable individual, including
						names, identification numbers, location data, online identifiers, or factors specific to an individual’s
						physical, economic, cultural, or social identity.
					</li>
				</ul>
				<p className='terms-title-section'>3. Your Data Controller</p>
				<p className='terms-text'>
					Our products and services are delivered through local entities within the VTRADER group. You are entering into
					a contract with VTRADER as detailed in our Terms of Service. The company you are contracting with serves as
					your Data Controller, responsible for the collection, use, disclosure, retention, and protection of your
					personal data according to our global privacy standards, this Privacy Notice, and applicable national laws.
				</p>

				<p className='terms-title-section'>4. How Do We Protect Personal Data?</p>
				<p className='terms-text'>
					At VTRADER, we prioritize the security of personal data. For details on our information security practices,
					please refer to our information security page.
				</p>

				<p className='terms-title-section'>5. Information We May Collect About You</p>
				<p className='terms-text'>
					We collect information through various means when you use our services, including our websites, account
					registration, webinar sign-ups, event subscriptions, news updates, and ongoing support communications. <br />
					<br />
					To open an account, you must complete a &quot;create account&quot; form with required personal data to help
					VTRADER assess your application and comply with relevant laws.
				</p>

				<p className='terms-title-section'>Required information includes:</p>

				<ul style={{ listStyle: "outside", marginLeft: "16px" }}>
					<li className='terms-text'>
						<b>Biographical Information:</b> Full name, residential address, contact details (email, phone), date and
						place of birth, gender, and citizenship.
					</li>
					<li className='terms-text'>
						<b>Financial Information:</b> Bank account details, wallet addresses, credit card information, source of
						funds, assets, liabilities, and information related to economic and trade sanctions lists.
					</li>
					<li className='terms-text'>
						<b>Trading Information:</b> Trading account balances, activity, inquiries, and responses.
					</li>
					<li className='terms-text'>
						<b>PEP Information:</b> Whether you or someone close to you holds a prominent public position.
					</li>
					<li className='terms-text'>
						<b>Verification Information:</b> Documents to verify your identity, such as passport, driver’s license,
						selfie photos/videos, or government-issued ID.
					</li>
					<li className='terms-text'>
						<b>Other Information:</b> Any other data necessary for AML compliance and legal obligations.
					</li>
				</ul>

				<p className='terms-title-section'>Automatically Collected Information:</p>
				<ul style={{ listStyle: "outside", marginLeft: "16px" }}>
					<li className='terms-text'>
						<b>Browser Information:</b> IP address, domain name, referrer, login details, browser type and version, time
						zone setting, and other details.
					</li>
					<li className='terms-text'>
						<b>Log Information:</b> Device details, activity logs, location information, and user interaction data.
					</li>
				</ul>

				<p className='terms-title-section'>Information from Other Sources:</p>
				<p className='terms-text'>
					We may receive data from third parties such as payment providers, business partners, and public sources. This
					includes basic personal and financial data, fraud and security information, and credit reference data.
				</p>

				<p className='terms-title-section'>6. Legal Justifications for Processing Personal Data</p>
				<p className='terms-text'>We process your personal data based on various legal grounds:</p>
				<ul style={{ listStyle: "outside", marginLeft: "16px" }}>
					<li className='terms-text'>
						Performance of a Contract: To provide services, process payments, and complete client onboarding.
					</li>
					<li className='terms-text'>
						Legal Obligations: To comply with laws such as AML regulations and identity verification.
					</li>
					<li className='terms-text'>
						Legitimate Interests: For business operations, product improvement, marketing, and fraud prevention.
					</li>
					<li className='terms-text'>Consent: For marketing, surveys, and certain data customizations.</li>
				</ul>

				<p className='terms-title-section'>7. Disclosure of Personal Data</p>
				<p className='terms-text'>
					We may disclose your personal data to VTRADER group companies and third parties such as service providers,
					business partners, and legal entities when required. We ensure these parties protect your data and comply with
					relevant privacy laws.
				</p>

				<p className='terms-title-section'>8. Where We Store Your Personal Data</p>
				<p className='terms-text'>
					Your personal data is stored and processed globally, including in the European Union, Japan, Australia, the
					United Kingdom, and the United States. Local laws may allow authorities to access your data.
				</p>

				<p className='terms-title-section'>9. Transfers Outside the European Economic Area (“EEA”)</p>
				<p className='terms-text'>
					We may transfer personal data outside the EEA using lawful mechanisms. For transfers to the US, we may use
					standard contractual clauses.
				</p>

				<p className='terms-title-section'>10. Privacy and Digital Assets</p>
				<p className='terms-text'>
					Your use of digital assets on public blockchains may be recorded and subject to forensic analysis. VTRADER
					cannot modify or delete data on these networks.
				</p>

				<p className='terms-title-section'>11. Data Retention</p>
				<p className='terms-text'>
					We retain personal data for as long as necessary for our business purposes or as required by law, including
					for managing disputes, legal claims, and AML compliance. We may also keep your data for longer if required for
					legal, regulatory, or technical reasons.
				</p>

				<p className='terms-title-section'>12. Cookies</p>
				<p className='terms-text'>
					Cookies are used to enhance your web experience. You can adjust your cookie settings or disable them, though
					this may affect website functionality. For more details, visit our Cookie Settings page.
				</p>

				<p className='terms-title-section'>13. Your Rights Regarding Personal Data</p>
				<p className='terms-text'>You have various rights regarding your personal data, including:</p>
				<ul style={{ listStyle: "outside", marginLeft: "16px" }}>
					<li className='terms-text'>
						<b>Access:</b> Request information on how we process your data.
					</li>
					<li className='terms-text'>
						<b>Rectification:</b> Update inaccurate or incomplete data.
					</li>
					<li className='terms-text'>
						<b>Erasure:</b> Request deletion of data under certain conditions.
					</li>
					<li className='terms-text'>
						<b>Processing Restrictions:</b> Limit data processing in specific situations.
					</li>
					<li className='terms-text'>
						<b>Data Portability:</b> Obtain and transfer your data in a structured format.
					</li>
					<li className='terms-text'>
						<b>Objection:</b> Object to data processing based on legitimate interests or direct marketing.
					</li>
					<li className='terms-text'>
						<b>Automated Decision-Making:</b> Request human intervention if decisions are based solely on automated
						processes.
					</li>
				</ul>
				<p className='terms-text'>For any of these requests, please contact us at support@vtrader.com.</p>

				<p className='terms-title-section'>14. Changes to This Privacy Notice</p>
				<p className='terms-text'>
					We review and update our privacy notice regularly. Any changes will be reflected with an updated “Last
					Updated” date. Please check this notice periodically and review announcements of material changes on our
					website.
				</p>

				<p className='terms-title-section'>15. Children’s Privacy</p>
				<p className='terms-text'>
					Our services are not intended for individuals under 18. We do not knowingly collect data from children. If we
					learn that we have collected data from a child, we will take steps to delete it and close the account.
				</p>

				<p className='terms-title-section'>16. Mobile Data</p>
				<p className='terms-text'>
					No mobile information will be shared with third parties/affiliates for marketing/promotional purposes.
					Information sharing to subcontractors in support services, such as customer service is permitted. All other
					use case categories exclude text messaging originator opt-in data and consent; this information will not be
					shared with any third parties.
				</p>

				<p className='terms-title-section'>17. Contact Information</p>
				<p className='terms-text'>
					For questions or concerns regarding this privacy notice, please contact us at office@vtrader.com or reach out
					to our Data Protection Officer.
				</p>

				<p className='terms-title-section'>USA Office:</p>
				<p className='terms-text'>office @vtrader.io</p>
				<p className='terms-text'>1650 Market street, suite 3600, Philadelphia, Pennsylvania 19103</p>

				<p className='terms-title-section'>EEA Office:</p>
				<p className='terms-text'>office @vtrader.io</p>
				<p className='terms-text'>
					vTrader headquarters in Bucharest: 6th District, 279 Prelungirea Ghencea St., 5th Floor
				</p>
				<p className='terms-text'>
					Trade Register under no. J40 / 8628 / 18.06.2018, with identification code 39501244
				</p>

				<p className='terms-title-section'>18. Data Protection Authorities</p>
				<p className='terms-text'>
					If you are dissatisfied with our response, you may lodge a complaint with a data protection authority.
					Relevant authorities include:
				</p>
				<ul style={{ listStyle: "outside", marginLeft: "16px" }}>
					<li className='terms-text'>
						<b>European Economic Area:</b> Your local supervisory authority
					</li>
					<li className='terms-text'>
						<b>US:</b> The Federal Trade Commision
					</li>
				</ul>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
