import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const customStyles = {
	content: {
		width: "320px",
		height: "290px",
		margin: "auto",
		top: "50%",
		left: "50%",
		right: "50%",
		transform: "translate(-50%, -50%)",
		padding: "20px",
		border: "1px solid #000", // Thin black border
		boxShadow: "none", // No shadow
	},
};

interface ConfirmTradeModalProps {
	closeModal: () => void;
	placeOrder: () => Promise<void>;
	confirmCancel: () => void;

	orderType: string;
	price: string;
	totalDebit: string;
	totalCredit: string;
}

Modal.setAppElement("#root");

const ConfirmTradeModal: React.FC<ConfirmTradeModalProps> = ({
	closeModal,
	placeOrder,
	confirmCancel,
	orderType,
	price,
	totalDebit,
	totalCredit,
}) => {
	const [scrollDisabled] = useState(false);

	const [isPlacingOrder, setIsPlacingOrder] = useState(false);

	useEffect(() => {
		if (!scrollDisabled) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [scrollDisabled]);

	const handlePlaceOrder = async () => {
		setIsPlacingOrder(true);
		try {
			await placeOrder();
		} catch (error) {
			// Handle errors if necessary
			console.log("Error", error);
		} finally {
			setIsPlacingOrder(false);
			closeModal();
		}
	};

	return (
		<div>
			<Modal isOpen={true} onRequestClose={closeModal} style={customStyles} shouldCloseOnOverlayClick={false}>
				{isPlacingOrder ? (
					<>
						<p style={{ fontSize: "12px", textAlign: "center", paddingTop: "80px" }}>Placing order...</p>
						<div className='loading-spinner'></div>
					</>
				) : (
					<>
						<p style={{ textAlign: "center", fontSize: "15px" }}>Order Summary (Confirmation)</p>
						<div
							className='rectangle'
							style={{ backgroundColor: "#DDD", marginBottom: "15px", marginTop: "15px" }}
						></div>

						<div className='confirm-trade-modal-container'>
							<div className='confirm-trade-modal-row'>
								<p className='confirm-trade-modal-row-text'>Order Type</p>
								<p className='confirm-trade-modal-row-text'>{orderType}</p>
							</div>
							<div className='confirm-trade-modal-row'>
								<p className='confirm-trade-modal-row-text'>Price</p>
								<p className='confirm-trade-modal-row-text'>{price}</p>
							</div>
							<div className='confirm-trade-modal-row'>
								<p className='confirm-trade-modal-row-text'>Total Debit</p>
								<p className='confirm-trade-modal-row-text'>{totalDebit}</p>
							</div>
							<div className='confirm-trade-modal-row'>
								<p className='confirm-trade-modal-row-text'>Total Credit</p>
								<p className='confirm-trade-modal-row-text'>{totalCredit}</p>
							</div>
						</div>

						<div
							className='rectangle'
							style={{ backgroundColor: "#DDD", marginBottom: "25px", marginTop: "15px" }}
						></div>

						<div className='setting-section-two-factor-buttons-container'>
							<button
								className='setting-section-two-factor-button-disabled'
								style={{ cursor: "pointer" }}
								onClick={() => {
									confirmCancel();
									closeModal();
								}}
							>
								CANCEL
							</button>
							<button
								className='setting-section-two-factor-button-enabled'
								style={{ cursor: "pointer" }}
								onClick={handlePlaceOrder}
							>
								PLACE ORDER
							</button>
						</div>
					</>
				)}
			</Modal>
		</div>
	);
};

export default ConfirmTradeModal;
