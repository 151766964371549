import "../../layout/Dashboard/layout.css";

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { coinData } from "src/constants/coin";
import DashboardLayout from "src/layout/Dashboard/Layout";

import FeesTickersService from "../../services/api/common/fees-tickers-service/FeesTickersService";
import JwtUser from "../../services/api/common/jwtUser/JwtUser";
import BittrexMarketSummary from "../../services/api/common/response/BittrexMarketSummary";
import { Fees } from "../../services/api/common/response/Fees";
import { PlaceOrderRequestViewModel } from "../../services/api/web/request/PlaceOrderRequestViewModel";
import BittrexMarketSummaryService from "../../services/bittrex-market-summary/BittrexMarketSummaryService";
import UserService from "../../services/user/UserService";
import { getCurrencySymbol } from "../../services/utils/cryptoSymbols";
import { formatNumber } from "../../services/utils/formatNumber";
import { showErrorToast, showSuccessToast } from "../../services/utils/toastUtils";
import TradeViewChart from "../crypto-graph/TradeViewChart";
import ConfirmTradeModal from "./ConfirmTradeModal";
const Trade = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const coinSelectedParam = queryParams.get("selectedCoin") || "BTC";

	const [coinSelected, setCoinSelected] = useState(coinSelectedParam);

	const [detailedMarketSummaries, setDetailedMarketSummaries] = useState<Array<BittrexMarketSummary> | null>([]);
	const [coinBid, setCoinBid] = useState(0);
	const [coinAsk, setCoinAsk] = useState(0);
	const [coinLast, setCoinLast] = useState(0);
	const [coinChange1Hour, setCoinChange1Hour] = useState("");
	const [coinChange1Day, setCoinChange1Day] = useState("");
	const [coinChange7Days, setCoinChange7Days] = useState("");

	const [fees, setFees] = useState<Fees | null>(null);

	const [buyFee, setBuyFee] = useState(0);
	const [sellFee, setSellFee] = useState(0);

	const [linesOfCoins, setLinesOfCoins] = useState<any>([]);
	const screenWidth = window.outerWidth;

	const feesTickerService: FeesTickersService = FeesTickersService.getInstance();
	const userService = UserService.getInstance();
	const marketSummaryService = BittrexMarketSummaryService.getInstance();

	const [user, setUser] = useState<JwtUser | null>(null);

	const [buyPrice, setBuyPrice] = useState("");
	const [buyAmount, setBuyAmount] = useState("");
	const [buyTotal, setBuyTotal] = useState("");
	const [canPlaceBuyOrder, setCanPlaceBuyOrder] = useState(false);

	const [buyOrderAmountValidation, setBuyOrderAmountValidation] = useState("");
	const [buyOrderTotalAmountValidation, setBuyOrderTotalAmountValidation] = useState("");

	const [sellPrice, setSellPrice] = useState("");
	const [sellAmount, setSellAmount] = useState("");
	const [sellTotal, setSellTotal] = useState("");
	const [canPlaceSellOrder, setCanPlaceSellOrder] = useState(false);
	const [sellOrderAmountValidation, setSellOrderAmountValidation] = useState("");
	const [sellOrderTotalAmountValidation, setSellOrderTotalAmountValidation] = useState("");

	const [isPlaceOrderModalOpen, setPlaceOrderModalOpen] = useState(false);

	const [modalPropOrderType, setModalPropOrderType] = useState("");
	const [modalPropPrice, setModalPropPrice] = useState("");
	const [modalPropTotalCredit, setModalPropTotalCredit] = useState("");
	const [modalPropTotalDebit, setModalPropTotalDebit] = useState("");

	useEffect(() => {
		const user = userService.getUser();
		if (user) {
			setUser(user);
		}

		const fees = feesTickerService.getFees();
		if (fees) {
			setFees(fees);
		}
	}, []); // Empty dependency array means the effect runs only once

	useEffect(() => {
		// Calculate the number of lines and coins per line based on screen width
		let numLines, coinsPerLine;
		if (screenWidth >= 600) {
			numLines = 3;
			coinsPerLine = Math.ceil(coinData.length / numLines);
		} else {
			numLines = 6;
			coinsPerLine = 4;
		}

		// Generate lines with coins
		const linesArray = [];
		for (let i = 0; i < numLines; i++) {
			const startIdx = i * coinsPerLine;
			const endIdx = startIdx + coinsPerLine;
			const lineCoins = coinData.slice(startIdx, endIdx);

			linesArray.push(
				<div className='trade-coin-container-line' key={i}>
					{lineCoins.map((coin) => (
						<div
							key={coin.name}
							className={`trade-container-coin`}
							style={{
								borderRadius: coinSelected === coin.name ? "5px" : "inherit",
								border: coinSelected === coin.name ? "1px solid black" : "inherit",
							}}
							onClick={() => setCoinSelected(coin.name)}
						>
							<span className='trade-container-coin-text'>{coin.name}</span>
							<img src={coin.icon} alt='ic' className='trade-icon' />
						</div>
					))}
					{i === numLines - 1 && (
						<div className='trade-container-coin' style={{ cursor: "default" }}>
							<span className='trade-container-coin-text' style={{ display: "none" }}>
								none
							</span>
							<img src={""} alt='ic' className='trade-icon' style={{ display: "none" }} />
						</div>
					)}
				</div>
			);
		}
		setLinesOfCoins(linesArray);
	}, [coinSelected]);

	useEffect(() => {
		const fetchMarketData = () => {
			const summaries = marketSummaryService.getMarketDetailedSummaries();
			setDetailedMarketSummaries(summaries);
		};

		fetchMarketData();

		const intervalId = setInterval(fetchMarketData, 1000);

		resetForm();

		return () => {
			clearInterval(intervalId);
		};
	}, [coinSelected]);

	useEffect(() => {
		const marketSummary = detailedMarketSummaries?.find((summary) => summary.market === "USDT-" + coinSelected);
		if (marketSummary) {
			setCoinAsk(marketSummary.ask);
			setCoinBid(marketSummary.bid);
			setCoinLast(marketSummary.last);
			setCoinChange1Hour(
				marketSummary.percent_change_1h >= 0
					? "+" + formatNumber(marketSummary.percent_change_1h).toString()
					: formatNumber(marketSummary.percent_change_1h).toString()
			);
			setCoinChange1Day(
				marketSummary.percent_change_24h >= 0
					? "+" + formatNumber(marketSummary.percent_change_24h).toString()
					: formatNumber(marketSummary.percent_change_24h).toString()
			);
			setCoinChange7Days(
				marketSummary.percent_change_7d >= 0
					? "+" + formatNumber(marketSummary.percent_change_7d).toString()
					: formatNumber(marketSummary.percent_change_7d).toString()
			);
		}

		if (fees) {
			setBuyFee(fees.currencies[coinSelected].orderBuy);
			setSellFee(fees.currencies[coinSelected].orderSell);
		}
	}, [coinSelected, detailedMarketSummaries]);

	const resetForm = () => {
		setBuyTotal("");
		setBuyPrice("");
		setBuyAmount("");
		setBuyOrderAmountValidation("");
		setBuyOrderTotalAmountValidation("");
		setCanPlaceBuyOrder(false);

		setSellTotal("");
		setSellPrice("");
		setSellAmount("");
		setSellOrderAmountValidation("");
		setSellOrderTotalAmountValidation("");
		setCanPlaceSellOrder(false);

		setModalPropOrderType("");
		setModalPropPrice("");
		setModalPropTotalDebit("");
		setModalPropTotalCredit("");
	};

	const handleBuyPriceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBuyPrice(event.target.value);
	};

	const handleBuyAmountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBuyAmount(event.target.value);
	};

	const handleBuyTotalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBuyTotal(event.target.value);
	};

	const handleSellPriceChanged = (event: any) => {
		setSellPrice(event.target.value);
	};

	const handleSellAmountChanged = (event: any) => {
		setSellAmount(event.target.value);
	};

	const handleSellTotalChanged = (event: any) => {
		setSellTotal(event.target.value);
	};

	const calculateTotal = (price: number, amount: number) => {
		const total = Math.floor(price * amount * 1e6) / 1e6; // Round to 6 decimals
		return total.toString();
	};

	const calculateAmount = (price: number, total: number) => {
		const amount = Math.floor((total / price) * 1e6) / 1e6; // Round to 6 decimals
		return amount.toString();
	};

	const calculatePrice = (total: number, amount: number) => {
		const price = Math.floor((total / amount) * 1e6) / 1e6; // Round to 6 decimals
		return price.toString();
	};

	const hasThisOrderMinTradeVolume = (usdtPrice: number, cryptoAmount: number): boolean => {
		const amountInUsdt = Math.ceil(usdtPrice * cryptoAmount * 1e6) / 1e6;
		const oneBtcInUsdt = marketSummaryService.getMarketDetailedSummary("USDT-BTC")?.last || 0;
		const amountInBtc = Math.ceil((amountInUsdt / oneBtcInUsdt) * 1e6) / 1e6;
		const amountInSatoshi: number = 100000000 * amountInBtc;
		return amountInSatoshi > feesTickerService.getMinTradeValueInSatoshi().minTradeValueInSatoshi;
	};

	const getMinCryptoAmountToMeetMinTradeVolume = (): number => {
		const SATOSHI_MULTIPLIER: number = 100000000;
		const minTradeValueInSatoshi: number = feesTickerService.getMinTradeValueInSatoshi().minTradeValueInSatoshi + 1500;
		const minBtcAmount: number = minTradeValueInSatoshi / SATOSHI_MULTIPLIER;
		const oneBtcInUsdt: number = marketSummaryService.getMarketDetailedSummary("USDT-BTC")?.last || 0;

		const minAmountInUsdt = minBtcAmount * oneBtcInUsdt;
		const oneSelectedCoinInUsdt = marketSummaryService.getMarketDetailedSummary("USDT-" + coinSelected)?.last || 0;
		return Math.ceil((minAmountInUsdt / oneSelectedCoinInUsdt) * 1e6) / 1e6;
	};

	useEffect(() => {
		if (buyPrice !== "" && buyAmount !== "") {
			const newBuyTotal = calculateTotal(parseFloat(buyPrice), parseFloat(buyAmount));
			if (!isNaN(parseFloat(newBuyTotal))) {
				setBuyTotal(newBuyTotal);
			}
		} else if (buyPrice !== "" && buyTotal !== "") {
			const newBuyAmount = calculateAmount(parseFloat(buyPrice), parseFloat(buyTotal));
			if (!isNaN(parseFloat(newBuyAmount))) {
				setBuyAmount(newBuyAmount);
			}
		} else if (buyAmount !== "" && buyTotal !== "") {
			const newBuyPrice = calculatePrice(parseFloat(buyTotal), parseFloat(buyAmount));
			if (!isNaN(parseFloat(newBuyPrice))) {
				setBuyPrice(newBuyPrice);
			}
		}

		setBuyOrderAmountValidation("");
		setBuyOrderTotalAmountValidation("");

		if (!isNaN(parseFloat(buyTotal))) {
			if (parseFloat(buyTotal) > user?.wallets.USDT.balance) {
				setBuyOrderTotalAmountValidation("Insufficient funds.");
				setCanPlaceBuyOrder(false);
				return;
			}
		}

		if (!isNaN(parseFloat(buyPrice)) && !isNaN(parseFloat(buyAmount))) {
			if (!hasThisOrderMinTradeVolume(parseFloat(buyPrice), parseFloat(buyAmount))) {
				setBuyOrderAmountValidation("Minimum Amount must be greater than ");
				setCanPlaceBuyOrder(false);
				return;
			}
		}

		setCanPlaceBuyOrder(parseFloat(buyPrice) > 0 && parseFloat(buyAmount) > 0 && parseFloat(buyTotal) > 0);
	}, [buyPrice, buyAmount, buyTotal]);

	useEffect(() => {
		if (sellPrice !== "" && sellAmount !== "") {
			const newSellTotal = calculateTotal(parseFloat(sellPrice), parseFloat(sellAmount));
			if (!isNaN(parseFloat(newSellTotal))) {
				setSellTotal(newSellTotal);
			}
		} else if (sellPrice !== "" && sellTotal !== "") {
			const newSellAmount = calculateAmount(parseFloat(sellPrice), parseFloat(sellTotal));
			if (!isNaN(parseFloat(newSellAmount))) {
				setSellAmount(newSellAmount);
			}
		} else if (sellAmount !== "" && sellTotal !== "") {
			const newSellPrice = calculatePrice(parseFloat(sellTotal), parseFloat(sellAmount));
			if (!isNaN(parseFloat(newSellPrice))) {
				setSellPrice(newSellPrice);
			}
		}

		setSellOrderAmountValidation("");
		setSellOrderTotalAmountValidation("");

		if (!isNaN(parseFloat(sellAmount))) {
			if (parseFloat(sellAmount) > user?.wallets[coinSelected].balance) {
				setSellOrderAmountValidation("Insufficient funds.");
				setCanPlaceSellOrder(false);
				return;
			}
		}

		if (!isNaN(parseFloat(sellPrice)) && !isNaN(parseFloat(sellAmount))) {
			if (!hasThisOrderMinTradeVolume(parseFloat(sellPrice), parseFloat(sellAmount))) {
				setSellOrderTotalAmountValidation("Minimum Amount must be greater than ");
				setCanPlaceSellOrder(false);
				return;
			}
		}

		setCanPlaceSellOrder(parseFloat(sellPrice) > 0 && parseFloat(sellAmount) > 0 && parseFloat(sellTotal) > 0);
	}, [sellPrice, sellAmount, sellTotal]);

	const placeBuyOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!canPlaceBuyOrder) {
			return;
		}

		event.preventDefault();

		setModalPropOrderType("BUY");
		setModalPropPrice(getCurrencySymbol(coinSelected) + "1 = " + getCurrencySymbol("USDT") + buyPrice);
		setModalPropTotalDebit(getCurrencySymbol("USDT") + buyTotal);

		const amountAfterFees = (parseFloat(buyAmount) * ((100 - buyFee) / 100)).toFixed(6);
		const feeAmount = (parseFloat(buyAmount) * (buyFee / 100)).toFixed(6);
		setModalPropTotalCredit(
			getCurrencySymbol(coinSelected) + amountAfterFees + " (" + getCurrencySymbol(coinSelected) + feeAmount + " Fee)"
		);

		setPlaceOrderModalOpen(true);
	};

	const placeSellOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!canPlaceSellOrder) {
			return;
		}

		event.preventDefault();

		setModalPropOrderType("SELL");
		setModalPropPrice(getCurrencySymbol(coinSelected) + "1 = " + getCurrencySymbol("USDT") + sellPrice);
		setModalPropTotalDebit(getCurrencySymbol(coinSelected) + sellAmount);

		const amountAfterFees = (parseFloat(sellAmount) * parseFloat(sellPrice) * ((100 - sellFee) / 100)).toFixed(6);
		const feeAmount = (parseFloat(sellAmount) * parseFloat(sellPrice) * (sellFee / 100)).toFixed(6);
		setModalPropTotalCredit(
			getCurrencySymbol("USDT") + amountAfterFees + " (" + getCurrencySymbol("USDT") + feeAmount + " Fee)"
		);

		setPlaceOrderModalOpen(true);
	};

	const submitOrder = async () => {
		const placeOrderRequestViewModel: PlaceOrderRequestViewModel = {
			asset: coinSelected,
			market: "USDT",
			quantity: modalPropOrderType === "BUY" ? parseFloat(buyAmount) : parseFloat(sellAmount),
			rate: modalPropOrderType === "BUY" ? parseFloat(buyPrice) : parseFloat(sellPrice),
			type: modalPropOrderType === "BUY" ? "LIMIT_BUY" : "LIMIT_SELL",
		};

		const response = await userService.placeOrder(placeOrderRequestViewModel);

		if (response && response.status === 200) {
			userService.setUser((response?.data as any)?.user);
			setUser(userService.getUser());

			userService.addOrder((response?.data as any)?.order);

			resetForm();

			showSuccessToast(toast, "Your order has been successfully submitted.");
		} else {
			showErrorToast(toast, displayResponseErrorMessage((response?.data as any)?.message));
		}
	};

	const displayResponseErrorMessage = (message: string) => {
		const defaultMessage =
			"An error occurred while submitting your order. Please try again later. If the problem persists, contact our staff.";

		if (!message) {
			return defaultMessage;
		}

		if (message === "ORDER_DISABLED") {
			return `Trading for USDT-${coinSelected} pair is currently disabled`;
		}

		if (message === "MIN_TRADE_VALUE_NOT_MET") {
			return `Minimum order volume is not met`;
		}

		if (message === "TOO_MANY_OPEN_ORDERS") {
			return `Cannot have more than 10 open orders`;
		}

		return defaultMessage;
	};

	return (
		<DashboardLayout>
			<h1 className='title'>Trade</h1>

			<div className='dashboard-container'>
				{/* <h3 style={{ textAlign: "center" }}>Temporary Trading Suspension</h3> */}
				{/* <h4 style={{ textAlign: "center" }}>We are diligently addressing this matter.</h4> */}

				<div className='trade-coin-container'>{linesOfCoins}</div>

				{isPlaceOrderModalOpen && (
					<ConfirmTradeModal
						closeModal={() => setPlaceOrderModalOpen(false)}
						confirmCancel={() => {}}
						placeOrder={submitOrder}
						orderType={modalPropOrderType}
						price={modalPropPrice}
						totalCredit={modalPropTotalCredit}
						totalDebit={modalPropTotalDebit}
					/>
				)}

				<div className='trade-ask-bid-container'>
					<div className='trade-ask-bid-container-box'>
						<p className='trade-ask-bid-container-box-title'>Ask</p>
						<p className='trade-ask-bid-container-box-value'>${formatNumber(coinAsk)}</p>
					</div>
					<div className='trade-ask-bid-container-box'>
						<p className='trade-ask-bid-container-box-title'>Bid</p>
						<p className='trade-ask-bid-container-box-value'>${formatNumber(coinBid)}</p>
					</div>
					<div className='trade-ask-bid-container-box'>
						<p className='trade-ask-bid-container-box-title'>Last</p>
						<p className='trade-ask-bid-container-box-value'>${formatNumber(coinLast)}</p>
					</div>
					<div className='trade-ask-bid-container-box'>
						<p className='trade-ask-bid-container-box-title'>1H</p>
						<p className='trade-ask-bid-container-box-value'>{coinChange1Hour}%</p>
					</div>
					<div className='trade-ask-bid-container-box'>
						<p className='trade-ask-bid-container-box-title'>1D</p>
						<p className='trade-ask-bid-container-box-value'>{coinChange1Day}%</p>
					</div>
					<div className='trade-ask-bid-container-box'>
						<p className='trade-ask-bid-container-box-title'>7D</p>
						<p className='trade-ask-bid-container-box-value'>{coinChange7Days}%</p>
					</div>
				</div>

				<div className='rectangle' style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "30px" }}></div>

				<div
					className='trade-ask-bid-container-buy-sell-coin'
					style={{ flexDirection: screenWidth < 600 ? "column" : "row" }}
				>
					<div className='trade-ask-bid-container-buy-sell-coin-column'>
						<p className='trade-ask-bid-container-buy-sell-coin-p-buy-sell'>BUY {coinSelected}</p>
						<p
							style={{
								marginBottom: "-2px",
								cursor: user?.wallets.USDT.balance === 0 ? "text" : "pointer",
							}}
							className='trade-ask-bid-container-buy-sell-coin-text'
						>
							Ask:&nbsp;
							<span
								style={{
									cursor: user?.wallets.USDT.balance === 0 ? "text" : "pointer",
									textDecoration: user?.wallets.USDT.balance === 0 ? "none" : "underline",
								}}
								onClick={() => {
									if (user?.wallets.USDT.balance > 0) {
										setBuyPrice(coinAsk.toString());
									}
								}}
							>
								{getCurrencySymbol("USDT") + formatNumber(coinAsk)}
							</span>
						</p>
						<p
							style={{
								marginBottom: "-2px",
								cursor: user?.wallets.USDT.balance === 0 ? "text" : "pointer",
							}}
							className='trade-ask-bid-container-buy-sell-coin-text'
						>
							You Have:{" "}
							<span
								style={{
									cursor: user?.wallets.USDT.balance === 0 ? "text" : "pointer",
									textDecoration: user?.wallets.USDT.balance === 0 ? "none" : "underline",
								}}
								onClick={() => {
									if (user?.wallets.USDT.balance > 0) {
										setBuyAmount("");
										setBuyTotal(user?.wallets.USDT.balance);
									}
								}}
							>
								{getCurrencySymbol("USDT") + user?.wallets.USDT.balance}
							</span>
						</p>
						<p className='trade-ask-bid-container-buy-sell-coin-text'>Fee: {buyFee}%</p>
						<form>
							<input
								type='number'
								title='Price (USDT)'
								placeholder='Price (USDT)'
								value={buyPrice}
								onChange={handleBuyPriceChanged}
								disabled={user?.wallets.USDT.balance === 0}
							/>
							<input
								type='number'
								title={`Amount (${coinSelected})`}
								placeholder={`Amount (${coinSelected})`}
								value={buyAmount}
								onChange={handleBuyAmountChanged}
								disabled={user?.wallets.USDT.balance === 0}
							/>
							{buyOrderAmountValidation && buyAmount && (
								<div className='order-validation-message'>
									{buyOrderAmountValidation}
									<span
										style={{
											color: "black",
											textDecoration: "underline",
											cursor: "pointer",
											fontSize: "10px",
										}}
										onClick={() => {
											setBuyAmount(getMinCryptoAmountToMeetMinTradeVolume().toString());
										}}
									>
										{getCurrencySymbol(coinSelected) + getMinCryptoAmountToMeetMinTradeVolume().toString()}
									</span>
								</div>
							)}
							<input
								type='number'
								title='Total (USDT)'
								placeholder='Total (USDT)'
								value={buyTotal}
								onChange={handleBuyTotalChanged}
								disabled={user?.wallets.USDT.balance === 0}
							/>
							{buyOrderTotalAmountValidation && buyTotal && (
								<div className='order-validation-message'>
									{buyOrderTotalAmountValidation} You have&nbsp;
									<span
										style={{
											color: "black",
											textDecoration: "underline",
											cursor: "pointer",
											fontSize: "10px",
										}}
										onClick={() => {
											setBuyAmount("");
											setBuyTotal(user?.wallets.USDT.balance);
										}}
									>
										{getCurrencySymbol("USDT") + user?.wallets.USDT.balance}
									</span>
								</div>
							)}
							<button
								className='trade-button'
								disabled={!canPlaceBuyOrder}
								style={{
									backgroundColor: !canPlaceBuyOrder ? "#BBB" : "#111",
									cursor: !canPlaceBuyOrder ? "default" : "pointer",
								}}
								onClick={placeBuyOrder}
							>
								BUY
							</button>
						</form>

						<p
							className='trade-ask-bid-container-buy-sell-coin-text'
							style={{
								textDecoration: buyTotal !== "" || buyPrice !== "" || buyAmount !== "" ? "underline" : "inherit",
								cursor: buyTotal !== "" || buyPrice !== "" || buyAmount !== "" ? "pointer" : "inherit",
								fontWeight: buyTotal !== "" || buyPrice !== "" || buyAmount !== "" ? "bold" : "inherit",
								color: buyTotal !== "" || buyPrice !== "" || buyAmount !== "" ? "black" : "white",
								alignSelf: "baseline",
								paddingLeft: "10px",
							}}
							onClick={() => {
								if (buyTotal !== "" || buyPrice !== "" || buyAmount !== "") {
									setBuyTotal("");
									setBuyPrice("");
									setBuyAmount("");
								}
							}}
						>
							Reset Form
						</p>
					</div>

					{screenWidth < 600 && (
						<div
							className='rectangle'
							style={{
								backgroundColor: "#DDD",
								marginBottom: "30px",
								marginTop: "60px",
							}}
						></div>
					)}

					<div className='trade-ask-bid-container-buy-sell-coin-column'>
						<p className='trade-ask-bid-container-buy-sell-coin-p-buy-sell'>SELL {coinSelected}</p>
						<p
							style={{
								marginBottom: "-2px",
								cursor: user?.wallets[coinSelected].balance === 0 ? "default" : "pointer",
							}}
							className='trade-ask-bid-container-buy-sell-coin-text'
						>
							Bid:&nbsp;
							<span
								onClick={() => {
									if (user?.wallets[coinSelected].balance > 0) {
										setSellPrice(coinBid.toString());
									}
								}}
								style={{
									cursor: user?.wallets[coinSelected].balance === 0 ? "default" : "pointer",
									textDecoration: user?.wallets[coinSelected].balance === 0 ? "none" : "underline",
								}}
							>
								{getCurrencySymbol("USDT") + formatNumber(coinBid)}
							</span>
						</p>
						<p
							style={{
								marginBottom: "-2px",
								cursor: user?.wallets[coinSelected].balance === 0 ? "default" : "pointer",
							}}
							className='trade-ask-bid-container-buy-sell-coin-text'
						>
							You Have:{" "}
							<span
								onClick={() => {
									if (user?.wallets[coinSelected].balance > 0) {
										setSellAmount(user?.wallets[coinSelected].balance);
									}
								}}
								style={{
									cursor: user?.wallets[coinSelected].balance === 0 ? "default" : "pointer",
									textDecoration: user?.wallets[coinSelected].balance === 0 ? "none" : "underline",
								}}
							>
								{getCurrencySymbol(coinSelected) + user?.wallets[coinSelected].balance}{" "}
							</span>
						</p>
						<p className='trade-ask-bid-container-buy-sell-coin-text'>Fee: {sellFee}%</p>
						<form>
							<input
								type='number'
								title='Price (USDT)'
								placeholder='Price (USDT)'
								value={sellPrice}
								onChange={handleSellPriceChanged}
								disabled={user?.wallets[coinSelected].balance === 0}
							/>
							<input
								type='number'
								title={`Amount (${coinSelected})`}
								placeholder={`Amount (${coinSelected})`}
								value={sellAmount}
								onChange={handleSellAmountChanged}
								disabled={user?.wallets[coinSelected].balance === 0}
							/>
							{sellOrderAmountValidation && sellAmount && (
								<div className='order-validation-message'>
									{sellOrderAmountValidation}
									You have&nbsp;
									<span
										style={{
											color: "black",
											textDecoration: "underline",
											cursor: "pointer",
											fontSize: "10px",
										}}
										onClick={() => {
											setSellAmount(user?.wallets[coinSelected].balance);
										}}
									>
										{getCurrencySymbol(coinSelected) + user?.wallets[coinSelected].balance}
									</span>
								</div>
							)}
							{sellOrderTotalAmountValidation && sellAmount && (
								<div className='order-validation-message'>
									{sellOrderTotalAmountValidation}
									<span
										style={{
											color: "black",
											textDecoration: "underline",
											cursor: "pointer",
											fontSize: "10px",
										}}
										onClick={() => {
											setSellAmount(getMinCryptoAmountToMeetMinTradeVolume().toString());
										}}
									>
										{getCurrencySymbol(coinSelected) + getMinCryptoAmountToMeetMinTradeVolume().toString()}
									</span>
								</div>
							)}
							<input
								type='number'
								title='Total (USDT)'
								placeholder='Total (USDT)'
								value={sellTotal}
								onChange={handleSellTotalChanged}
								disabled={user?.wallets[coinSelected].balance === 0}
							/>
							<button
								className='trade-button'
								disabled={!canPlaceSellOrder}
								style={{
									backgroundColor: !canPlaceSellOrder ? "#BBB" : "#111",
									cursor: !canPlaceSellOrder ? "default" : "pointer",
								}}
								onClick={placeSellOrder}
							>
								SELL
							</button>
						</form>

						<p
							className='trade-ask-bid-container-buy-sell-coin-text'
							style={{
								textDecoration: sellTotal !== "" || sellPrice !== "" || sellAmount !== "" ? "underline" : "inherit",
								cursor: sellTotal !== "" || sellPrice !== "" || sellAmount !== "" ? "pointer" : "inherit",
								fontWeight: sellTotal !== "" || sellPrice !== "" || sellAmount !== "" ? "bold" : "inherit",
								alignSelf: "baseline",
								color: sellTotal !== "" || sellPrice !== "" || sellAmount !== "" ? "black" : "white",
								paddingLeft: "10px",
							}}
							onClick={() => {
								if (sellTotal !== "" || sellPrice !== "" || sellAmount !== "") {
									setSellTotal("");
									setSellPrice("");
									setSellAmount("");
								}
							}}
						>
							Reset Form
						</p>
					</div>
				</div>

				<div className='rectangle' style={{ backgroundColor: "#DDD", marginBottom: "50px", marginTop: "50px" }}></div>

				<div style={{ marginBottom: "50px" }}>
					<TradeViewChart tradingPair={`${coinSelected}USDT`} width={screenWidth < 600 ? screenWidth - 30 : 695} />
				</div>
			</div>
		</DashboardLayout>
	);
};

export default Trade;
