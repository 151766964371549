export const formatTxStatus = (status: string): string => {
	if (status === "SUCCESS") {
		return "Success";
	}

	if (status === "RETURNED") {
		return "Returned";
	}

	if (status === "TO_BE_SUBMITTED" || status === "PENDING") {
		return "Pending";
	}

	if (status === "CANCELED") {
		return "Canceled";
	}

	if (status === "ON_HOLD") {
		return "On Hold";
	}

	return "Unknown";
};

export const getTxStatusColor = (status: string): string => {
	if (status === "SUCCESS") {
		return "green";
	}

	if (status === "CANCELED" || status === "RETURNED") {
		return "red";
	}

	if (status === "TO_BE_SUBMITTED" || status === "PENDING" || status === "ON_HOLD") {
		return "orange";
	}

	return "black";
};
