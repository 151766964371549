import "./RightPanelModal.css";

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

import UserService from "../../services/user/UserService";

const customStyles = {
	content: {
		width: "350px",
		height: "100%",
		left: "auto",
		right: "0",
		bottom: "0",
		padding: "0",
		margin: "0",
		boxShadow: "none",
		border: "none",
	},
};

interface RightPanelModalProps {
	closeModal: () => void;
	openWalletSidebarModal: () => void;
	openAlertsModal: () => void;
	openLogoutModal: () => void;
}

Modal.setAppElement("#root");

const LeftPanelModal: React.FC<RightPanelModalProps> = ({
	closeModal,
	openWalletSidebarModal,
	openAlertsModal,
	openLogoutModal,
}) => {
	const [scrollDisabled, setScrollDisabled] = useState(false);

	const [userInitials, setUserInitials] = useState("");

	useEffect(() => {
		const userService = UserService.getInstance();
		const user = userService.getUser();
		if (user) {
			const userInitials =
				(user?.tierZeroInfo?.firstname?.[0] ?? "-").toUpperCase() +
				(user?.tierZeroInfo?.lastname?.[0] ?? "-").toUpperCase();
			setUserInitials(userInitials);
		}

		if (!scrollDisabled) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [scrollDisabled]);

	return (
		<div>
			<Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
				<div className='right-panel-title'>
					<div
						className='right-panel-user-circle-container'
						onClick={() => {
							closeModal();
							setScrollDisabled(true);
						}}
					>
						<p className='right-panel-user-circle-text'>{userInitials}</p>
					</div>
				</div>

				<div className='right-panel-big-section'>
					<div
						onClick={() => {
							closeModal();
							setScrollDisabled(true);
						}}
					>
						<Link to='/account' className='right-panel-big-section-link'>
							<p className='right-panel-big-section-text'>Account</p>
						</Link>
					</div>
					<p
						style={{ cursor: "pointer" }}
						className='right-panel-big-section-text'
						onClick={() => {
							openWalletSidebarModal();
							closeModal();
						}}
					>
						Wallet
					</p>
					<p
						style={{ cursor: "pointer" }}
						className='right-panel-big-section-text'
						onClick={() => {
							openAlertsModal();
							closeModal();
							setScrollDisabled(true);
						}}
					>
						Alerts
					</p>
					<div
						onClick={() => {
							closeModal();
							setScrollDisabled(true);
						}}
					>
						<Link to='/mobile-activity' className='right-panel-big-section-link'>
							<p className='right-panel-big-section-text'>Mobile Activity</p>
						</Link>
					</div>
					<div
						onClick={() => {
							closeModal();
							setScrollDisabled(true);
						}}
					>
						<Link to='/settings' className='right-panel-big-section-link'>
							<p className='right-panel-big-section-text'>Settings</p>
						</Link>
					</div>
					<div
						onClick={() => {
							closeModal();
							setScrollDisabled(true);
						}}
					>
						<Link to='/referrals' className='right-panel-big-section-link'>
							<p className='right-panel-big-section-text'>Referrals</p>
						</Link>
					</div>
					<div
						onClick={() => {
							closeModal();
							setScrollDisabled(true);
						}}
					>
						<Link to='/help' className='right-panel-big-section-link'>
							<p className='right-panel-big-section-text'>Help</p>
						</Link>
					</div>
					<p
						style={{ cursor: "pointer" }}
						className='right-panel-big-section-text'
						onClick={() => {
							openLogoutModal();
							closeModal();
							setScrollDisabled(true);
						}}
					>
						Log Out
					</p>
				</div>
			</Modal>
		</div>
	);
};

export default LeftPanelModal;
