class AuthService {
	private tokenKey = "authToken";

	storeToken(token: string, rememberMe: boolean) {
		try {
			if (rememberMe) {
				const expirationDate = new Date();
				expirationDate.setDate(expirationDate.getDate() + 7);
				document.cookie = `${this.tokenKey}=${token}; expires=${expirationDate.toUTCString()}; path=/`;
			} else {
				localStorage.setItem(this.tokenKey, token);
			}
		} catch (error) {
			console.log("Error ", error);
		}
	}

	getToken(): string | null {
		const token = localStorage.getItem(this.tokenKey);
		if (token) {
			return token;
		}

		const cookieValue = document.cookie.match("(^|;)\\s*" + this.tokenKey + "\\s*=\\s*([^;]+)");
		return cookieValue ? cookieValue.pop() || "" : null;
	}

	isLoggedIn(): boolean {
		const token = this.getToken();

		if (!token) {
			return false;
		}

		const tokenData = this.parseJwt(token);
		if (!tokenData) {
			return false;
		}

		const currentTime = Math.floor(Date.now() / 1000);
		return !!tokenData.exp && tokenData.exp > currentTime;
	}

	logout() {
		localStorage.removeItem(this.tokenKey);
		document.cookie = `${this.tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
	}

	private parseJwt(token: string): { [key: string]: any } | null {
		try {
			const base64Url = token.split(".")[1];
			const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			const decoded = atob(base64);
			return JSON.parse(decoded) as { [key: string]: any };
		} catch (error) {
			console.log("Error", error);
			return null;
		}
	}
}

export default AuthService;
