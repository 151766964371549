import "../../layout/Dashboard/layout.css";

import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { coinData } from "src/constants/coin";
import DashboardLayout from "src/layout/Dashboard/Layout";

import FeesTickersService from "../../services/api/common/fees-tickers-service/FeesTickersService";
import JwtUser from "../../services/api/common/jwtUser/JwtUser";
import { Fees } from "../../services/api/common/response/Fees";
import NgnUsdtRate from "../../services/api/common/response/NgnUsdtRate";
import WithdrawalRequestViewModel from "../../services/api/web/request/WithdrawalRequestViewModel";
import UserService from "../../services/user/UserService";
import UserServiceUtils from "../../services/user/UserServiceUtils";
import { getCurrencySymbol } from "../../services/utils/cryptoSymbols";
import { formatNumber } from "../../services/utils/formatNumber";
import { getLongNameForCrypto } from "../../services/utils/longNameCrypto";
import { showErrorToast, showSuccessToast } from "../../services/utils/toastUtils";
import ConfirmWithdrawalModal from "./ConfirmWithdrawalModal";

const Withdraw = () => {
	const withdrawalType = ["Bank", "Blockchain"];

	const fiatCoins = ["EUR", "NGN"];

	const history = useHistory();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const coinSelectedParam = queryParams.get("selectedCoin") || "USDT";

	const [coinSelected, setCoinSelected] = useState(coinSelectedParam);
	const [withdrawalTypeSelected, setWithdrawalTypeSelected] = useState("Blockchain");
	const [fiatCoinSelected, setFiatCoinSelected] = useState("EUR");
	const [coinBuyFiatPrice, setCoinBuyFiatPrice] = useState(0);
	const [coinSellFiatPrice, setCoinSellFiatPrice] = useState(0);

	const [user, setUser] = useState<JwtUser | null>(null);
	const [fees, setFees] = useState<Fees | null>(null);

	const [linesOfCoins, setLinesOfCoins] = useState<any>([]);
	const screenWidth = window.outerWidth;

	const [withdrawnAmount, setWithdrawnAmount] = useState("");
	const [withdrawnAmountValidation, setWithdrawnAmountValidation] = useState("");
	const [withdrawalWallet, setWithdrawalWallet] = useState("");
	const [estimatedReceivedAmountText, setEstimatedReceivedAmountText] = useState("");

	const [canMakeWithdrawal, setCanMakeWithdrawal] = useState(false);

	const feesTickerService: FeesTickersService = FeesTickersService.getInstance();
	const userService = UserService.getInstance();

	const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
	const [twoFactorInputCode, setTwoFactorInputCode] = useState("");

	const [paymentId, setPaymentId] = useState("");

	const [withdrawalFixedFee, setWithdrawalFixedFee] = useState(0);

	const [hasTierTwo, setHasTierTwo] = useState(false);

	const userServiceUtils = UserServiceUtils.getInstance();

	const [isConfirmWithdrawalModalOpen, setIsConfirmWithdrawalModalOpen] = useState(false);

	useEffect(() => {
		const user = userService.getUser();
		if (user) {
			setUser(user);
			setTwoFactorEnabled(user?.twoFactor);
			setHasTierTwo(userServiceUtils.hasTier(user?.authorities, "ROLE_USER_TIER_2"));
		}

		const fees = feesTickerService.getFees();
		if (fees) {
			setFees(fees);
			setWithdrawalFixedFee(fees?.currencies[coinSelected].withdrawalFixed || 0);
		}
	}, []); // Empty dependency array means the effect runs only once

	useEffect(() => {
		// Calculate the number of lines and coins per line based on screen width
		let numLines, coinsPerLine;
		if (screenWidth >= 600) {
			numLines = 3;
			coinsPerLine = Math.ceil(coinData.length / numLines);
		} else {
			numLines = 6;
			coinsPerLine = 4;
		}

		// Generate lines with coins
		const linesArray = [];
		for (let i = 0; i < numLines; i++) {
			const startIdx = i * coinsPerLine;
			const endIdx = startIdx + coinsPerLine;
			const lineCoins = coinData.slice(startIdx, endIdx);

			linesArray.push(
				<div className='trade-coin-container-line' key={i}>
					{lineCoins.map((coin) => (
						<div
							key={coin.name}
							className={`trade-container-coin`}
							style={{
								borderRadius: coinSelected === coin.name ? "5px" : "inherit",
								border: coinSelected === coin.name ? "1px solid black" : "inherit",
							}}
							onClick={() => setCoinSelected(coin.name)}
						>
							<span className='trade-container-coin-text'>{coin.name}</span>
							<img src={coin.icon} alt='ic' className='trade-icon' />
						</div>
					))}
				</div>
			);
		}

		setLinesOfCoins(linesArray);
	}, [coinSelected]);

	useEffect(() => {
		if (coinSelected !== "USDT") {
			setWithdrawalTypeSelected("Blockchain");
		}

		let newBuyValue = coinBuyFiatPrice;
		let newSellValue = coinSellFiatPrice;

		if (fiatCoinSelected === "NGN") {
			const ngnUsdtRate: NgnUsdtRate = feesTickerService.getNgnUsdtRate();
			newBuyValue = ngnUsdtRate.buy;
			newSellValue = ngnUsdtRate.sell;
		} else if (fiatCoinSelected === "EUR") {
			const usdtTicker = feesTickerService.getUsdtTicker();
			newBuyValue = (usdtTicker.value * (100 + usdtTicker.buyFee)) / 100;
			newSellValue = (usdtTicker.value * (100 - usdtTicker.sellFee)) / 100;
		}

		setCoinBuyFiatPrice(newBuyValue);
		setCoinSellFiatPrice(newSellValue);

		setWithdrawalFixedFee(fees?.currencies[coinSelected].withdrawalFixed || 0);

		resetForm();
	}, [coinSelected, fiatCoinSelected, user, fees, withdrawalTypeSelected]);

	useEffect(() => {
		if (typeof withdrawnAmount === "string" && withdrawnAmount.trim() === "") {
			setCanMakeWithdrawal(false);
			return;
		}

		const withdrawnAmountNum = parseFloat(withdrawnAmount);
		if (isNaN(withdrawnAmountNum)) {
			setCanMakeWithdrawal(false);
			return;
		}

		const coinBalance = user?.wallets[coinSelected].balance;
		if (coinBalance === 0) {
			setWithdrawnAmountValidation("Insufficient funds");
			setCanMakeWithdrawal(false);
			return;
		}

		if (withdrawnAmount > coinBalance) {
			setWithdrawnAmountValidation(`Cannot withdraw more than ${getCurrencySymbol(coinSelected)} ${coinBalance}`);
			setCanMakeWithdrawal(false);
			return;
		}

		const minAmount = Math.max(
			(fees?.withdrawalFeeMultiplicator || 0) * (fees?.currencies[coinSelected]?.minWithdrawalAmount || 0),
			withdrawalFixedFee
		);
		if (withdrawnAmountNum < minAmount) {
			setWithdrawnAmountValidation(`Cannot withdraw less than ${getCurrencySymbol(coinSelected)} ${minAmount}`);
			setCanMakeWithdrawal(false);
			return;
		}

		setWithdrawnAmountValidation("");

		if (withdrawalTypeSelected === "Bank") {
			const convertedAmount = coinSellFiatPrice * withdrawnAmountNum;
			const fixedFeeWithdrawalFiat = 0;
			// fees?.currencies[coinSelected]?.withdrawalFiat[fiatCoinSelected] || 0;
			const amountToReceive = formatNumber(convertedAmount - fixedFeeWithdrawalFiat);
			const estimation =
				getCurrencySymbol(fiatCoinSelected) +
				" " +
				amountToReceive +
				" (" +
				getCurrencySymbol(fiatCoinSelected) +
				" " +
				formatNumber(convertedAmount) +
				" - " +
				getCurrencySymbol(fiatCoinSelected) +
				" " +
				formatNumber(fixedFeeWithdrawalFiat) +
				")";
			setEstimatedReceivedAmountText(estimation);
		} else {
			const fixedFeeWithdrawalFiat = fees?.currencies[coinSelected]?.withdrawalFixed || 0;
			const amountToReceive = formatNumber(withdrawnAmountNum - fixedFeeWithdrawalFiat, 4);
			const estimation =
				getCurrencySymbol(coinSelected) +
				" " +
				amountToReceive +
				" (" +
				getCurrencySymbol(coinSelected) +
				" " +
				formatNumber(withdrawnAmountNum) +
				" - " +
				getCurrencySymbol(coinSelected) +
				" " +
				formatNumber(fixedFeeWithdrawalFiat, 4) +
				")";
			setEstimatedReceivedAmountText(estimation);
		}

		if (twoFactorEnabled && twoFactorInputCode.trim() === "") {
			setCanMakeWithdrawal(false);
			return;
		}

		if (coinSelected === "XLM" || coinSelected === "XRP" || coinSelected === "ATOM") {
			if (paymentId.trim() === "") {
				setCanMakeWithdrawal(false);
				return;
			}
		}

		if (withdrawalWallet.trim() === "") {
			setCanMakeWithdrawal(false);
			return;
		}

		if (withdrawalWallet === "Withdrawal bank account") {
			setCanMakeWithdrawal(false);
			return;
		}

		setCanMakeWithdrawal(true);
	}, [
		withdrawnAmount,
		withdrawalWallet,
		withdrawalTypeSelected,
		fiatCoinSelected,
		coinSelected,
		twoFactorInputCode,
		paymentId,
		withdrawalFixedFee,
	]);

	const handleDepositTypeSelect = (event: any) => {
		const newDepositType = event.target.value;
		setWithdrawalTypeSelected(newDepositType);
	};

	const handleFiatCoinSelect = (event: any) => {
		const newFiatCoin = event.target.value;
		setFiatCoinSelected(newFiatCoin);
	};

	const handleWithdrawalWalletChange = (event: any) => {
		const newWithdrawalWallet = event.target.value;
		setWithdrawalWallet(newWithdrawalWallet);
	};

	const handleWithdrawnAmountChange = (event: any) => {
		setWithdrawnAmount(event.target.value);
	};

	const handleTwoFactorInputCodeChange = (event: any) => {
		setTwoFactorInputCode(event.target.value);
	};

	const handlePaymentIdChange = (event: any) => {
		setPaymentId(event.target.value);
	};

	const resetForm = () => {
		setWithdrawalWallet("");
		setTwoFactorInputCode("");
		setWithdrawnAmount("");
		setPaymentId("");
		setCanMakeWithdrawal(false);
	};

	const submitWithdrawal = async () => {
		if (!canMakeWithdrawal) {
			return;
		}

		const withdrawalRequest: WithdrawalRequestViewModel = {
			amount: parseFloat(withdrawnAmount),
			currencyCode: coinSelected,
			paymentId: paymentId,
			to: withdrawalWallet,
			twoFactorVerificationCode: twoFactorEnabled ? twoFactorInputCode : "",
		};

		const response = await userService.requestWithdrawal(withdrawalRequest);

		if (response && response.status === 200) {
			userService.setUser((response?.data as any)?.user);
			setUser(userService.getUser());

			userService.addWithdrawal((response?.data as any)?.transaction);

			resetForm();

			showSuccessToast(toast, "Your withdrawal has been successfully submitted.");
		} else {
			showErrorToast(toast, displayResponseErrorMessage((response?.data as any)?.message));
		}
	};

	const displayResponseErrorMessage = (message: string) => {
		const defaultMessage =
			"An error occurred while submitting your withdrawal. Please try again later. If the problem persists, contact our staff.";

		if (!message) {
			return defaultMessage;
		}

		if (message === "INVALID_TWO_FACTOR_VERIFICATION_CODE") {
			return "Wrong two factor code";
		}

		if (message === "WITHDRAWAL_DISABLED") {
			let coin = coinSelected;
			if (withdrawalTypeSelected === "Bank") {
				coin = fiatCoinSelected;
			}
			return `Withdrawals are temporarily disabled for ${coin}`;
		}

		if (message === "INVALID_IBAN") {
			return `Invalid wallet destination address`;
		}

		return defaultMessage;
	};

	return (
		<DashboardLayout>
			<h1 className='title'>Withdraw</h1>
			{hasTierTwo && (
				<div>
					<div className='dashboard-container'>
						<div className='deposit-titles'>
							<Link to='/withdraw' className='deposit-active-tab'>
								<p>Issue Withdrawal</p>
							</Link>
							<Link to='/withdraw-history' className='deposit-inactive-tab'>
								<p>Withdrawal History</p>
							</Link>
						</div>

						<div className='trade-coin-container'>{linesOfCoins}</div>

						{isConfirmWithdrawalModalOpen && (
							<ConfirmWithdrawalModal
								closeModal={() => setIsConfirmWithdrawalModalOpen(false)}
								confirmCancel={() => {}}
								submitWithdrawal={submitWithdrawal}
								withdrawalAddress={withdrawalWallet}
								withdrawalAmount={withdrawnAmount}
								coin={coinSelected}
								estimatedReceivedAmount={estimatedReceivedAmountText}
							/>
						)}

						<div
							className='rectangle'
							style={{ backgroundColor: "#DDD", marginBottom: "10px", marginTop: "40px" }}
						></div>

						<div style={{ display: "flex", justifyContent: "center" }}>
							<p className='deposit-select-type'>Select withdrawal type</p>
						</div>

						<div style={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
							{coinSelected === "USDT" ? (
								<select value={withdrawalTypeSelected} onChange={handleDepositTypeSelect} className='deposit-dropdown'>
									{withdrawalType.map((type) => (
										<option key={type} value={type}>
											{type}
										</option>
									))}
								</select>
							) : (
								<select value='Bank' disabled className='deposit-dropdown'>
									<option value='Bank'>Blockchain</option>
								</select>
							)}
						</div>

						{withdrawalTypeSelected === "Bank" && (
							<div>
								<div className='deposit-bank-currency-sell-buy'>
									<div className='deposit-bank-currency-sell-buy-box'>
										<p className='deposit-select-type'>View Currency</p>
										<select
											value={fiatCoinSelected}
											onChange={handleFiatCoinSelect}
											className='deposit-dropdown'
											style={{ marginTop: "-10px" }}
										>
											{fiatCoins.map((type) => (
												<option key={type} value={type}>
													{type}
												</option>
											))}
										</select>
									</div>
									<div className='deposit-bank-currency-sell-buy-box'>
										<p className='deposit-select-type'>Sell Price</p>
										<p
											style={{
												textAlign: "center",
												marginTop: "-10px",
											}}
										>
											{getCurrencySymbol(fiatCoinSelected)} {formatNumber(coinSellFiatPrice)}
										</p>
									</div>
									<div className='deposit-bank-currency-sell-buy-box'>
										<p className='deposit-select-type'>Buy Price</p>
										<p
											style={{
												textAlign: "center",
												marginTop: "-10px",
											}}
										>
											{getCurrencySymbol(fiatCoinSelected)} {formatNumber(coinBuyFiatPrice)}
										</p>
									</div>
								</div>
							</div>
						)}

						{withdrawalTypeSelected === "Blockchain" && (
							<div>
								<div
									className='rectangle'
									style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "20px" }}
								></div>

								<p className='deposit-bank-instruction-text' style={{ fontWeight: "bold", lineHeight: "30px" }}>
									Instructions
								</p>
								<p className='deposit-bank-instruction-text'>
									{user?.wallets[coinSelected].balance > 0 ? (
										<>
											1. You Have:&nbsp;
											<span
												style={{ cursor: "pointer", textDecoration: "underline" }}
												onClick={() => {
													setWithdrawnAmount(user?.wallets[coinSelected].balance);
												}}
											>
												{getCurrencySymbol(coinSelected) + user?.wallets[coinSelected].balance}
											</span>
										</>
									) : (
										<>1. Your balance is currently 0. You don&apos;t have any funds to withdraw.</>
									)}
								</p>

								<p className='deposit-bank-instruction-text'>
									2. Withdraw {getLongNameForCrypto(coinSelected)} by sending them to an{" "}
									{getLongNameForCrypto(coinSelected)} wallet on of your choice.
								</p>
								<p className='deposit-bank-instruction-text'>
									3. Make sure that the withdrawal address is specified correctly.
								</p>
								<p className='deposit-bank-instruction-text'>
									4. Withdrawal Fixed fee: {getCurrencySymbol(coinSelected)}
									{withdrawalFixedFee}
								</p>
								{coinSelected === "USDT" && (
									<p className='deposit-bank-instruction-text'>
										5. Use only USDT wallets from Ethereum network (ERC20) to withdraw from your vTrader wallet. Sending
										to any other wallet may result in the loss of your funds
									</p>
								)}
								{coinSelected === "XRP" && (
									<p className='deposit-bank-instruction-text'>5. Make sure the Deposit Tag is specified correctly.</p>
								)}
								{(coinSelected === "XLM" || coinSelected === "ATOM") && (
									<p className='deposit-bank-instruction-text'>5. Make sure the Memo is specified correctly.</p>
								)}
							</div>
						)}

						{withdrawalTypeSelected === "Bank" && (
							<div>
								<div
									className='rectangle'
									style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "20px" }}
								></div>

								<p className='deposit-bank-instruction-text' style={{ fontWeight: "bold", lineHeight: "30px" }}>
									Instructions
								</p>
								<p className='deposit-bank-instruction-text'>
									{user?.wallets[coinSelected].balance > 0 ? (
										<>
											1. You Have:&nbsp;
											<span
												style={{ cursor: "pointer", textDecoration: "underline" }}
												onClick={() => {
													setWithdrawnAmount(user?.wallets[coinSelected].balance);
												}}
											>
												{getCurrencySymbol(coinSelected) + user?.wallets[coinSelected].balance}
											</span>
										</>
									) : (
										<>1. Your balance is currently 0. You don&apos;t have any funds to withdraw.</>
									)}
								</p>

								<p className='deposit-bank-instruction-text'>
									2. Withdrawal Fixed Fee:&nbsp;
									{getCurrencySymbol(fiatCoinSelected)}
									{/* {fees?.currencies[coinSelected]?.withdrawalFiat[fiatCoinSelected] || 0} */}
								</p>
								<p className='deposit-bank-instruction-text'>
									3. The withdrawn amount will be credited to the bank account associated with your platforms&apos;s
									wallet
								</p>
							</div>
						)}

						<div
							className='rectangle'
							style={{ backgroundColor: "#DDD", marginBottom: "30px", marginTop: "40px" }}
						></div>

						<div className='withdrawal-input-container'>
							{withdrawalTypeSelected === "Bank" && (
								<select
									value={withdrawalWallet}
									onChange={handleWithdrawalWalletChange}
									disabled={user?.wallets[coinSelected].balance === 0}
									className='withdrawal-bank-account-dropdown'
									title='Withdrawal bank account'
								>
									<option value=''>Withdrawal bank account</option>
									{user?.verifiedBankAccounts && user?.verifiedBankAccounts[fiatCoinSelected]?.length > 0 ? (
										user?.verifiedBankAccounts[fiatCoinSelected].map((bankAccount, index) => (
											<option key={index} value={bankAccount.iban}>
												{bankAccount.iban}
											</option>
										))
									) : (
										<option disabled>No bank accounts available for {fiatCoinSelected}</option>
									)}
								</select>
							)}

							{withdrawalTypeSelected === "Blockchain" && (
								<input
									style={{ marginTop: "10px", height: "12px" }}
									type='text'
									placeholder='Withdrawal Address'
									disabled={user?.wallets[coinSelected].balance === 0}
									value={withdrawalWallet}
									onChange={handleWithdrawalWalletChange}
								/>
							)}

							<input
								style={{ marginTop: "10px", height: "12px" }}
								type='number'
								disabled={user?.wallets[coinSelected].balance === 0}
								placeholder={`Withdrawn Amount (${getCurrencySymbol(coinSelected)} ${
									user?.wallets[coinSelected].balance
								} Available)`}
								value={withdrawnAmount}
								onChange={handleWithdrawnAmountChange}
							/>

							{withdrawnAmountValidation && withdrawnAmount && (
								<div className='withdrawal-validation-message'>{withdrawnAmountValidation}</div>
							)}

							{(coinSelected === "XLM" || coinSelected === "XRP" || coinSelected === "ATOM") && (
								<input
									style={{ marginTop: "10px" }}
									type='text'
									disabled={user?.wallets[coinSelected].balance === 0}
									placeholder={
										coinSelected === "XLM" || coinSelected === "ATOM"
											? "Memo*"
											: coinSelected === "XRP"
												? "Deposit Tag*"
												: "Memo*"
									}
									value={paymentId}
									onChange={handlePaymentIdChange}
								/>
							)}

							{twoFactorEnabled && (
								<input
									style={{ marginTop: "10px" }}
									type='numeric'
									placeholder='Two Factor Code*'
									value={twoFactorInputCode}
									onChange={handleTwoFactorInputCodeChange}
								/>
							)}

							<p
								className='trade-ask-bid-container-buy-sell-coin-text'
								style={{
									textDecoration:
										withdrawalWallet !== "" || withdrawnAmount !== "" || paymentId !== "" || twoFactorInputCode !== ""
											? "underline"
											: "inherit",
									cursor:
										withdrawalWallet !== "" || withdrawnAmount !== "" || paymentId !== "" || twoFactorInputCode !== ""
											? "pointer"
											: "inherit",
									fontWeight:
										withdrawalWallet !== "" || withdrawnAmount !== "" || paymentId !== "" || twoFactorInputCode !== ""
											? "bold"
											: "inherit",
									color:
										withdrawalWallet !== "" || withdrawnAmount !== "" || paymentId !== "" || twoFactorInputCode !== ""
											? "black"
											: "white",
									alignSelf: "baseline",
								}}
								onClick={() => {
									if (
										withdrawalWallet !== "" ||
										withdrawnAmount !== "" ||
										paymentId !== "" ||
										twoFactorInputCode !== ""
									) {
										resetForm();
									}
								}}
							>
								Reset Form
							</p>

							<div style={{ height: "35px", marginBottom: screenWidth < 600 ? "40px" : "25px" }}>
								{withdrawnAmount && !withdrawnAmountValidation && (
									<div>
										<p style={{ fontSize: "14px" }}>Estimated Received Amount: {estimatedReceivedAmountText}</p>
										<p style={{ fontSize: "10px", marginTop: "-10px" }}>
											*The rate may change until your withdrawal is processed
										</p>
									</div>
								)}
							</div>

							<button
								className='withdrawal-button'
								disabled={!canMakeWithdrawal}
								style={{
									backgroundColor: !canMakeWithdrawal ? "#BBB" : "#111",
									cursor: !canMakeWithdrawal ? "default" : "pointer",
								}}
								onClick={() => {
									setIsConfirmWithdrawalModalOpen(true);
								}}
							>
								ISSUE WITHDRAWAL
							</button>
						</div>
					</div>
				</div>
			)}

			{!hasTierTwo && (
				<div>
					<div className='deposit-get-verified-tier-container'>
						<div className='deposit-get-verified-tier-box'>
							<p className='deposit-bank-instruction-text'>To withdraw, you must complete Tier 2 verification.</p>
						</div>
						<div className='deposit-get-verified-tier-box' style={{ marginTop: "10px" }}>
							<button
								className='deposit-get-tier-verified-button'
								onClick={() => {
									history.push("/account?selectedTier=tier2");
								}}
							>
								GET STARTED
							</button>
						</div>
					</div>
				</div>
			)}
		</DashboardLayout>
	);
};

export default Withdraw;
