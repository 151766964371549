export const formatDateForTable = (timestamp: number): string => {
	return `${new Date(timestamp).toLocaleDateString("en-US", {
		day: "2-digit",
		month: "short",
		year: "numeric",
	})} ${new Date(timestamp).toLocaleTimeString("en-US", {
		hour12: false, // Use 24-hour format
		hour: "2-digit",
		minute: "2-digit",
	})}`;
};
