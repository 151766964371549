import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

import { coinData } from "src/constants/coin";

import UserService from "../../services/user/UserService";
import { formatNumber } from "../../services/utils/formatNumber";
const customStyles = {
	content: {
		height: "100%",
		left: "auto",
		right: "0",
		bottom: "0",
		padding: "0",
		margin: "0",
		boxShadow: "none",
		border: "none",
	},
};

interface RightPanelModalProps {
	closeModal: () => void;
}

Modal.setAppElement("#root");

const WalletSidebarModal: React.FC<RightPanelModalProps> = ({ closeModal }) => {
	const [scrollDisabled, setScrollDisabled] = useState(false);

	const history = useHistory();
	const [wallets, setWallets] = useState<any>(null);
	const userService = UserService.getInstance();

	useEffect(() => {
		if (!scrollDisabled) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		const user = userService.getUser();
		if (user) {
			setWallets(user?.wallets);
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [scrollDisabled, wallets]);

	return (
		<div>
			<Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
				<div style={{ paddingLeft: "20px" }}>
					<div
						style={{ display: "flex", justifyContent: "start", cursor: "pointer" }}
						onClick={() => {
							closeModal();
							setScrollDisabled(true);
						}}
					>
						<p className='wallet-details-text' style={{ marginRight: "4px" }}>
							&lt;
						</p>
						<p className='wallet-details-text'>Back</p>
					</div>

					<p>Wallet</p>

					{wallets && (
						<table>
							<div className='rectangle' style={{ backgroundColor: "#DDD", marginBottom: "15px" }}></div>
							<tbody>
								{coinData.map((coin, index) => (
									<div key={`table-row-${index}`}>
										<div className='wallet-details-coin-box' style={{ width: "365px" }}>
											<td style={{ width: "25px" }}>
												<img src={coin.icon} alt={`${coin.name} Icon`} className='wallet-details-icon' />
											</td>
											<td className='wallet-details-text' style={{ width: "40px" }}>
												{coin.name}
											</td>
											<td
												className='wallet-details-text'
												style={{
													width: "210px",
													textAlign: "right",
												}}
											>
												{formatNumber(wallets[coin.name]?.balance, 8) || 0.0}
											</td>
											<td style={{ paddingLeft: "20px", paddingBottom: "5px" }}>
												<button
													className='button-with-text-only'
													style={{ marginRight: "20px" }}
													onClick={() => {
														history.push(`/withdraw?selectedCoin=${coin.name}`);
														closeModal();
													}}
												>
													&minus;
												</button>
												<button
													className='button-with-text-only'
													onClick={() => {
														history.push(`/deposit?selectedCoin=${coin.name}`);
														closeModal();
													}}
												>
													+
												</button>
											</td>
										</div>
										<div
											className='rectangle'
											style={{
												backgroundColor: "#DDD",
												marginBottom: "15px",
												marginTop: "15px",
											}}
										></div>
									</div>
								))}
							</tbody>
						</table>
					)}

					<div style={{ marginBottom: "50px" }}></div>
				</div>
			</Modal>
		</div>
	);
};

export default WalletSidebarModal;
