import TradingViewWidget from "react-tradingview-widget";

interface ChartComponentProps {
	tradingPair: string;
	exchange?: "binance";
	height?: number;
	width?: number;
}

const ChartComponent: React.FC<ChartComponentProps> = ({
	tradingPair,
	exchange = "binance",
	height = 400,
	width = 695,
}: ChartComponentProps) => {
	return (
		<div>
			<TradingViewWidget
				symbol={`${exchange.toUpperCase()}:${tradingPair}`} // Use the provided trading pair and exchange
				interval='D' // Set the chart interval (D = Daily)
				theme='Light' // Set the chart theme (dark or light)
				locale='en' // Set the chart locale (en, es, etc.)
				width={width} // Set the chart width
				height={height} // Set the chart height
			/>
		</div>
	);
};

export default ChartComponent;
