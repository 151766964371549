import React, { createContext, ReactNode, useContext, useState } from "react";

import UserService from "../user/UserService";
import AuthService from "./AuthService";

interface AuthContextType {
	isLoggedIn: boolean;
	login: (token: string, rememberMe: boolean) => void;
	logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const authService = new AuthService();

	const login = (token: string, rememberMe: boolean) => {
		setIsLoggedIn(true);
		UserService.getInstance().setAuthToken(token);
		authService.storeToken(token, rememberMe);
	};

	const logout = () => {
		setIsLoggedIn(false);
		UserService.getInstance().setAuthToken(null);
		authService.logout();
	};

	return <AuthContext.Provider value={{ isLoggedIn, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
