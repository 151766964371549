import React, { createContext, useContext } from "react";

import WebSocketService from "./WebSocketService";

interface WebSocketContextProps {
	children: React.ReactNode;
}

export interface WebSocketContextType {
	webSocketService: WebSocketService;
}

const WebsocketContext = createContext<WebSocketContextType | undefined>(undefined);

export function WebSocketProvider({ children }: WebSocketContextProps) {
	const webSocketService = new WebSocketService();

	return <WebsocketContext.Provider value={{ webSocketService }}>{children}</WebsocketContext.Provider>;
}

export function useTradeWebSocket() {
	const context = useContext(WebsocketContext);
	if (context === undefined) {
		throw new Error("useWebSocket must be used within a WebSocketProvider");
	}
	return context;
}
