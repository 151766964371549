import "../../layout/Dashboard/layout.css";
import "react-toastify/dist/ReactToastify.css";

const About = () => {
	return (
		<div>
			<div className='dashboard-container' style={{ marginBottom: "50px" }}>
				<h1 className='title' style={{ marginBottom: "30px" }}>
					Why vTrader?
				</h1>

				{/*<p className="faq-title-section-text" style={{fontSize: '14px', fontWeight: 'bold'}}>Bringing traditional money and crypto together</p>*/}
				{/*<p className="faq-content-section-text" style={{marginLeft: '0px'}}>The process of buying cryptocurrency can be long and unnecessarily cumbersome. The long lead time required for the bank transactions to settle can be frustrating when users are seeking to execute a trade at a specific price. To minimize the time window and price fluctuation for users, vTrader allows you to trade with USD Tether (“USDT”). USDT is a stablecoin, pegged to the price of the U.S Dollar. Our platform offers a system through which our clients can buy and sell USDT. The purpose of this system is to avoid the risk generated by the cryptomarket volatility during bank transfers in and out of the platform.</p>*/}

				{/*<p className="faq-title-section-text" style={{fontSize: '14px', fontWeight: 'bold', marginTop: '30px'}}>Security & Privacy You Can Trust</p>*/}
				{/*<p className="faq-content-section-text" style={{marginLeft: '0px'}}>Customers can issue a withdrawal of USDT in the "Withdrawal" section of our platform. For security reasons, and in order for AML(Anti-Money-Laundering) standards to be respected, users can only withdraw fiat in a bank account from which they already bought any amount of USDT. The withdrawal will be processed by our staff and the USDT submitted for withdrawal will be converted into EUR at the moment of processing.</p>*/}

				<p className='faq-title-section-text' style={{ fontSize: "14px", fontWeight: "bold", marginTop: "30px" }}>
					Responsive and Relevant Support
				</p>
				<p className='faq-content-section-text' style={{ marginLeft: "0px" }}>
					We believe in a strong relationship with our members. One based on trust, respect, and responsiveness in
					solving the request.
				</p>

				<p className='faq-title-section-text' style={{ fontSize: "14px", fontWeight: "bold", marginTop: "30px" }}>
					The simplicity of our platform
				</p>
				<p className='faq-content-section-text' style={{ marginLeft: "0px" }}>
					Our platform is designed to be a “go-to” place for everyone in the crypto-market. Our interface is welcoming
					and intuitive for both advanced members and beginners alike.
				</p>

				<p className='faq-title-section-text' style={{ fontSize: "14px", fontWeight: "bold", marginTop: "30px" }}>
					Trust
				</p>
				<p className='faq-content-section-text' style={{ marginLeft: "0px" }}>
					We build the platform’s architecture with a security-driven mindset. Our cold-wallets and fund storages are
					only accessible with the consent of every member of our board of directors.
				</p>

				<p className='faq-title-section-text' style={{ fontSize: "14px", fontWeight: "bold", marginTop: "30px" }}>
					Mobile Apps and Free Transfers
				</p>
				<p className='faq-content-section-text' style={{ marginLeft: "0px" }}>
					Our mobile apps are indeed a product meant to bring aboard a true community of crypto traders. Free transfer
					and trades provided by the mobile app help us in our quest.
				</p>
			</div>
		</div>
	);
};

export default About;
