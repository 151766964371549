import { toast } from "react-toastify";

import gIcon from "./g-icon.png";
import xIcon from "./x-icon.png";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const showSuccessToast = (toast: toast, messsage: string) => {
	toast.success(messsage, {
		icon: () => <img src={gIcon} style={{ width: "20px" }} />,
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 3000, // Close the toast after 3 seconds
		hideProgressBar: true, // Hide the progress bar
		closeOnClick: true, // Close the toast when clicked
		pauseOnHover: false, // Don't pause the timer on hover
		draggable: false, // Disable dragging the toast
		closeButton: false,
	});
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const showErrorToast = (toast: toast, messsage: string) => {
	toast.error(messsage, {
		icon: () => <img src={xIcon} style={{ width: "20px" }} />,
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 3000, // Close the toast after 3 seconds
		hideProgressBar: true, // Hide the progress bar
		closeOnClick: true, // Close the toast when clicked
		pauseOnHover: false, // Don't pause the timer on hover
		draggable: false, // Disable dragging the toast
		closeButton: false,
	});
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const showInfoToast = (toast: toast, messsage: string) => {
	toast.info(messsage, {
		icon: () => <img src={gIcon} style={{ width: "20px" }} />,
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 3000, // Close the toast after 3 seconds
		hideProgressBar: true, // Hide the progress bar
		closeOnClick: true, // Close the toast when clicked
		pauseOnHover: false, // Don't pause the timer on hover
		draggable: false, // Disable dragging the toast
		closeButton: false,
	});
};
